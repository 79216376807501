import { getConfigData, getHashConfig } from 'Common/utilities/config';

const data = getConfigData();

export default {
  environment: data.sentry_env,
  release: getHashConfig(),
  autoBreadcrumbs: true,
  captureUnhandledRejections: true,
  extra: {
    commitLink: `https://github.com/loanmarket/mycrm-frontend/commit/${getHashConfig()}`,
  },
};

import { forEach } from 'lodash';

class FileUploadCtrl {
  constructor(
    uiService,
    FileUploader,
    configService,
    $timeout,
    toaster,
    $window
  ) {
    'ngInject';

    this.uiService = uiService;
    this.FileUploader = FileUploader;
    this.configService = configService;
    this.$timeout = $timeout;
    this.toaster = toaster;
    this.$window = $window;
  }

  // toggles queue list show/hide
  toggleQueue() {
    this.showQueue = !this.showQueue;
  }

  // {@files} - reference files from uploader.getNotUploadedItems()
  removeUnreadyFiles(files) {
    forEach(files, fileItem => {
      // will only remove cancelled and failed files
      if (fileItem.isCancel || fileItem.isError) {
        this.uploader.removeFromQueue(fileItem);
      }
    });
  }

  $onInit() {
    // toggles show/hide of queue container
    this.showQueue = true;

    // list of default values for FileUploader
    this.default = {
      // list of default extension if no `extensions` prop is provided
      extensions: ['pdf', 'jpg', 'jpeg', 'png'],

      // autoUpload after file has been added, default to true if no `autoUpload` prop is provided
      autoUpload: true,

      // post url for our file upload, default to contacts/DocumentUpload if no `url` prop is provided
      url: `${this.configService.resource}/contacts/DocumentUpload`,
    };

    this.inputStyle = {
      height: this.height || '100%',
      width: this.width || '100%',
    };

    // instantiates new fileUploader object to this.uploader
    this.uploader = new this.FileUploader({
      // expects autoUpload as a prop or get from the default
      autoUpload:
        typeof this.autoUpload !== 'undefined'
          ? this.autoUpload
          : this.default.autoUpload,

      // expect url as a prop or get from the default
      url: this.url || this.default.url,

      // let's just put it like this
      headers: { Authorization: this.configService.token },
    });

    // list of successfully uploaded items
    this.uploader.uploadedItems = [];

    this.uploader.onBeforeUploadItem = item => {
      const params = [];
      if (!this.noUrlParams) {
        const { familyId, uploadType, documentId, type } = this;
        const args = { familyId, uploadType, documentId, type };
        Object.keys(args).forEach(key => {
          params.push(`${key}=${args[key] || 0}`);
        });
        item.url += `?${params.join('&')}`;
      }
    };

    // filter file extensions
    this.uploader.filters.push({
      name: 'extensions',
      fn: item => {
        const jwt = this.$window.localStorage.getItem('myCRM_jwt');
        const didTokenChange =
          this.uploader.headers &&
          jwt &&
          this.uploader.headers.Authorization !== jwt;
        if (didTokenChange) {
          this.uploader.headers.Authorization = jwt;
        }

        const ext = this.extensions
          ? this.extensions.join(`|`)
          : this.default.extensions.join(`|`);
        const regEx = new RegExp(ext, 'gi');

        if (!item.name.match(regEx)) {
          if (this.invalidFileInfo) {
            this.toaster.pop(
              `error`,
              this.invalidFileInfo.title,
              this.invalidFileInfo.description
            );
          } else {
            this.toaster.pop(
              `error`,
              `Unable to upload ${item.name}`,
              `Please try uploading using ${ext.toUpperCase()}`
            );
          }
        } else {
          return true;
        }
      },
    });

    // needs to remove the cancelled or failed files from the queue before reuploading newly added files
    // this is to make sure that we are uploading healthy files only
    this.uploader.onAfterAddingAll = () => {
      this.removeUnreadyFiles(this.uploader.getNotUploadedItems());
    };

    // completely uploaded all files
    this.uploader.onCompleteItem = (fileItem, response, status) => {
      if (status === 200) {
        this.uploader.uploadedItems.push({ ...response[0] });
      }
    };

    this.uploader.onCompleteAll = () => {
      // will call onCompleteAll only if defined
      if (this.onCompleteAll) {
        this.onCompleteAll({ files: this.uploader.uploadedItems });
      }

      // waits for a second before closing the document progress for users to see
      this.$timeout(() => {
        this.uploader.uploadedItems = []; // clears current list of items
        this.uploader.clearQueue(); // clears the whole queue
      }, 1000);
    };
  }
}

export default FileUploadCtrl;

import angular from 'angular';

/*
* NOTE: Please pass in props as the list of properties you wish to attach to your component, leave an empty object if you don't have any
* USAGE: Should be used if the modal's template is calling a component
*/
class CommonModalPlaceholderCtrl {
  constructor($uibModalInstance, props) {
    'ngInject';

    this.modalInstance = $uibModalInstance;
    this.props = props;
  }
}

angular
  .module('app')
  .controller('CommonModalPlaceholderCtrl', CommonModalPlaceholderCtrl);

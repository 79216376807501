import template from './insuranceAppCoverDetails.html';
import controller from './insuranceAppCoverDetailsCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    quoteId: '<',
    onInit: '&',
    onUpdated: '&?',
  },
};

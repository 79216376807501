import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import { isEmploymentIncome } from 'Common/utilities/income';
import { DEFAULT_INCOME_TYPES } from 'Common/constants/incomeTypes';

angular
  .module('app')
  .controller('FinancialIncomeCtrl', function FinancialIncomeCtrl(
    $rootScope,
    $scope,
    $timeout,
    $interval,
    $compile,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    toaster,
    $state,
    $stateParams,
    utilitiesService,
    $uibModal,
    SweetAlert,
    optionsService,
    incomeSharedService,
    financialsSharedService,
    uiService,
    loanAppSharedData
  ) {
    $scope.uiService = uiService;
    $scope.isEmploymentIncome = isEmploymentIncome;
    $scope.financialsSharedService = financialsSharedService;
    $scope.loanAppSharedData = loanAppSharedData;
    $scope.loanAppId = $stateParams.loanAppId;
    $scope.familyId = $stateParams.familyId;

    $scope.IncomeTotalAmount = 0;
    $scope.totalIncomePerMonth = 0;

    $scope.Settings = {
      collapseList: true,
      displayIncomeForm: false,
    };

    $scope.$watch('selectedContacts', newValue => {
      if (newValue === 'financials') {
        $scope._incomeInit(true);
      }
    });

    //* ************************************/
    //  owners multi Select                /
    //* ************************************/
    $scope.localLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected: "<div class='buttonLabel'>All Clients</div>", // default-label is deprecated and replaced with this.
    };
    $scope.ownersList = [];
    $scope.ownersListSelected = [];

    $scope.changeIncome = () => {
      if (isEmploymentIncome($scope.financials.IncomeSet.TypeId)) {
        $scope.financials.IncomeSet.FrequencyID = parseInt(
          $scope.financials.IncomeSet.FrequencyID,
          10
        );
        $scope.newIncomeModal(
          $scope.financials.IncomeSet.EmploymentId ? 'edit' : 'new',
          true
        );
      } else if (
        parseInt($scope.financials.IncomeSet.TypeId, 10) ===
        DEFAULT_INCOME_TYPES.RENTAL
      ) {
        $scope.Settings.displayIncomeForm = false;
        $scope.financials.IncomeSet.TypeId = $scope.prevValueHolder;
        toaster.pop(
          'error',
          'Error',
          'Please add rental income to the Existing Real Estate Asset'
        );
      }
    };

    $scope.IncomeClientGet = (incomeIdParam, familyId, borrowers) => {
      let incomeId = incomeIdParam;
      if (_.isUndefined(incomeId)) {
        incomeId = 0;
      }

      $scope.ownersListSelected = [];

      contactService.borrowersDetailsGet(familyId, 0).then(respond => {
        $scope.ownersListSelected = borrowers || [];
        $scope.ownersList = $scope.processBorrowersData(
          respond,
          borrowers,
          $scope.ownersListSelected
        );
      });
    };

    $scope.getIncomeList = () => {
      contactService.incomeInfoGet($scope.familyId).then(response => {
        const income = response.data;
        $scope.IncomeTotalAmount = 0;
        $scope.totalIncomePerMonth = income.TotalAmountPerMonth;

        _.forEach(income.FinancialInfo, financialInfo => {
          if (!angular.isUndefined(financialInfo.FrequencyName))
            financialInfo.FrequencyInitial = financialInfo.FrequencyName.charAt(
              0
            );

          _.map(financialInfo.Borrowers, borrower => {
            borrower.OwnershipInitials = utilitiesService.filterInitial(
              borrower.FirstName,
              borrower.LastName ? borrower.LastName : ''
            );
            return borrower;
          });
        });

        $scope.IncomeTotalAmount = income.TotalAmountPerMonth;

        $scope.financials.incomeTotal = $scope.IncomeTotalAmount;

        $scope.listIncome = response.data;

        const hasIncome = $scope.listIncome.FinancialInfo.length > 0;
        if (hasIncome) {
          $scope.Settings.collapseList = false;
        }
      });
    };

    $scope._incomeInit = () => {
      $scope.prevValueHolder = '0';
      if (_.isUndefined($scope.selectedFamily)) {
        $scope.selectedFamily = {
          FamilyId: parseInt($scope.familyId, 10),
          initials: '',
        };
      }

      $scope.financials.IncomeSet = {};
      $scope.frequencyTypes = [];
      optionsService.FinancialFrequency().then(response => {
        _.map(response.data, o => {
          o.Desc = o.Name;
          o.Value = parseInt(o.Value, 10);

          return o;
        });

        $scope.frequencyTypes = response.data;
      });

      loanScenarioService.IncomeTypeGet().then(response => {
        $scope.incomeTypes = response.data;

        // Remove Addback
        _.remove($scope.incomeTypes, o => {
          return o.GroupName === 'Addback';
        });

        $scope.financials.IncomeSet = {
          Type: response.data[0].Value,
          Frequency: 3,
        };
      });

      $scope.listIncome = [];
      $scope.getIncomeList();
      $scope.IncomeClientGet(undefined, $scope.familyId);
    };
    // initialize again this section because there are changes on application section
    $rootScope.$on('REFRESH_APPLICANT_DEPENDENT_SECTIONS', () => {
      $scope._incomeInit(true);
    });

    $rootScope.$on('REFRESH_INCOME', () => {
      $scope._incomeInit(true);
    });

    $scope.IncomeInfoDelete = incomeId => {
      SweetAlert.swal(
        {
          title: 'Are you sure?',
          text: 'This record will be removed from your income list',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F68F8F',
          confirmButtonText: 'Yes, remove it!',
          closeOnConfirm: false,
        },
        confirm => {
          if (confirm) {
            contactService.deleteIncome(incomeId, $scope.familyId).then(() => {
              $timeout(() => {
                $scope._incomeInit(false);
                SweetAlert.swal({
                  title: 'Success',
                  text: 'Income has been been successfully deleted.',
                  type: 'success',
                  timer: 2000,
                  showConfirmButton: false,
                });
              }, 100);
            });
          }
        }
      );
    };

    $scope.getNewIncomeModel = () => {
      return incomeSharedService.incomeServices.getNewIncomeModel(
        $state.params.familyId
      );
    };

    $scope.resetIncomeModel = () => {
      $scope.financials.IncomeSet = $scope.getNewIncomeModel();
      $scope.chosenGrossNetWithFrequency = 'G/M';
    };

    $scope.addIncome = incomeDetails => {
      /**
       * incomeDetails comes from inline form (new income)
       * */
      if (!incomeDetails) {
        /**
         * @description get all $scope.financials.IncomeSet and reassign some below
         * */
        $scope.financials.IncomeSet.IsGross =
          parseInt($scope.financials.IncomeSet.TypeId, 10) === 19
            ? incomeSharedService.incomeServices.convertNetGross(
                $scope.financials.IncomeSet.IsGross,
                true
              )
            : true;
        $scope.financials.IncomeSet.TypeId = parseInt(
          $scope.financials.IncomeSet.TypeId,
          10
        );
        $scope.financials.IncomeSet.FamilyId = $scope.familyId.toString();
      } else {
        /**
         * Assign available values to $scope.financials.IncomeSet
         * */
        $scope.financials.IncomeSet = {
          Id: incomeDetails.Id ? incomeDetails.Id : 0,
          FamilyId: $scope.familyId.toString(),
          TypeId: parseInt(incomeDetails.TypeId, 10),
          FrequencyID: parseInt(incomeDetails.FrequencyID, 10),
          Amount: incomeDetails.Amount,
          Description: incomeDetails.Description,
          IsGross:
            parseInt(incomeDetails.TypeId, 10) === 19
              ? incomeSharedService.incomeServices.convertNetGross(
                  incomeDetails.IsGross,
                  true
                )
              : true,
        };
      }

      const joinFamilyObj = _.find($scope.ownersList, obj => {
        return obj.BorrowerID === 0;
      });

      if (joinFamilyObj) {
        if (joinFamilyObj.ticked) {
          $scope.ownersListSelected = _.map($scope.ownersList, obj => {
            if (obj.BorrowerID !== 0) {
              obj.ticked = true;
              return obj;
            }
          });
        }
      } else {
        $scope.ownersListSelected = _.filter($scope.ownersList, obj => {
          return obj.ticked === true;
        });
      }

      $scope.financials.IncomeSet.Borrowers = [];
      $scope.ownersList.forEach(data => {
        if (data.ticked && data.BorrowerID !== 0) {
          $scope.financials.IncomeSet.Borrowers.push({
            Percentage: 0,
            BorrowerID: data.BorrowerID,
            IsEntity: data.IsEntity,
          });
        }
      });

      contactService.incomeDetailsSet($scope.financials.IncomeSet).then(() => {
        $scope._incomeInit(false);
        $scope.resetIncomeModel();
      });
    };

    // Add Income Modal
    $scope.newIncomeModal = (type, isLinkIncome) => {
      if (isLinkIncome && $scope.ownersList.length) {
        const isJoint = _.find($scope.ownersList, obj => !obj.BorrowerID) || {};
        const firstBorrower = _.find(
          $scope.ownersList,
          obj => (isJoint.ticked ? obj.BorrowerID : obj.ticked)
        );
        $scope.financials.IncomeSet.ClientId =
          (firstBorrower && firstBorrower.BorrowerID) || 0;
      }

      const templateUrl = `/assets/views/contacts/client/financial/modal/${
        isLinkIncome ? `incomeEmployment` : `income`
      }.html`;
      const size = isLinkIncome ? 'md' : 'sm';
      const modalInstance = $uibModal.open({
        templateUrl,
        controller: 'NewIncomeModalCtrl',
        size,
        scope: $scope,
        resolve: {
          modalType() {
            return type;
          },
        },
        backdrop: 'static',
        keyboard: false,
      });

      modalInstance.result.then(
        clientIncomeResponse => {
          if (
            clientIncomeResponse &&
            clientIncomeResponse.isOpenIncomeModal &&
            clientIncomeResponse.obj
          ) {
            $scope.changeIncome(clientIncomeResponse.obj);
          } else {
            $scope.resetIncomeModel();
          }
        },
        () => {
          $scope.resetIncomeModel();
        }
      );
    };

    $scope.IncomeDetailsGet = (familyId, income) => {
      $scope.resetIncomeModel();

      $scope.financials.IncomeSet = income;
      $scope.financials.IncomeSet.FrequencyID = parseInt(
        $scope.financials.IncomeSet.FrequencyID,
        10
      );
      $scope.financials.IncomeSet.TypeId = $scope.financials.IncomeSet.TypeId.toString();
      $scope.financials.IncomeSet.IsGross = incomeSharedService.incomeServices.convertNetGross(
        $scope.financials.IncomeSet.IsGross,
        false
      );
      // Get clients of particular income
      $scope.IncomeClientGet(
        $scope.financials.IncomeSet.Id,
        familyId,
        angular.copy($scope.financials.IncomeSet.Borrowers)
      );
      $scope.IncomeSet = $scope.financials.IncomeSet;
      $scope.prevValueHolder = $scope.IncomeSet.TypeId;
      $scope.newIncomeModal('edit');
    };

    $scope.selectIncome = (familyId, contactIncome) => {
      if (
        isEmploymentIncome(contactIncome.TypeId) &&
        contactIncome.EmploymentId
      ) {
        const clientId =
          contactIncome.Borrowers &&
          contactIncome.Borrowers.length &&
          contactIncome.Borrowers[0].BorrowerID &&
          contactIncome.Borrowers[0].BorrowerID.toString();
        $scope.loanAppSharedData.changeEmploymentOwnership(clientId);
        const modalInstance = $uibModal.open({
          backdrop: 'static',
          keyboard: false,
          templateUrl: '/assets/views/modals/employment.html',
          controller: 'EmploymentModalCtrl',
          controllerAs: 'vm',
          size: 'lg',
          windowClass: 'modal-larger',
          resolve: {
            newIncomeDetails: () => null,
            isFromIncome: () => true,
            familyId: () => familyId,
            employmentId: () => contactIncome.EmploymentId,
            clientId: () => clientId,
            loanAppId: () => 0,
            action: () => 'Edit',
          },
        });

        modalInstance.result.then(
          () => {
            $scope.getIncomeList();
          },
          () => {
            $scope.getIncomeList();
          }
        );
      } else if (
        isEmploymentIncome(contactIncome.TypeId) &&
        !contactIncome.EmploymentId
      ) {
        $scope.financials.IncomeSet = contactIncome;
        $scope.newIncomeModal('edit', true);
      } else {
        $scope.IncomeDetailsGet(familyId, contactIncome);
      }
    };

    $scope.$watch('listIncome', () => {
      let length = 0;
      if (_.size($scope.listIncome) > 0) {
        _.forEach($scope.listIncome, o => {
          length += _.size(o.FinancialInfo);
        });

        $rootScope.$broadcast('updateIncome', {
          length,
          TotalAmount: $scope.IncomeTotalAmount,
        });
      } else {
        $rootScope.$broadcast('updateIncome', { length: 0, TotalAmount: 0 });
      }
    });

    // Watch for changes in selected family
    $scope.$watch('selectedFamily.FamilyId', nv => {
      $scope.IncomeClientGet(undefined, nv);
    });

    $scope.onItemSelect = data => {
      let isJointFamily = true;
      for (let i = 0; i < $scope.ownersList.length; i++) {
        if (
          (typeof $scope.ownersList[i].ticked === 'undefined' ||
            !$scope.ownersList[i].ticked) &&
          $scope.ownersList[i].BorrowerID >= 1
        ) {
          isJointFamily = false;
        }
      }

      // will be true if user clicks on joint
      if (data.BorrowerID === 0) {
        isJointFamily = true;
      }
      if (!isJointFamily) {
        $scope.ownersList = _.map($scope.ownersList, obj => {
          if (obj.BorrowerID === 0) {
            obj.ticked = false;
          }
          return obj;
        });
      } else {
        const findJoint = incomeSharedService.incomeServices.findJoint(
          $scope.ownersList
        );
        // need to check if single owner
        if (findJoint && Object.keys(findJoint).length) {
          $scope.ownersList = _.map($scope.ownersList, obj => {
            obj.ticked = obj.BorrowerID === 0;
            return obj;
          });
        }
      }
    };

    $scope.ownersListSelectedArray = [];
    $scope.onSelectAll = () => {
      const findJointIncome = incomeSharedService.incomeServices.findJoint(
        $scope.ownersList
      );
      // need to check if single owner
      if (findJointIncome && Object.keys(findJointIncome).length) {
        $scope.ownersList = _.map($scope.ownersList, obj => {
          obj.ticked = obj.BorrowerID === 0;
          return obj;
        });
      } else {
        for (let i = 0; i < $scope.ownersList.length; i++) {
          if ($scope.ownersList[i].BorrowerID > -1) {
            $scope.ownersList[i].ticked = false;
          } else {
            $scope.ownersList[i].ticked = true;
          }
        }
      }
    };

    $scope.grossNetDropdownClicked = (grossNetString, freqValue, freqDesc) => {
      $scope.financials.IncomeSet.IsGross = grossNetString;
      $scope.financials.IncomeSet.FrequencyID = freqValue;
      $scope.chosenGrossNetWithFrequency = `${grossNetString
        .charAt(0)
        .toUpperCase()}/${freqDesc.charAt(0).toUpperCase()}`;
    };

    $scope.$watch('ownersListSelected', () => {
      $('.hide-in-mobile .multiSelect')
        .nextAll('div.buttonLabel')
        .remove();
      if ($scope.ownersListSelected.length >= 3) {
        // if list has more than 2 persons
        $('.multiSelect .buttonLabel:last-child()').html(
          `${$scope.ownersListSelected.length - 2} more ...` +
            `<span class="caret"></span>`
        );
      }
      $('.hide-in-mobile .multiSelect .buttonLabel:not(:nth-child(3))').each(
        e => {
          $(e.currentTarget).html(
            `${$(
              e.currentTarget
            ).text()}<i class="fa fa-close"></i> <span class="caret"></span>`
          );
        }
      );
    });
  });

import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import {
  isCancelled,
  getModalName,
  videoNotFoundAlert,
} from 'Common/utilities/subscription';
import { objectLength } from 'Common/utilities/objectValidation';
import { E_SIGN_ACOUNT_TYPE } from 'Common/constants/eSign';

class UsersSubscriptionComponentCtrl {
  constructor(
    contactService,
    $uibModal,
    configService,
    usersSubscriptionService,
    eSignService,
    crmConfirmation
  ) {
    'ngInject';

    this.contactService = contactService;
    this.$uibModal = $uibModal;
    this.subscriptionType = SUBSCRIPTIONTYPE;
    this.configService = configService;
    this.usersSubscriptionService = usersSubscriptionService;
    this.isCancelledWithActiveDate = isCancelled;
    this.videoNotFoundAlert = videoNotFoundAlert;
    this.eSignService = eSignService;
    this.crmConfirmation = crmConfirmation;
  }
  $onInit() {
    this.checkUserSubsLength = 0;
    this.userSubscriptions = [];
    this.showEsignSignature =
      this.configService.feature && this.configService.feature.esignSignature;
  }
  $onChanges(changes) {
    if (changes && changes.adviserInfo) {
      this.adviserInfo = changes.adviserInfo.currentValue;
    }
    if (changes && changes.orderedById) {
      this.orderedById = changes.orderedById.currentValue;
    }
    const checkChange =
      this.adviserInfo &&
      this.adviserInfo.FamilyId &&
      changes.adviserInfo.currentValue.FamilyId !==
        changes.adviserInfo.previousValue.FamilyId;
    if (checkChange) {
      this.familyId = this.adviserInfo.FamilyId;
      this.getSubscription();
    }
  }

  checkTypeValid(subscriptionProductId) {
    const {
      ONLINE_FACT_FIND,
      BROKERPEDIA,
      CREDIT_CHECK,
      PRICE_FINDER,
      MARKETING_AUTOMATION,
      E_SIGN,
    } = this.subscriptionType;
    const {
      onlineFactFind: featureOnlineFactFind,
      brokerPedia: featureBrokerPedia,
      creditChecks: featureCreditChecks,
      priceFinder: featurePriceFinder,
      marketingAutomation: featureMarketingAutomation,
      esign: featureEsign,
    } = this.configService.feature;
    const onlineFactFind = featureOnlineFactFind && ONLINE_FACT_FIND;
    const brokerPedia = featureBrokerPedia && BROKERPEDIA;
    const creditCheck = featureCreditChecks && CREDIT_CHECK;
    const eSign = featureEsign && E_SIGN;
    const priceFinder = featurePriceFinder && PRICE_FINDER;
    const marketingAutomation =
      featureMarketingAutomation && MARKETING_AUTOMATION;
    const subscriptionTypeValid =
      [
        onlineFactFind,
        brokerPedia,
        creditCheck,
        priceFinder,
        marketingAutomation,
        eSign,
      ].indexOf(subscriptionProductId) !== -1;
    return subscriptionTypeValid;
  }

  getSubscription() {
    this.userSubscriptions = [];
    if (this.familyId) {
      this.contactService.subscriptionInfo(this.familyId).then(response => {
        if (!response || !response.length) return;
        this.userSubscriptions = response.reduce((initial, currentObj) => {
          const { subscriptionProductId } = currentObj;
          if (this.checkTypeValid(subscriptionProductId)) {
            initial.push(currentObj);
          }
          return initial;
        }, []);
        const brokerPedia = this.userSubscriptions.find(
          obj => obj.subscriptionProductId === SUBSCRIPTIONTYPE.BROKERPEDIA
        );
        const priceFinder = this.userSubscriptions.find(
          obj => obj.subscriptionProductId === SUBSCRIPTIONTYPE.PRICE_FINDER
        );
        this.usersSubscriptionService.setBrokerPediaData(brokerPedia);
        this.usersSubscriptionService.setPriceFinderData(priceFinder);
      });
    }
  }

  openESignModal() {
    const props = {
      familyId: this.familyId,
    };
    this.$uibModal.open({
      template: `<e-sign-upload-signature-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId">
                </e-sign-upload-signature-modal>`,
      size: 'md',
      backdrop: 'static',
      windowClass: 'mc-e-sign-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
  }

  openAutoMarketingModal(props) {
    const modalInstance = this.$uibModal.open({
      template: `<subscription-auto-marketing-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId"
                  subscription-data="vm.props.subscriptionData"
                  ordered-by-id="vm.props.orderedById">
                </subscription-auto-marketing-modal>`,
      size: 'lg',
      backdrop: 'static',
      windowClass: 'subscription-modal subscription-auto-marketing-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then(response => {
      if (response && response.getAllSubscribe) {
        this.getSubscription();
      }
    });
  }

  openSubscribeModal(subs) {
    const { subscriptionProductId } = subs;
    if (!subscriptionProductId) return;
    const isTypeValid = this.checkTypeValid(subscriptionProductId);
    if (!isTypeValid) return;
    if (subscriptionProductId === this.subscriptionType.MARKETING_AUTOMATION) {
      const props = {
        familyId: this.familyId,
        subscriptionData: subs,
        orderedById: this.orderedById,
      };
      this.openAutoMarketingModal(props);
    } else {
      const props = {
        familyId: this.familyId,
        subscriptionData: subs,
        orderedById: this.orderedById,
      };
      const componentName = getModalName(subscriptionProductId);
      if (!componentName) return;
      const modalInstance = this.$uibModal.open({
        template: `<${componentName}
                    modal-instance="vm.modalInstance"
                    family-id="vm.props.familyId"
                    subscription-data="vm.props.subscriptionData"
                    ordered-by-id="vm.props.orderedById">
                  </${componentName}>`,
        size: 'lg',
        backdrop: 'static',
        windowClass: 'mc-subscription-modal',
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        resolve: {
          props,
        },
      });
      modalInstance.result.then(response => {
        if (!response) return;
        this.getSubscription();
        const validToCheckSignature =
          this.showEsignSignature &&
          subscriptionProductId === SUBSCRIPTIONTYPE.E_SIGN;
        if (validToCheckSignature) {
          this.openESignModal();
          return;
        }
        this.esignAccountCheck(subscriptionProductId);
      });
    }
  }

  esignAccountCheck(subscriptionProductId) {
    if (subscriptionProductId !== SUBSCRIPTIONTYPE.E_SIGN || !this.familyId)
      return;
    this.eSignService.getAccountCheck(this.familyId).then(response => {
      if (response && response.status === E_SIGN_ACOUNT_TYPE.INVITED) {
        this.crmConfirmation.open({
          type: 'success',
          title: 'Subscription Successful',
          description: `Your eSign account has now been created, please confirm your email address`,
          buttonText: 'Okay, got it!',
          modalSize: 'md',
        });
      }
    });
  }

  cancelSubscription(subs) {
    const { subscriptionProductId } = subs;
    const isTypeValid = this.checkTypeValid(subscriptionProductId);
    if (!isTypeValid) return;
    if (subscriptionProductId === this.subscriptionType.MARKETING_AUTOMATION) {
      this.cancelCampaignSubscription(subs);
      return;
    }
    const props = {
      familyId: this.familyId,
      subscriptionData: subs,
    };
    const modalInstance = this.$uibModal.open({
      template: `<subscription-cancel-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId"
                  subscription-data="vm.props.subscriptionData">
                </subscription-cancel-modal>`,
      size: 'lg',
      backdrop: 'static',
      windowClass: 'cancel-subscription',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then(response => {
      if (!response || !response.getSubscription) return;
      this.getSubscription();
    });
  }
  cancelCampaignSubscription(subs) {
    const props = {
      familyId: this.familyId,
      subscriptionData: subs,
    };
    const modalInstance = this.$uibModal.open({
      template: `<subscription-cancel-now-modal
                  modal-instance="vm.modalInstance"
                  family-id="vm.props.familyId"
                  subscription-data="vm.props.subscriptionData">
                </subscription-cancel-now-modal>`,
      backdrop: 'static',
      windowClass: 'cancel-subscription-modal',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then(() => {
      this.getSubscription();
    });
  }
  isInActiveSubscription(subscripton) {
    return (
      this.subscriptionEnabled &&
      !subscripton.isActive &&
      !this.isCancelledWithActiveDate(subscripton)
    );
  }
  isCancelledSubscription(subscripton) {
    return (
      this.subscriptionEnabled && this.isCancelledWithActiveDate(subscripton)
    );
  }
  showModifyEsignSubscription(subscripton) {
    if (!subscripton || !objectLength(subscripton)) return false;
    return (
      this.showEsignSignature &&
      (this.isActiveSubscription(subscripton) ||
        this.isCancelledSubscription(subscripton)) &&
      subscripton.subscriptionProductId === this.subscriptionType.E_SIGN
    );
  }
  isActiveSubscription(subscription) {
    return (
      this.subscriptionEnabled && subscription.isActive && !subscription.isTrial
    );
  }
  isTrialSubscription(subscription) {
    return (
      this.subscriptionEnabled && subscription.isTrial && subscription.isActive
    );
  }
}

export default UsersSubscriptionComponentCtrl;

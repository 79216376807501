import angular from 'angular';
import _ from 'lodash';
import { setCurrrentDate, setDateFormat } from 'Common/utilities/date';

import { IMAGE_TYPE } from 'Common/constants/emailSignatureImageType';

import {
  VALID_IMAGE_FORMAT,
  INVALID_FORMAT_WARNING,
} from 'Common/constants/imageFormat';

angular
  .module('app')
  .controller('AdviserMarketingWebCtrl', function AdviserMarketingWebCtrl(
    broadcastDataService,
    corporateService,
    contactService,
    commonFnService,
    dataService,
    queryService,
    toaster,
    $filter,
    $state,
    $scope,
    $window,
    $timeout,
    configService
  ) {
    /* State Params */
    $scope.clientId = $state.params.clientId || 0;
    $scope.familyId = $state.params.familyId || 0;
    /*    VARIABLE(s)    */
    /* Data Type: {Object} */
    $scope.familyMarketingDetailGet = {};
    $scope.familyWebManagementGet = {};
    $scope.addressTypeDetails = {};
    $scope.editMode = {};
    /* Data Type: {Array} */
    $scope.brandingCategoryListGet = [];
    $scope.altInputFormats = [];
    $scope.setterType = [];
    $scope.saveType = [];
    /* Data Type: {String} */
    $scope.userCurrentCountyId = 0;
    $scope.dateStaticValue = '1900-01-01';
    $scope.dateFormat = '';
    /*  FUNCTION REFERENCE      */
    /*  FUNCTION DEFINITION(S)  */
    let watchAdviserMarketingEditMode = null;
    /* ------------------------------------------ [ GETTER ] --------------------------------------------------- */
    function getBrandingCategories(countryId) {
      dataService.getBrandingCategories(countryId).then(brandingCategory => {
        if (brandingCategory && brandingCategory.length) {
          $scope.brandingCategoryListGet = brandingCategory;
        }
      });
    }
    /**
     *   @name onRetrieveFamilyWebManagementSuccess
     *   @desc This will load data from Api: `familyWebManagementGet` on success.
     *   @params {response} data from the api result.
     */
    function onRetrieveFamilyWebManagementSuccess(response) {
      if (response && response.data) {
        response.data.AddressTypeID = response.data.AddressTypeID
          ? response.data.AddressTypeID
          : 0;
        response.data.AddressType = queryService.getArrValue(
          response.data.AddressTypeID,
          null,
          'id',
          'name',
          $scope.addressTypeDetails
        );
        $scope.familyWebManagementGet = response.data;
      }
    }
    /**
     *   @name onRetrieveMarketingDetailSuccess
     *   @desc This will load data from Api: `familyWebManagementGet` on success.
     *   @params {response} data from the api result.
     */
    function onRetrieveMarketingDetailSuccess(response) {
      if (response && response.data) {
        $scope.familyMarketingDetailGet = response.data;
      }
    }
    /*
      *   @name initialize
      *   @desc This will load/initialize data needed on load.
      */
    function initialize() {
      dataService.getUserInfo(true).then(response => {
        if (response && response.data) {
          $scope.isCorpTeam =
            response.data.CorpAccessTypeID === 1 ? 'true' : 'false';
          $scope.userCurrentCountyId = response.data.CountryId;
        }
      });
      $scope.setterType = dataService.getSetterTypes('Adviser');
      $scope.saveType = dataService.getSaveTypes('Adviser');
      $scope.dateFormat = setDateFormat();
      $scope.altInputFormats = dataService.getDateValidityFormats();
      $scope.currentDate = setCurrrentDate();
      $scope.addressTypeDetails = dataService.getAddressTypeDetails();
      dataService.getRecentViewed();

      corporateService
        .familyMarketingDetailGet($scope.familyId, $scope.clientId)
        .then(
          onRetrieveMarketingDetailSuccess,
          commonFnService.onRetrieveError
        );

      corporateService
        .corporateContactGet($scope.clientId, $scope.familyId)
        .then(adviser => {
          if (adviser && adviser.data) {
            $scope.userCurrentCountyId = adviser.data.ReportingCountryId
              ? adviser.data.ReportingCountryId
              : $scope.userCurrentCountyId;
            getBrandingCategories($scope.userCurrentCountyId);
          }
        })
        .then(() => {
          corporateService
            .familyWebManagementGet($scope.familyId, $scope.clientId)
            .then(
              onRetrieveFamilyWebManagementSuccess,
              commonFnService.onRetrieveError
            );
        });

      watchAdviserMarketingEditMode = $scope.$watch('editModeObj', newValue => {
        $scope.editMode = newValue;
      });
      $scope.getAdviserProfilePicture(IMAGE_TYPE.HEAD_SHOT);
      $scope.getAdviserProfilePicture(IMAGE_TYPE.HALF_BODY);
      $scope.getAdviserProfilePicture(IMAGE_TYPE.FULL_BODY);
    }
    $scope.$on('$destroy', () => {
      watchAdviserMarketingEditMode();
    });
    /* ------------------------------------------ [ SETTER ] --------------------------------------------------- */
    /**
     *   @name querySet
     *   @desc This will save the value from api.
     *   @params {String} setter
     *   @params {String} key
     *   @params {Array} arrays
     *   @params {Boolean} isCorporate
     */
    const showToaster = (type, title, message) => {
      const invalidToaster = !type || !title || !message;
      if (invalidToaster) return;
      toaster.pop(type, title, message);
    };

    function querySet(setter, key, field, arrays, isCorporate) {
      $scope.editMode[key] = false;
      commonFnService.setEditMode($scope.editMode, field);
      if (isCorporate) {
        corporateService[setter].apply(null, arrays).then(response => {
          if (response) {
            showToaster(`success`, `Updated`, `successfully updated!`);
          }
        });
      }
      toaster.clear();
    }
    /*  ------------------------------------------ [ PUBLIC FUNCTION ] ------------------------------------------- */
    $scope.dateEditMode = function(obj, key) {
      return commonFnService.dateEditMode(obj, key, $scope.editMode);
    };

    $scope.formatDateString = function(date) {
      return commonFnService.formatDateString(date);
    };

    $scope.formatDate = function(date) {
      return commonFnService.formatDate(date);
    };

    $scope.checkAccessPermission = (isAllReadOnly, fieldReadOnly) => {
      return dataService.checkAccessPermission(isAllReadOnly, fieldReadOnly);
    };

    $scope.uploadImageUrl = $scope.familyId
      ? `${configService.resource}/contacts/${$scope.familyId}/profile-picture`
      : ``;
    $scope.isUploadMultiple = false;
    $scope.validFileExtensions = VALID_IMAGE_FORMAT;
    $scope.invalidFileInfo = INVALID_FORMAT_WARNING;
    $scope.imageType = IMAGE_TYPE;

    $scope.isHeadShotActive = () => {
      return (
        $scope.profileHeadShotInfo && $scope.profileHeadShotInfo.downloadLink
      );
    };

    $scope.isHalfBodyActive = () => {
      return (
        $scope.profileHalfBodyInfo && $scope.profileHalfBodyInfo.downloadLink
      );
    };

    $scope.isFullBodyctive = () => {
      return (
        $scope.profileFullBodyInfo && $scope.profileFullBodyInfo.downloadLink
      );
    };

    $scope.uploadImageSuccess = response => {
      if (!response || !response.length) return;
      showToaster(`success`, `Updated`, `successfully updated!`);
      const { ImageUploadType: imageType } = response[0];
      $scope.getAdviserProfilePicture(imageType);
    };
    $scope.getAdviserProfilePicture = type => {
      if (!$scope.familyId || !type) return;
      contactService
        .getEmailSignatureImage($scope.familyId, type)
        .then(response => {
          if (!response || !response.length) {
            $scope.updateProfileImage(type, {});
          } else {
            $scope.updateProfileImage(type, response[0]);
          }
        });
    };

    $scope.updateProfileImage = (type, imageData) => {
      if (!type || !imageData) return;
      if (type === IMAGE_TYPE.HEAD_SHOT) {
        $scope.profileHeadShotInfo = imageData;
      }
      if (type === IMAGE_TYPE.HALF_BODY) {
        $scope.profileHalfBodyInfo = imageData;
      }
      if (type === IMAGE_TYPE.FULL_BODY) {
        $scope.profileFullBodyInfo = imageData;
      }
    };

    $scope.deleteSignatureImage = (type, documentId) => {
      if (!$scope.familyId || !documentId || !type) return;
      contactService
        .deleteSignatureImage($scope.familyId, documentId)
        .then(response => {
          if (!response || !response.data) return;
          showToaster(`success`, `Updated`, `successfully deleted!`);
          $scope.getAdviserProfilePicture(type);
        });
    };

    $scope.updateAdviserDetails = function(
      setterType,
      obj,
      key,
      field,
      saveType,
      formName,
      inputName
    ) {
      let dataArray = [];
      switch (setterType) {
        case 'familyMarketingDetailSet':
          switch (key) {
            case 'BrandingCategory':
              if (
                $scope.userCurrentCountyId &&
                $scope.userCurrentCountyId < 3
              ) {
                getBrandingCategories($scope.userCurrentCountyId);
              }
              obj.BrandingCategoryId = queryService.getArrValue(
                null,
                obj.BrandingCategory,
                'BrandingCategoryId',
                'BrandingCategory',
                $scope.brandingCategoryListGet
              );
              obj.BrandingCategory = queryService.getArrValue(
                obj.BrandingCategoryId,
                null,
                'BrandingCategoryId',
                'BrandingCategory',
                $scope.brandingCategoryListGet
              );
              break;
            default:
              break;
          }
          dataArray = [$scope.familyId, $scope.clientId, obj];
          break;
        case 'familyWebManagementSet':
          if (formName && inputName) {
            if (
              !commonFnService.checkFormDateValidation(
                formName,
                inputName,
                obj,
                key
              )
            ) {
              return false;
            }
          } else {
            switch (key) {
              case 'DoNotDisplayOnOfficeSite':
                obj.DoNotDisplayOnOfficeSite = commonFnService.setBooleanToInt(
                  obj.DoNotDisplayOnOfficeSite
                );
                break;
              case 'IsPublic':
                obj.IsPublic = commonFnService.setBooleanToInt(obj.IsPublic);
                break;
              case 'AddressType':
                obj.AddressTypeID = queryService.getArrValue(
                  null,
                  obj.AddressType,
                  'id',
                  'name',
                  $scope.addressTypeDetails
                );
                obj.AddressType = queryService.getArrValue(
                  obj.AddressTypeID,
                  null,
                  'id',
                  'name',
                  $scope.addressTypeDetails
                );
                break;
              case 'HideStreetDetail':
                obj.HideStreetDetail = commonFnService.setBooleanToInt(
                  obj.HideStreetDetail
                );
                break;
              default:
                break;
            }
          }
          dataArray = [$scope.familyId, $scope.clientId, obj];
          break;
        default:
          break;
      }
      if (_.size(dataArray) > 0) {
        querySet(setterType, key, field, dataArray, true);
      }
    };
    $scope.getDateFormatInView = date => {
      return dataService.setDateFormatInView(date);
    };
    /**
     *   Function Call
     */
    initialize();
  });

import angular from 'angular';

angular
  .module('app')
  .controller(
    'RepaymentPdfPreviewModalCtrl',
    function RepaymentPdfPreviewModalCtrl(
      $scope,
      $window,
      options,
      labels,
      colors,
      graphData,
      loanAmount,
      years,
      interestRate,
      frequency,
      totalRepaymentAmount,
      resultPanelData,
      totalInterest,
      customThemeService,
      series,
      userService,
      loanCalculatorSharedData
    ) {
      $scope.data = graphData;
      $scope.options = options;
      $scope.labels = labels;
      $scope.colors = colors;
      $scope.series = series;
      $scope.loanAmount = loanAmount;
      $scope.years = years;
      $scope.interestRate = interestRate;
      $scope.frequency = frequency;
      $scope.totalRepaymentAmount = totalRepaymentAmount;
      $scope.resultPanelData = resultPanelData;
      $scope.totalInterest = totalInterest;
      $scope.brandingLogo = customThemeService.branding;
      $scope.getUserInfo = loanCalculatorSharedData.getUserInfo;
      $scope.getUserInfo();
      $scope.date = new Date();
    }
  );

import template from './insurancePipelineFilters.html';
import controller from './insurancePipelineFiltersCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    viewMode: '<',
    statuses: '<',
    statusFilters: '<',
    advisers: '<',
    adviserFilter: '<',
    providers: '<',
    providerFilter: '<',
    labels: '<',
    labelFilter: '<',
    onStatusFiltersChange: '&',
    onAdviserFilterChange: '&',
    onLabelFilterChange: '&?',
    onClientFilterChange: '&',
    onProviderFilterChange: '&',
  },
};

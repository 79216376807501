import {
  KanbanLeads,
  KanbanOpportunity,
  KanbanApplication,
} from 'Common/services/kanban';
import {
  PIPELINE_TYPES_STR,
  PIPELINE_SETTINGS_CATEGORY,
  PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS,
} from 'Common/constants/pipelineType';
import {
  PIPELINE_STATUS,
  CONVERSION_PIPELINE_OPTIONS,
} from 'Common/constants/pipelineStatus';
import { capitalizeFirstLetter } from 'Common/utilities/string';
import { PROBABILITIES } from 'Common/constants/probabilities';
import { pipelineStatusBuilderForUI } from 'Common/mappers/pipeline';
import {
  removeLabelFromCard,
  updateLabelOfCard,
  leadCardMap,
  opportunityCardMap,
  applicationCardMap,
} from 'Common/utilities/kanbanHelper';
import angular from 'angular';
import toastr from 'toastr';

export default class PipelineKanbanCtrl {
  constructor(
    $stateParams,
    $timeout,
    $window,
    $q,
    $state,
    pipelineService,
    pipelineSharedData,
    loanOpportunityService,
    modalRenderService,
    contactService,
    configService,
    loanScenarioService,
    opportunityNewModalService,
    utilitiesService,
    $uibModal,
    crmConfirmation
  ) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.$window = $window;
    this.$q = $q;
    this.$state = $state;
    this.pipelineService = pipelineService;
    this.pipelineSharedData = pipelineSharedData;
    this.loanOpportunityService = loanOpportunityService;
    this.modalRenderService = modalRenderService;
    this.contactService = contactService;
    this.configService = configService;
    this.loanScenarioService = loanScenarioService;
    this.opportunityNewModalService = opportunityNewModalService;
    this.utilitiesService = utilitiesService;
    this.$uibModal = $uibModal;
    this.crmConfirmation = crmConfirmation;

    this.removeCard = this.removeCard.bind(this);
    this.changePageColumnHeights = this.changePageColumnHeights.bind(this);
    this.processParentNotification = this.processParentNotification.bind(this);
  }

  $onInit() {
    this.PIPELINE_TYPES_STR = PIPELINE_TYPES_STR;
    this.pipelineType = this.$stateParams.pipelineType;
    this.statusList = null;
    this.isLockLoanappFeatureEnabled =
      (this.configService.feature && this.configService.feature.lockLoanapp) ||
      0;

    switch (this.pipelineType) {
      case PIPELINE_TYPES_STR.LEADS:
        this.kanbanDependencies = {
          name: `${capitalizeFirstLetter(this.pipelineType)} Kanban`,
          pipelineSharedData: this.pipelineSharedData,
          pipelineService: this.pipelineService,
          modalRenderService: this.modalRenderService,
          loanOpportunityService: this.loanOpportunityService,
          $timeout: this.$timeout,
          $window: this.$window,
          settings: this.settings || {},
          $q: this.$q,
          $state: this.$state,
          loanScenarioService: this.loanScenarioService,
          contactService: this.contactService,
          opportunityNewModalService: this.opportunityNewModalService,
        };
        this.probabilityList = PROBABILITIES;
        break;
      case PIPELINE_TYPES_STR.OPPORTUNITY:
        this.kanbanDependencies = {
          name: `${capitalizeFirstLetter(this.pipelineType)} Kanban`,
          pipelineSharedData: this.pipelineSharedData,
          pipelineService: this.pipelineService,
          modalRenderService: this.modalRenderService,
          loanOpportunityService: this.loanOpportunityService,
          $timeout: this.$timeout,
          $window: this.$window,
          settings: this.settings || {},
          $q: this.$q,
        };
        break;
      case PIPELINE_TYPES_STR.APPLICATION:
        this.kanbanDependencies = {
          name: `${capitalizeFirstLetter(this.pipelineType)} Kanban`,
          pipelineSharedData: this.pipelineSharedData,
          pipelineService: this.pipelineService,
          modalRenderService: this.modalRenderService,
          loanOpportunityService: this.loanOpportunityService,
          $timeout: this.$timeout,
          $window: this.$window,
          settings: this.settings || {},
          $q: this.$q,
        };
        break;
      default:
        break;
    }
    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });
  }

  loadKanban() {
    this.onToggleShowLoader({ bool: true });
    const promises = [this.getStatuses(), this.getCards()];

    /**
     * please make sure not to interchange the values of @param promises
     * as it is tightly coupled with the code block below
     */
    this.$q
      .all(promises)
      .then(promiseData => {
        if (!promiseData || !promiseData.length) return;
        this.onToggleShowLoader({ bool: false });

        this.statusList = promiseData[0].map(o => {
          o.IsConversion = false;
          return o;
        });
        const cards =
          (promiseData[1] && promiseData[1].cards) ||
          (promiseData[1] &&
            promiseData[1].data &&
            promiseData[1].data.cards) ||
          [];
        const totalLoanAmounts =
          (promiseData[1] && promiseData[1].totalLoanAmounts) ||
          (promiseData[1] &&
            promiseData[1].data &&
            promiseData[1].data.totalLoanAmounts) ||
          [];

        this.kanbanDependencies.columns = [];
        this.kanbanDependencies.generalCards = cards;
        this.kanbanDependencies.totalLoanAmounts = totalLoanAmounts;
        this.kanbanDependencies.statusList = this.statusList;
        this.kanbanDependencies.onRefreshStats = this.onRefreshStats;

        this.timelineStatusList = this.statusList.map(
          pipelineStatusBuilderForUI
        );

        switch (this.pipelineType) {
          case PIPELINE_TYPES_STR.LEADS:
            if (!this.kanbanBoard) {
              this.kanbanBoard = new KanbanLeads(this.kanbanDependencies);
            } else {
              this.kanbanBoard.settings = this.kanbanDependencies.settings;
              this.kanbanBoard.convertCardsToColumns(
                cards,
                totalLoanAmounts,
                this.statusList
              );
            }
            this.initialsBreakpoint = 324;
            break;
          case PIPELINE_TYPES_STR.OPPORTUNITY:
            if (!this.kanbanBoard) {
              this.kanbanBoard = new KanbanOpportunity(this.kanbanDependencies);
            } else {
              this.kanbanBoard.settings = this.kanbanDependencies.settings;
              this.kanbanBoard.convertCardsToColumns(
                cards,
                totalLoanAmounts,
                this.statusList
              );
            }
            this.initialsBreakpoint = 384;
            break;
          case PIPELINE_TYPES_STR.APPLICATION:
            if (!this.kanbanBoard) {
              this.kanbanBoard = new KanbanApplication(this.kanbanDependencies);
            } else {
              this.kanbanBoard.settings = this.kanbanDependencies.settings;
              this.kanbanBoard.convertCardsToColumns(
                cards,
                totalLoanAmounts,
                this.statusList
              );
            }
            this.initialsBreakpoint = 608;
            break;
          default:
            break;
        }
      })
      .finally(() => {
        this.setSlickSettings();
      });
  }

  getStatuses() {
    return (
      this.statusList ||
      this.pipelineService.SettingsStatusGet().then(res => {
        return (
          res &&
          res.data.filter(
            status =>
              status.CategoryName ===
              PIPELINE_SETTINGS_CATEGORY[this.pipelineType.toUpperCase()]
          )
        );
      })
    );
  }

  getCards() {
    const { searchClientName } = this.kanbanDependencies.settings;
    switch (this.pipelineType) {
      case PIPELINE_TYPES_STR.LEADS:
        return this.pipelineService.getPipelineLeadCards({
          searchClientName,
          pageSize: 15,
          pageNumber: 1,
        });
      case PIPELINE_TYPES_STR.OPPORTUNITY:
        return this.pipelineService.getPipelineOpportunityCards({
          pageSize: 15,
          pageNumber: 1,
        });
      case PIPELINE_TYPES_STR.APPLICATION:
        return this.pipelineService.getPipelineApplicationKanban({
          searchClientName,
          pageSize: 15,
          pageNumber: 1,
        });
      default:
        return [];
    }
  }

  removeCard(args = {}) {
    const { columnIndex, cardIndex } = args;
    if (typeof columnIndex !== 'number' || typeof cardIndex !== 'number') {
      /**
       * let's hack this delete to just reload the kanban if this worst-case scenario happens (no columns and cards as args)
       */
      this.loadKanban();
    } else {
      const { columns } = this.kanbanBoard;
      const { cards } = columns[columnIndex];
      cards.splice(cardIndex, 1);
      columns[columnIndex].totalRecords--;

      switch (this.pipelineType) {
        case PIPELINE_TYPES_STR.OPPORTUNITY:
          this.kanbanBoard.getLoanOpportunityTotalLoanAmounts();
          break;
        case PIPELINE_TYPES_STR.APPLICATION:
          this.kanbanBoard.getApplicationTotalLoanAmounts();
          break;
        default:
          break;
      }

      this.changePageColumnHeights();
    }
  }

  toggleIsShrink(event) {
    event && event.stopPropagation();
    this.isShrink = !this.isShrink;
  }

  setColumnSort({ column, mode }) {
    if (mode === column.SelectedFilterStatus) return;
    column.SelectedFilterStatus = mode !== 'Reset' ? mode : '';

    const sortingMode = [];
    this.kanbanBoard.columns.forEach(o => {
      if (o.SelectedFilterStatus) {
        sortingMode.push(`${o.name}:${o.SelectedFilterStatus}`);
      }
    });

    this.onChangeSettings({
      $e: { sortingMode: sortingMode.join(',') },
      triggerChildNotification: true,
    });
  }

  processParentNotification(eventHandler, data) {
    const isRefreshKanban =
      eventHandler ===
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_KANBAN;
    const isFirstLoad =
      eventHandler ===
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.ON_FIRST_LOAD;
    const isReconvertCards =
      eventHandler ===
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.RECONVERT_CARDS;
    const isRefreshTotalAmounts =
      eventHandler ===
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_TOTAL_AMOUNTS;
    const isRefreshLabels =
      eventHandler ===
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REFRESH_LABELS;
    const isRemoveCard =
      eventHandler ===
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.REMOVE_CARD;
    const isMoveCard =
      eventHandler ===
      PARENT_TO_CHILD_NOTIFICATION_REGISTRATION_HANDLERS.MOVE_CARD;

    if (isRefreshKanban || isFirstLoad) {
      if (data) this.kanbanDependencies.settings = data;
      this.loadKanban();
    } else if (isReconvertCards) {
      const method =
        this.pipelineType === PIPELINE_TYPES_STR.APPLICATION
          ? 'getPlainApplicationTotalLoanAmounts'
          : null;
      if (!method) return;

      this.kanbanBoard[method]().then(({ data: totalLoanAmounts }) => {
        this.kanbanBoard.convertCardsToColumns(
          this.kanbanDependencies.generalCards,
          totalLoanAmounts,
          this.statusList
        );
      });
    } else if (isRefreshTotalAmounts) {
      const method =
        this.pipelineType === PIPELINE_TYPES_STR.APPLICATION
          ? 'getApplicationTotalLoanAmounts'
          : null;
      if (!method) return;
      this.kanbanBoard[method]();
    } else if (isRefreshLabels) {
      const { updatedLabel, action } = data;

      if (action === 'update') {
        this.kanbanBoard.columns.forEach(column => {
          column.cards = column.cards.map(card =>
            updateLabelOfCard(card, updatedLabel)
          );
        });
      } else if (action === 'delete') {
        this.kanbanBoard.columns.forEach(column => {
          column.cards = column.cards.map(card =>
            removeLabelFromCard(card, updatedLabel.labelId)
          );
        });
      }
    } else if (isRemoveCard) {
      const { pipelineStatusId, loanId } = data;
      const { columns } = this.kanbanBoard;
      const columnIndex = columns.findIndex(
        o => o.pipelineStatusId === pipelineStatusId
      );
      if (!columnIndex === -1) return;

      const cardIndex = columns[columnIndex].cards.findIndex(
        card => card.loanID === loanId
      );
      if (cardIndex === -1) return;

      this.removeCard({ columnIndex, cardIndex });
    } else if (isMoveCard) {
      const { card, primaryKey, sourcePipelineStatusId } = data;
      this.kanbanBoard.customOptions.forceItemMove(
        card,
        primaryKey,
        sourcePipelineStatusId
      );
      this.changePageColumnHeights();
    }
  }

  setSlickSettings() {
    this.slickConfig = null;

    if (!this.kanbanBoard || !this.kanbanBoard.columns) return;
    angular.element('.pagination-container').empty();

    this.$timeout(() => {
      const maxCardLength = this.getMaxCardLength();
      const maxHeight = 60;
      const defaultSettings = {
        enabled: true,
        draggable: false,
        arrows: false,
        dots: true,
        infinite: false,
        slidesToShow: 1,
        dotsClass: `slick-dots`,
        slidesToScroll: 1,
        appendDots: '.pagination-container',
      };
      this.slickConfig = {
        ...defaultSettings,
        responsive: [
          {
            breakpoint: this.initialsBreakpoint,
            settings: {
              customPaging: (slider, idx) => {
                const slide = angular.element(slider.$slides[idx]);
                const columnName = slide.data('column-name');
                const columnCards = parseInt(slide.data('card-length'), 10);
                const columnHeight = maxHeight * (columnCards / maxCardLength);
                return `
                <div class="pager-content is-${this.pipelineType} vbox">
                  <p class="columnname flex">${this.utilitiesService.filterInitialOneString(
                    columnName
                  )}</p>
                  <div class="pagecol-representation is-${
                    this.pipelineType
                  }" id="pagecol-${idx}" style="height: ${columnHeight}px"></div>
                </div>
                `;
              },
            },
          },
          {
            breakpoint: 992,
            settings: {
              customPaging: (slider, idx) => {
                const slide = angular.element(slider.$slides[idx]);
                const columnName = slide.data('column-name');
                const columnCards = parseInt(slide.data('card-length'), 10);
                const columnHeight = maxHeight * (columnCards / maxCardLength);
                return `
                <div class="pager-content is-${this.pipelineType} vbox">
                  <p class="columnname flex twolines">${columnName}</p>
                  <div class="pagecol-representation is-${
                    this.pipelineType
                  }" id="pagecol-${idx}" style="height: ${columnHeight}px"></div>
                </div>
                `;
              },
            },
          },
        ],
      };
    }, 2000);
  }

  getMaxCardLength() {
    let maxCardLength = 0;
    this.kanbanBoard.columns.forEach(column => {
      maxCardLength =
        column && column.cards && column.cards.length > maxCardLength
          ? column.cards.length
          : maxCardLength;
      return maxCardLength;
    });
    return maxCardLength;
  }

  openMoveCard(card) {
    let cardPrimaryKey;
    if (this.pipelineType === PIPELINE_TYPES_STR.LEADS) {
      cardPrimaryKey = 'pipelineCardsID';
    } else if (this.pipelineType === PIPELINE_TYPES_STR.OPPORTUNITY) {
      cardPrimaryKey = 'loanId';
    } else {
      cardPrimaryKey = 'loanID';
    }

    const conversionStatusList = CONVERSION_PIPELINE_OPTIONS[
      this.pipelineType.toUpperCase()
    ].map(conversion => {
      const {
        PIPELINE_STATUS_ID: PipelineStatusID,
        PIPELINE_STATUS: PipelineStatus,
      } = conversion;
      const mappedObject = {
        PipelineStatusID,
        PipelineStatus,
        IsConversion: true,
      };
      return mappedObject;
    });

    const columnPrimaryKey =
      this.pipelineType === PIPELINE_TYPES_STR.LEADS ||
      this.pipelineType === PIPELINE_TYPES_STR.APPLICATION
        ? 'pipelineStatusID'
        : 'pipelineStatusId';

    const props = {
      selectedColumnId: card[columnPrimaryKey],
      columnList: [...this.statusList, ...conversionStatusList],
      proceedMoveCard: ($e, isConversion, selectedReasonId) => {
        const sourceColumnId = props.selectedColumnId;
        const sourceColumn = this.kanbanBoard.columns.find(
          o => o.pipelineStatusId === sourceColumnId
        );
        const destinationSourceColumnId = $e.selectedColumn.PipelineStatusID;
        const destinationColumn = this.kanbanBoard.columns.find(
          o => o.pipelineStatusId === destinationSourceColumnId
        );
        const sourceCardIdx = sourceColumn.cards.findIndex(
          o => o[cardPrimaryKey] === card[cardPrimaryKey]
        );

        this.kanbanBoard
          .customItemMove(
            card,
            destinationSourceColumnId,
            isConversion,
            selectedReasonId
          )
          .then(res => {
            if (res && !res.isSuccess) return;
            sourceColumn && sourceColumn.totalRecords--;

            if (res && res.isSuccess && !isConversion) {
              sourceColumn.cards.splice(sourceCardIdx, 1);
              destinationColumn.cards.splice(0, 0, card);
              destinationColumn && destinationColumn.totalRecords++;

              $e.cancel();

              const destinationColumnIdx = this.kanbanBoard.columns.findIndex(
                o => o.pipelineStatusId === destinationSourceColumnId
              );
              const list =
                this.$window.document.querySelector('ul.slick-dots') || {};
              const child = list.children[destinationColumnIdx];
              if (!child) return;
              angular.element(child).triggerHandler('click');
            } else if (
              isConversion &&
              destinationSourceColumnId !== PIPELINE_STATUS.SETTLED
            ) {
              this.crmConfirmation
                .open({
                  type: 'success',
                  title: 'Success',
                  description: 'Card Successfully Converted',
                  buttonText: 'Okay, got it!',
                })
                .result.then(() => {
                  sourceColumn.cards.splice(sourceCardIdx, 1);
                  sourceColumn && sourceColumn.totalRecords--;
                  $e.cancel();
                });
            } else if (isConversion) {
              sourceColumn.cards.splice(sourceCardIdx, 1);
              $e.cancel();
            } else {
              toastr.error('Ooops, something went wrong', 'Error');
            }
          });
      },
      moveCard: $e => {
        if (props.selectedColumnId === $e.selectedColumn.PipelineStatusID)
          return;
        const destinationSourceColumnId = $e.selectedColumn.PipelineStatusID;
        const destinationStatus = props.columnList.find(
          o =>
            o.PipelineStatusID === destinationSourceColumnId &&
            o.IsConversion === $e.selectedColumn.IsConversion
        );

        if (destinationStatus.IsConversion) {
          this.modalRenderService
            .kanbanCustomItemMoveConfirmation(destinationStatus.PipelineStatus)
            .result.then(({ isOk }) => {
              if (!isOk) {
                $e.cancel();
                return;
              }

              if (destinationSourceColumnId === PIPELINE_STATUS.NOT_PROCEEDED) {
                this.modalRenderService
                  .kanbanReasonForNotProceeding(this.kanbanBoard.reasonList)
                  .result.then(({ isOk: isReasonOk, selectedReasonId }) => {
                    if (!isReasonOk) {
                      $e.cancel();
                      return;
                    }
                    props.proceedMoveCard(
                      $e,
                      destinationStatus.IsConversion,
                      selectedReasonId
                    );
                  });
              } else {
                props.proceedMoveCard($e, destinationStatus.IsConversion);
              }
            });
        } else {
          props.proceedMoveCard($e);
        }
      },
    };

    this.$uibModal
      .open({
        template: `<pipeline-move-card-modal
        modal-instance="vm.modalInstance"
        selected-column-id="vm.props.selectedColumnId"
        column-list="vm.props.columnList"
        on-move-card="vm.props.moveCard($e)"
      ></pipeline-move-card-modal>`,
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        size: 'sm',
        windowClass: 'pipeline-move-card-modal',
        keyboard: false,
        backdrop: 'static',
        resolve: {
          props: () => props,
        },
      })
      .result.then(
        () => {
          this.changePageColumnHeights();
        },
        () => {
          this.changePageColumnHeights();
        }
      );
  }

  changePageColumnHeights() {
    const maxLimit = this.getMaxCardLength();
    let idx = 0;

    const maxHeight = 60;
    this.kanbanBoard.columns.forEach(column => {
      const newHeight = maxHeight * (column.cards.length / maxLimit);
      angular.element(`#pagecol-${idx}`).css({ height: `${newHeight}px` });
      idx++;
    });
  }

  onViewingMoreCards(column) {
    if (this.disableViewMore) return;

    column.loading = true;
    this.$timeout(() => {
      this.disableViewMore = true;
    }, 0);
    const { pageNumber } = column;
    const statusId = column.pipelineStatusId || column.pipelineStatusID;
    const pageSize = 15;
    this.viewMoreCards(pageNumber + 1, pageSize, statusId).then(response => {
      this.disableViewMore = false;
      column.loading = false;
      if (!response) return;
      const { newCards, newTotalLoans } = response;

      column.pageNumber++;
      column.cards = [...column.cards, ...newCards];
      column.totalLoanAmount && (column.totalLoanAmount = newTotalLoans);
      this.disableViewMore = false;
    });
  }

  viewMoreCards(pageNumber, pageSize, statusId) {
    const { searchClientName } = this.kanbanDependencies.settings;
    switch (this.pipelineType) {
      case PIPELINE_TYPES_STR.LEADS:
        return this.pipelineService
          .getPipelineLeadCards({
            searchClientName,
            pageNumber,
            pageSize,
            statusId,
          })
          .then(({ data, succeeded }) => {
            if (!data || !succeeded) return false;
            const { cards } = data;
            return {
              newCards: (cards && cards.filter(leadCardMap)) || [],
            };
          });
      case PIPELINE_TYPES_STR.OPPORTUNITY:
        return this.pipelineService
          .getPipelineOpportunityCards({ pageNumber, pageSize, statusId })
          .then(data => {
            if (!data) return false;
            const { cards, totalLoanAmounts } = data;
            return {
              newCards: (cards && cards.filter(opportunityCardMap)) || [],
              newTotalLoans:
                (totalLoanAmounts &&
                  totalLoanAmounts.filter(o => o.statusId === statusId)) ||
                0,
            };
          });
      case PIPELINE_TYPES_STR.APPLICATION:
        return this.pipelineService
          .getPipelineApplicationKanban({
            searchClientName,
            pageNumber,
            pageSize,
            statusId,
          })
          .then(({ data, succeeded }) => {
            if (!data || !succeeded) return false;
            const { cards, totalLoanAmounts } = data;
            return {
              newCards: (cards && cards.filter(applicationCardMap)) || [],
              newTotalLoans:
                (totalLoanAmounts &&
                  totalLoanAmounts.filter(o => o.statusId === statusId)) ||
                0,
            };
          });
      default:
        return {};
    }
  }
}

import angular from 'angular';

angular.module('app').factory('modelDataService', function modelDataService() {
  /*
  *=====================================
  *        FUNCTION DEFINITION(S)
  *=====================================
  */
  const addressModelData = () => {
    return {
      street_address: '',
      route: '',
      intersection: '',
      political: '',
      country: '',
      administrative_area_level_1: '',
      administrative_area_level_2: '',
      administrative_area_level_3: '',
      administrative_area_level_4: '',
      administrative_area_level_5: '',
      colloquial_area: '',
      locality: '',
      ward: '',
      sublocality: '',
      sublocality_level_1: '',
      sublocality_level_2: '',
      sublocality_level_3: '',
      sublocality_level_4: '',
      sublocality_level_5: '',
      neighborhood: '',
      premise: '',
      subpremise: '',
      postal_code: '',
      natural_feature: '',
      airport: '',
      park: '',
      point_of_interest: '',
      floor: '',
      establishment: '',
      parking: '',
      post_box: '',
      postal_town: '',
      room: '',
      street_number: '',
      bus_station: '',
      train_station: '',
      transit_station: '',
      latitude: 0,
      longitude: 0,
      geoCoded: false,
      stateShort_Name: '',
      countryCodeInTwoLetter: '',
      isMailing: true,
      campground: '',
      lodging: '',
      description: '',
      place_id: '',
      isBusiness: true,
      Google_formatted_address: ' ',
      FamilyId: 0,
      AddressID: '',
      Type: '',
      TypeId: '',
      formatted_address: '',
      ContactLivingIn: [
        {
          BorrowerID: 0,
          FirstName: '',
          LastName: '',
          IsInclude: true,
          IsEntity: true,
          IncludeGuarantor: 0,
          FamilyId: 0,
        },
      ],
      OwnershipType: '',
      OwnershipTypeId: 0,
      AddressValuationExtendedDetailsId: 0,
      RentalAmount: 0,
      FrequencyTypeId: 0,
    };
  };

  const addressMakeModelData = () => {
    return {
      Address: {
        street_address: '',
        route: '',
        intersection: '',
        political: '',
        country: '',
        administrative_area_level_1: '',
        administrative_area_level_2: '',
        administrative_area_level_3: '',
        administrative_area_level_4: '',
        administrative_area_level_5: '',
        latitude: '',
        longitude: '',
        geoCoded: '',
        short_name: '',
        countryCode: '',
        IsMailing: false,
        AddressID: 0,
        Type: '',
        TypeId: '',
        formatted_address: '',
        AddressValuationExtendedDetailsId: 0,
      },
    };
  };

  const referrerModelDate = (member, referrerOrgId) => {
    return {
      ClientID: 0,
      FamilyId: member.ReferrerFamilyId,
      FranchiseeDetailsId: 0,
      Title: null,
      FirstName: member.FirstName || '',
      MiddleName: '',
      LastName: member.LastName || '',
      PreferredName: '',
      AdviserOrganizationId: Number(referrerOrgId),
      WorkNumber: member.Phone || '',
      Mobile: '',
      Fax: '',
      Email: member.Email || '',
      BrokerStatusId: 0,
      Description: '',
      RecruitedBy_FamilyId: 0,
      ContactType: 'Referrer',
      AccountOwner_FamilyId: 0,
      ReportingCountryId: 0,
      ReportingStateId: 0,
      ReportingRegionId: 0,
      DOB: '',
      Gender: 3,
      SecondaryEmail: '',
      HomeNumber: '',
      LanguagesSpoken: '',
      GeneralInterests: '',
      SportsTeam: '',
      ReceiveRecruitNewsLetter: 0,
      CommissionTierId: 0,
      AchievementId: 0,
      IndividualAchievementId: 0,
      JobTitle: '',
      PreviousAggregator: '',
      BrokerReferredBy: '',
      EnquirySourceId: 0,
      AppointmentEntity: '',
      RoleId: '',
      EmploymentType: '',
      EntityTypeId: '',
      AppointmentEntityShareholder: '',
      ABN: '',
      ACN: '',
      DisplayTitle: '',
      Location: '',
      EmploymentTypeId: 0,
    };
  };

  const complianceReviewModel = () => {
    return {
      complianceReviewId: 0,
      adviserFamilyId: 0,
      reviewerFamilyId: 0,
      rating: 0,
      comments: '',
    };
  };
  /*
  *   =====================================
  *           FUNCTION REFERENCE
  *   =====================================
  */
  const service = {
    addressModelData,
    referrerModelDate,
    addressMakeModelData,
    complianceReviewModel,
  };
  return service;
});

class CommunicateService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'MailChimp';
    this.apiReferralBaseUrl = 'Referral';
    this.apiContactsBaseUrl = 'contacts';
    this.apiCorporateBaseUrl = 'corporate';
  }

  templatesGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/templates`);
  }

  getTemplates() {
    return this.httpClient.get(`${this.apiBaseUrl}/GetTemplates`);
  }

  getCampaignReports() {
    return this.httpClient.get(`${this.apiBaseUrl}/GetCampaignReports`);
  }

  getEmailCampaignReferrers(
    userId,
    isActive,
    referrerOrgId,
    adviserOrgId,
    adviserId,
    recentLeadInDays,
    recentLeadToInDays,
    pageNumber,
    pageSize
  ) {
    return this.httpClient.get('email-campaign-referrers', {
      userId,
      isActive,
      referrerOrgId,
      adviserOrgId,
      adviserId,
      recentLeadInDays,
      recentLeadToInDays,
      pageNumber,
      pageSize,
    });
  }

  numberCampaignStarted(listId = '9411174a1a') {
    return this.httpClient.get(`${this.apiBaseUrl}/NumberCampaignStarted`, {
      listId,
    });
  }

  numberSentEmails(listId = '9411174a1a') {
    return this.httpClient.get(`${this.apiBaseUrl}/NumberSentEmails`, {
      listId,
    });
  }

  exportRecipientsToCSV(query) {
    // TODO: will refactory the query parameter
    return this.httpClient.get(
      `${this.apiReferralBaseUrl}/ExportRecipientsToCSV${query}`
    );
  }

  recipientsExportCSV(data) {
    return this.httpClient.post(
      `${this.apiReferralBaseUrl}/RecipientsExportCSV`,
      data
    );
  }

  sendListToMailChimp(
    listName,
    permisionRemider,
    fromEmail,
    fromName,
    subject,
    campaignIdOfTemplate = '',
    data
  ) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/SendListToMailChimp`,
      data,
      {
        listName,
        permisionRemider,
        fromEmail,
        fromName,
        subject,
        campaignIdOfTemplate,
      }
    );
  }

  sendActionCampaign(campaignId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/SendActionCampaign`,
      {},
      { campaignId }
    );
  }

  openMailChimpList(listId = '9411174a1a') {
    return this.httpClient.get(`${this.apiBaseUrl}/OpenMailChimpList`, {
      listId,
    });
  }

  loanStatusListGet() {
    return this.httpClient.get(`${this.apiContactsBaseUrl}/LoanStatusListGet`);
  }

  lenderListGet(lenderPlace) {
    return this.httpClient.get(`${this.apiContactsBaseUrl}/LenderListGet`, {
      lenderPlace,
    });
  }

  categoryListGet() {
    return this.httpClient.get(`${this.apiContactsBaseUrl}/CategoryListGet`);
  }

  ratingListGet() {
    return this.httpClient.get(`${this.apiContactsBaseUrl}/RatingListGet`);
  }

  getAdvisersList(pFamilyID = 0, pSearchSameAdviserOrg = 0) {
    // TODO: will move this to general
    return this.httpClient.get('GetAdvisersList', {
      pFamilyID,
      pSearchSameAdviserOrg,
    });
  }

  inquirySourceListGet() {
    return this.httpClient.get(
      `${this.apiCorporateBaseUrl}/InquirySourceListGet`
    );
  }

  campaignRecipientGet(params) {
    return this.httpClient.get(
      `${this.apiReferralBaseUrl}/CampaignRecipientGet`,
      params
    );
  }

  getCampaignAdviser(
    reportingCountry,
    reportingState,
    reportingRegion,
    contactStatus,
    categories,
    enquirySource,
    clientType,
    contactType,
    pageNumber,
    pageSize
  ) {
    return this.httpClient.get(
      `${this.apiReferralBaseUrl}/CampaignAdviserGet`,
      {
        reportingCountry,
        reportingState,
        reportingRegion,
        contactStatus,
        categories,
        enquirySource,
        clientType,
        contactType,
        pageNumber,
        pageSize,
      }
    );
  }

  getEmailCampaignReferrersExcelExport(
    userId,
    isActive,
    referrerOrgId,
    adviserOrgId,
    adviserId,
    recentLeadInDays,
    recentLeadToInDays,
    pageNumber,
    pageSize
  ) {
    return this.httpClient.get('email-campaign-referrers-excel', {
      userId,
      isActive,
      referrerOrgId,
      adviserOrgId,
      adviserId,
      recentLeadInDays,
      recentLeadToInDays,
      pageNumber,
      pageSize,
    });
  }
}

export default CommunicateService;

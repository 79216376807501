import { values } from 'lodash';
import { ASSET_TYPE } from 'Common/constants/securityValues';

export default class AssetFinanceProductFinderCtrl {
  constructor(
    $timeout,
    $uibModal,
    loanProfilerService,
    optionsService,
    NgTableParams,
    currentUserService,
    productFinderService,
    lenderRatesService,
    bouncingTooltipService,
    iFinanceAppService,
    serviceabilityService,
    toaster
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$uibModal = $uibModal;
    this.loanProfilerService = loanProfilerService;
    this.optionsService = optionsService;
    this.NgTableParams = NgTableParams;
    this.currentUserService = currentUserService;
    this.productFinderService = productFinderService;
    this.lenderRatesService = lenderRatesService;
    this.bouncingTooltipService = bouncingTooltipService;
    this.iFinanceAppService = iFinanceAppService;
    this.serviceabilityService = serviceabilityService;
    this.toaster = toaster;
    this.ASSET_TYPE = ASSET_TYPE;
  }

  $onInit() {
    this.clientType = [];
    this.familyMembersData = {
      loanAmount: 0,
      commissionPercent: 0,
      residualPercent: 0,
      documentFeePercent: 0,
      propertyOwner: false,
    };
    this.loanProviderLendersListGetAll = [];
    this.loanProviderLendersListSet = [];
    this.loanStructureList = [];
    this.defaultSelection = {};
    this.generalSettingsValid = true;
    this.crmCurrency = '$';
    this.calculatedAmount = {
      ResidualAmount: 0,
      CommissionAmount: 0,
      DocumentFeeAmount: 0,
    };
    this.disableSearchFilter = true;

    this.filterOptions = {
      saleTypeList: [],
      assetVariantList: [],
      lenderList: [],
    };
    this.filterDataList = {};

    this.fromLenderRateObject = {
      isFromLenderRates: !!Object.keys(
        this.lenderRatesService.getLenderRateInfoForProductFinder()
      ).length,
    };

    this.asset = {
      vehicleDetails: {},
    };

    this.getFilterData();
    this.resetFilter();
  }

  resetFilter() {
    this.filterDataList = {
      saleType: 'All',
      assetVariant: 'All',
      lender: 'All',
      establishmentFee: false,
      accountKeeping: false,
    };
  }

  getFilterData() {
    this.getGeneralDropdown();
    this.getGeneralFilter();

    this.loanProfilerService
      .getServiceabilityFiltersParamters()
      .then(response => {
        if (!response || !response.data) return;
        const { data } = response;

        if (data.Lenders && data.Lenders.length)
          this.loanProviderListFormat(data.Lenders);
        this.getDefaultFilterSelection();
        this.getLoanStructure(true);
      });
  }

  getGeneralFilter() {
    this.serviceabilityService
      .getFilteringProductsGeneralSettings(this.brokerEventId)
      .then(res => {
        if (!res) return;
        this.familyMembersData = res;
        const maxTerm = 7;
        this.familyMembersData.loanTerm =
          parseFloat(res.loanTerm) > maxTerm ? '' : res.loanTerm;
        this.asset.vehicleDetails = {
          redBookCode: res.redBookCode,
          model: res.description,
          vehicleClassID: res.vehicleClassType,
          make: res.vehicleMake,
          family: res.vehicleFamily,
          month: res.vehicleMonth,
          year: res.vehicleYear,
        };
      });
  }

  openVehicleModal() {
    const props = {
      brokerEventId: this.brokerEventId,
      asset: this.asset,
    };
    const modalInstance = this.$uibModal.open({
      template: `<asset-finance-vehicle-modal
                  modal-instance="vm.modalInstance"
                  asset="vm.props.asset"
                  broker-event-id="vm.props.brokerEventId">
                </asset-finance-vehicle-modal>`,
      backdrop: 'static',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then(response => {
      this.familyMembersData.redBookCode = '';
      if (
        !response ||
        !response.vehicleDetails ||
        !response.vehicleDetails.redBookCode
      )
        return;
      this.asset = response;

      const details = response.vehicleDetails;
      const vehicle = {
        redBookCode: details.redBookCode,
        description: details.model,
        vehicleClassType: details.vehicleClassID,
        vehicleMake: details.make,
        vehicleFamily: details.family,
        vehicleMonth: details.month,
        vehicleYear: details.year,
      };

      this.familyMembersData = { ...this.familyMembersData, ...vehicle };
    });
  }

  loanProviderListFormat(lenders) {
    lenders.forEach(productFinderItem => {
      const length = 12;
      const trimmedString =
        productFinderItem.Name.length > length
          ? `${productFinderItem.Name.substring(0, length - 3)}...`
          : productFinderItem.Name;
      if (productFinderItem.Value !== 'FavouriteLenders') {
        this.loanProviderLendersListGetAll.push({
          name: productFinderItem.Name,
          shortName: trimmedString,
          value: productFinderItem.Value,
          ticked: false,
        });
      }
    });
  }

  getGeneralDropdown() {
    this.optionsService.getAssetFinanceSecurityType().then(response => {
      if (!response || !response.length) return;
      this.assetTypeList = response;
    });

    this.optionsService.getAssetFinanceCategory().then(response => {
      if (!response || !response.length) return;
      this.financeTypeList = response;
    });

    this.optionsService.getAssetFinanceLoanTerms().then(response => {
      if (!response || !response.length) return;
      this.loanTermsList = response;
    });

    this.iFinanceAppService.getQuotePurposeType().then(res => {
      this.clientType = res;
    });

    this.iFinanceAppService.getSaleType().then(res => {
      this.filterOptions.saleTypeList = res;
    });
  }

  getDefaultFilterSelection() {
    this.loanProfilerService
      .getServiceabilityFilteringProductsGeneralSettings(this.brokerEventId)
      .then(response => {
        if (!response || !response.data) return;

        this.defaultSelection = response.data;
        if (Number(this.defaultSelection.loanAmount) === 0) {
          this.defaultSelection.loanAmount = '';
        } else if (this.defaultSelection.loanAmount !== '')
          this.defaultSelection.loanAmount = Number(
            this.defaultSelection.loanAmount
          );
        if (
          !this.defaultSelection.security ||
          Number(this.defaultSelection.security) === 0
        )
          this.defaultSelection.security = '';
        else
          this.defaultSelection.security = Number(
            this.defaultSelection.security
          );
        this.lenderSelection = response.data.Lender;
        this.loanProviderLendersListSet = [];
        if (
          typeof response.data.Lender !== 'undefined' &&
          response.data.Lender.length !== 0
        ) {
          this.defaultSelection.Lender.forEach(item => {
            const data = this.loanProviderLendersListGetAll.find(itemGet => {
              if (itemGet.value === item) {
                itemGet.ticked = true;
              }
              return itemGet.value === item;
            });
            if (typeof data !== 'undefined')
              this.loanProviderLendersListSet.push(data);
          });
        }
      });
  }

  getLoanStructure(isOverlay) {
    this.loanProfilerService
      .getProductFinderLoanStructureList(this.brokerEventId)
      .then(response => {
        const { data } = response;
        if (!data || !data.length) {
          this.getLoanStructure(true);
          return;
        }
        this.loanStructureList = data;

        this.loanStructureList.forEach(loanStructure => {
          loanStructure.isLoanStructure = true;
          loanStructure.loanProviderLendersListGetAll = [];
          loanStructure.selectedProduct = [];

          this.getSelectedProductList(loanStructure);
          if (isOverlay) this.updateOverlaySearch();
        });
      });
  }

  groupFilter(data) {
    const filters = ['assetVariantList', 'lenderList'];

    filters.filter(filterType => {
      const result = ['All'];
      const field =
        filterType === 'assetVariantList' ? 'asset_variant' : 'lender';
      data.filter(item => {
        if (result.indexOf(item[field]) < 0) result.push(item[field]);
        return item;
      });
      this.filterOptions[filterType] = result;
      return filterType;
    });

    this.resetFilter();
  }

  filterResult(data) {
    if (this.disableSearchFilter) return data;

    const {
      saleType,
      assetVariant,
      lender,
      establishmentFee,
      accountKeeping,
    } = this.filterDataList;
    const newData = data.filter(item => {
      const estFee =
        (establishmentFee && parseInt(item.establishment_fee, 10) === 0) ||
        !establishmentFee;
      const accFee =
        (accountKeeping && parseInt(item.account_keeping_fee, 10) === 0) ||
        !accountKeeping;
      const isSupplier = saleType === 'All' || item.supplier === saleType;
      const isAssetVariant =
        assetVariant === 'All' || item.asset_variant === assetVariant;
      const isLender = lender === 'All' || item.lender === lender;
      const isMatch =
        estFee && accFee && isSupplier && isAssetVariant && isLender;
      return isMatch;
    });

    return newData;
  }

  sortArray(data, sortCol, sortType) {
    const asc = (a, b) => {
      return a[sortCol].localeCompare(b[sortCol]);
    };
    const desc = (a, b) => {
      return b[sortCol].localeCompare(a[sortCol]);
    };

    data.sort(sortType === 'ASC' ? asc : desc);
    if (this.disableSearchFilter) this.groupFilter(data);

    const newData = this.filterResult(data);
    this.disableSearchFilter = false;
    return newData;
  }

  loadProductFinderList(loanStructure, parameters = {}) {
    this.countPerPage = 10;
    loanStructure.isSearchByLender = false;
    loanStructure.LoanStructureID = this.fromLenderRateObject.isFromLenderRates
      ? 0
      : loanStructure.LoanStructureID;

    const getProductFinder = (params, sortColumn, sortType, $defer) => {
      this.iFinanceAppService.getQuote(parameters).then(response => {
        if (!response || !response.data || !response.data.data) {
          loanStructure.isProductLoaderOverlayDisplay = false;

          $defer.resolve([]);
          return;
        }

        const { data } = response.data;

        params.total(data.length);

        if (this.currentUserService.isAU) {
          this.productFinderService
            .returnAddedSpreadsheetToProductList(data)
            .then(withSpreadsheet => {
              if (!withSpreadsheet) return;

              const sortedData = this.sortArray(
                withSpreadsheet,
                sortColumn,
                sortType
              );

              $defer.resolve(sortedData);
              loanStructure.isProductLoaderOverlayDisplay = false;
            });
        } else {
          $defer.resolve(data);
        }
      });
    };

    const tableParams = {
      page: 1,
      count: this.countPerPage,
      sorting: {
        lender: 'asc',
      },
      defaultSort: 'asc',
    };

    const tableSecondParams = {
      total: 0,
      getData($defer, params) {
        loanStructure.isProductLoaderOverlayDisplay = true;
        if (!loanStructure.isSearchByLender) {
          const sortColumn = params.orderBy()[0].substring(1);
          const sortType = values(params.sorting())[0].toUpperCase();

          getProductFinder(params, sortColumn, sortType, $defer);
        } else {
          this.loanProfilerService
            .lenderListWithProductCountGet(
              this.brokerEventId,
              loanStructure.LoanStructureID,
              params.page(),
              params.count(),
              null,
              null,
              this.fromLenderRateObject.isFromLenderRates
            )
            .then(response => {
              params.total(
                (response && response.data && response.data.Count) || 0
              );

              $defer.resolve(response.data.LenderProducts);
              loanStructure.isProductLoaderOverlayDisplay = false;
            });
        }
      },
    };

    loanStructure.productFinderTableParams = new this.NgTableParams(
      tableParams,
      tableSecondParams
    );
  }

  getSelectedProductList(loanStructure) {
    this.loanProfilerService
      .getFavouriteProduct(this.brokerEventId, loanStructure.LoanStructureID)
      .then(response => {
        if (!response || !response.data) return;

        loanStructure.selectedProduct = response.data;
        this.showSelctedProduct = this.screenSize > 1440 ? 3 : 2;
      });
  }

  updateOverlaySearch() {
    this.loanStructureList.forEach(loanStructure => {
      loanStructure.isProductSearchOverlayDisplay = true;
      this.isProductSearchOverlayDisplay =
        loanStructure.isProductSearchOverlayDisplay;
    });
  }

  hoverProductName(bool) {
    this.isHoverProductName = bool;
  }

  deleteVehicle() {
    this.familyMembersData.redBookCode = '';
    this.asset = {
      vehicleDetails: {},
    };
  }

  updateFilterData(loanStructure, isLoadTable) {
    this.generalSettingsValid = true;
    const {
      clientType,
      financeType,
      loanTerm,
      assetType,
      purchasePrice,
      commissionPercent,
      residualPercent,
      documentFeePercent,
      propertyOwner,
      loanAmount,
      redBookCode,
      description,
      vehicleClassType,
      vehicleMake,
      vehicleFamily,
      vehicleMonth,
      vehicleYear,
    } = this.familyMembersData;

    if (
      !clientType ||
      !financeType ||
      !loanTerm ||
      !assetType ||
      !purchasePrice
    ) {
      this.generalSettingsValid = false;
      this.toaster.pop('error', 'Oops!', 'Please fill all required fields.');
      return;
    } else if (assetType === this.ASSET_TYPE.MOTOR_VEHICLE && !redBookCode) {
      this.toaster.pop('error', 'Oops!', 'Please select a vehicle.');
      return;
    }

    loanStructure.isProductSearchOverlayDisplay = false;
    this.isProductSearchOverlayDisplay =
      loanStructure.isProductSearchOverlayDisplay;

    if (isLoadTable) loanStructure.isProductLoaderOverlayDisplay = true;

    const generalSettingsCopy = {
      ClientType: clientType,
      AssetType: assetType,
      FinanceType: financeType,
      loanTerm,
      PurchasePrice: purchasePrice,
      CommissionPercent: commissionPercent,
      ResidualPercent: residualPercent,
      DocumentFeePercent: documentFeePercent,
      PropertyOwner: propertyOwner,
      loanAmount,
      ...this.calculatedAmount,
      Lender: ['All'],
      IsAssetFinance: true,
      RedBookCode: redBookCode,
      Description: description,
      VehicleClassType: vehicleClassType,
      VehicleMake: vehicleMake,
      VehicleFamily: vehicleFamily,
      VehicleMonth: vehicleMonth,
      VehicleYear: vehicleYear,
    };
    const postData = {
      GeneralSetting: generalSettingsCopy,
    };

    this.loanProfilerService
      .updateServiceabilityFilteringProductsSettings(
        this.brokerEventId,
        postData
      )
      .then(() => {
        if (isLoadTable) {
          this.loadIfinanceQuote(loanStructure);
          this.bouncingTooltipService.setTooltipVisible(true);
        }
        this.onSearch();
      })
      .catch(() => {
        this.toaster.pop('error', 'Error', 'Yikes! Something is wrong');
      });
  }

  loadIfinanceQuote(loanStructure) {
    const {
      clientType: purposeType,
      financeType: quoteType,
      loanTerm: term,
      assetType,
      purchasePrice,
      commissionPercent: commissionPercentage,
      residualPercent: residualPercentage,
      documentFeePercent: brokerPercentage,
      propertyOwner,
      loanAmount: tradeInAmount,
      redBookCode,
    } = this.familyMembersData;

    const params = {
      purposeType,
      assetType,
      residentialStatusType: propertyOwner ? 1 : 2,
      quoteType,
      purchasePrice,
      term: Math.round(term * 12),
      brokerPercentage,
      commissionPercentage,
      residualPercentage,
      tradeInAmount,
      redBookCode:
        assetType === this.ASSET_TYPE.MOTOR_VEHICLE ? redBookCode : '',
    };
    this.loadProductFinderList(loanStructure, params);
  }

  changeSorting(loanStructure, $column) {
    this.nice = '';
    if (!$column.sortType) $column.sortType = 'asc';
    else if ($column.sortType === 'asc') $column.sortType = 'desc';
    else {
      $column.sortType = 'asc';
    }
    loanStructure.productFinderTableParams.sorting(
      $column.sortable(),
      $column.sortType
    );
    loanStructure.productFinderTableParams.reload();
  }

  updateFamilyMembers(searchType) {
    if (searchType === 'assetType') {
      if (
        this.familyMembersData.assetType === this.ASSET_TYPE.MOTOR_VEHICLE &&
        !this.familyMembersData.redBookCode
      )
        this.openVehicleModal();
    }
    this.disableSearchFilter = true;
    this.calculateAmount();
  }

  calculateAmount() {
    const loanAmount = this.familyMembersData.loanAmount;
    this.resetAmount();
    if (loanAmount) {
      this.calculatedAmount.ResidualAmount =
        loanAmount * (this.familyMembersData.residualPercent / 100);
      this.calculatedAmount.CommissionAmount =
        loanAmount * (this.familyMembersData.commissionPercent / 100);
      this.calculatedAmount.DocumentFeeAmount =
        loanAmount * (this.familyMembersData.documentFeePercent / 100);
    }
  }

  resetAmount() {
    this.calculatedAmount.ResidualAmount = 0;
    this.calculatedAmount.CommissionAmount = 0;
    this.calculatedAmount.DocumentFeeAmount = 0;
  }

  deleteLoanStructureProduct(loanStructure, selectedProduct, $event) {
    this.getSelectedProductList(loanStructure);
    selectedProduct.isAddedForComparison = true;
    $event.stopPropagation();
  }
}

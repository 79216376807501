class ContactInsuranceSharedDataService {
  tempInsuranceDetailsInput(FamilyID) {
    this.data = {
      BenefitID: 0,
      FamilyID,
      InsuranceDetails: {
        PolicyID: 0,
        BenefitTypeID: '',
        QPRBenefitID: 0,
        ProviderID: '',
        ProductID: '',
        ClientID: 0,
        CurrentClaim: false,
        InArears: false,
        PolicyNumber: '',
        StatusID: '',
        CommencementDate: '',
        PaymentFrequencyID: '',
        WaivePolicy: false,
        AutoPremium: true,
        AutoPolicyFee: true,
        TotalPremium: 0,
        PolicyFee: 0,
        BenefitNotes: '',
        QMErrorMessage: '',
        PolicyOwnership: [
          {
            POID: null,
            ClientID: 0,
            IsDependent: true,
            IsOwnership: true,
            IsInsured: true,
            Other: '',
          },
        ],
        LoadingExclusions: [
          {
            BenefitID: 0,
            FamilyID: 0,
            PolicyID: 0,
            BenefitTypeID: '',
            ClientID: 0,
            StandardRates: true,
            LoadingExclusion: false,
            Loading: 0,
            LoadingExclusionDetails: '',
            LoadingExclusionDate: '',
          },
        ],
      },
      ClientBenefits: {
        HealthCover: [
          {
            ClientID: 0,
            BaseCover: true,
            Loading: 0,
            Excess: '',
            SpecialistsTest: true,
            GpPrescriptions: true,
            DentalOptical: true,
          },
        ],
        LifeCover: {
          Indexed: true,
          FutureInsurability: false,
          Loading: 0,
          CoverAmount: '',
          CalcPeriod: '',
        },
        FamilyProtection: {
          CalcPeriod: '',
          FutureInsurability: false,
          Indexed: true,
          Loading: 0,
          CoverAmount: '',
          BenefitPeriodTerm: null,
          BenefitPeriodYear: '',
        },
        TraumaCover: {
          Comprehensive: false,
          EarlyCancer: false,
          EarlyTrauma: false,
          TpdExtension: false,
          CalcPeriod: '',
          TraumaOwnOccupation: false,
          TraumaChild: [0],
          Indexed: false,
          IncludeChildren: false,
          LifeBuyBack: false,
          TraumaBuyBack: false,
          TpdAddOn: false,
          ChildTrauma: false,
          Loading: 0,
          CoverAmount: '',
          Standalone: false,
          Accelerated: false,
          TpdAnyOccupation: false,
          ChildCoverAmount: null,
        },
        TotalAndPermanentDisability: {
          CalcPeriod: '',
          Indexed: false,
          Loading: 0,
          CoverAmount: '',
          OwnOccupation: true,
          AnyOccupation: false,
          Standalone: true,
          Accelerated: false,
        },
        IncomeProtection: {
          IPPremier: true,
          IPCoverType: '',
          CalcPeriod: '',
          Indexed: true,
          Booster: true,
          CoverAmount: '',
          Loading: 0,
          WeekWaitPeriod: '',
          BenefitPeriod: '',
          IsTaxable: 0,
        },
        MortgageRepaymentCover: {
          CalcPeriod: '',
          Indexed: true,
          Loading: 0,
          CoverAmount: '',
          WeekWaitPeriod: '',
          BenefitPeriod: '',
        },
        RedundancyCover: {
          CalcPeriod: '',
          Indexed: true,
          Loading: 0,
          CoverAmount: '',
          HasMpCover: true,
          MRPCoverAmount: 0,
          WeekWaitPeriod: '',
        },
        MortgageLifeCover: {
          Indexed: true,
          FutureInsurability: false,
          Loading: 0,
          CoverAmount: '',
          CalcPeriod: '',
        },
      },
    };

    return this.data;
  }
}

export default ContactInsuranceSharedDataService;

import angular from 'angular';
import mergeContact from './mergeContact';
import clientInsurance from './clientInsurance';
import insuranceAppWorkbench from './insuranceAppWorkbench';

export default angular.module('app.scenes', [
  mergeContact,
  clientInsurance,
  insuranceAppWorkbench,
]).name;

import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { forEach } from 'lodash';

class AccountService {
  constructor(configService) {
    'ngInject';

    this.configService = configService;
  }
  saveCheck(list, form, accessType, allocatedAdviser) {
    this.list = list;
    let errKey = 0;
    forEach(list, (value, key) => {
      if (value.isIncluded) {
        if (!value.FirstName) {
          form.$invalid = true;
          errKey = key;
        } else if (!value.LastName) {
          form.$invalid = true;
          errKey = key;
        }
      }
      if (
        accessType === ACCESS_TYPE.CORPORATE &&
        !allocatedAdviser.adviserSelected.FullName.description
      ) {
        form.$invalid = true;
        errKey = key;
      }
    });
    return errKey;
  }

  accountDropletReadyOptions(inter) {
    if (inter) {
      inter.allowedExtensions(['jpg', 'png', 'gif']);
      inter.setRequestUrl(
        `${this.configService.resource}/contacts/DocumentUpload`
      );
      inter.setRequestHeaders({ Authorization: this.configService.token });
      inter.defineHTTPSuccess([/2.{2}/]);
      inter.useArray(false);
    }
    return inter;
  }

  copyReferral(familyDetails, contactInfoSetValue) {
    this.referral = {
      ReferralCategory: contactInfoSetValue.ReferralCategory,
      ReferralCategoryId: contactInfoSetValue.ReferralCategoryId,
      ReferralItem: contactInfoSetValue.ReferralItem,
      ReferralItemId: contactInfoSetValue.ReferralItemId,
      ReferrerOrgName: contactInfoSetValue.ReferrerOrgName,
      ReferrerName: contactInfoSetValue.ReferrerName,
      ReferrerID: contactInfoSetValue.ReferrerID,
      ReferrerOrgID: contactInfoSetValue.ReferrerOrgID,
      ReferrerAgreementTypeId: contactInfoSetValue.ReferrerAgreementTypeId,
    };
    return { ...familyDetails, ...this.referral };
  }
}

export default AccountService;

import { reject } from 'lodash';
import moment from 'moment-timezone';
import { toastError } from 'Common/utilities/alert';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

class TaskTemplatedCtrl {
  constructor(
    toaster,
    contactService,
    contactModelService,
    dashboardService,
    taskService,
    generalSearchService,
    currentUserService,
    clientInfoService,
    taskDetailService
  ) {
    'ngInject';

    this.toaster = toaster;
    this.contactService = contactService;
    this.contactModelService = contactModelService;
    this.dashboardService = dashboardService;
    this.taskService = taskService;
    this.generalSearchService = generalSearchService;
    this.currentUserService = currentUserService;
    this.clientInfoService = clientInfoService;
    this.taskDetailService = taskDetailService;
    this.toastError = toastError;
  }

  $onInit() {
    this.taskSet = {};
    this.mainObject = {};
    this.mainObject.isSearchEnable = false;
    this.isLoading = false;
    this.format = 'dd MMM yyyy';
    this.dateOptions = {
      formatYear: 'yy',
      showWeeks: false,
    };
    this.template_task_id = 0;
    this.copiedTemplatedTaskList = [];
    this.selectedTemplateId = 0;
    this.taskInput = {
      familyValue: '',
    };
    this.templateTask = {};
    this.getAllData();
  }

  openDate(templateTask) {
    this.temp = {};
    templateTask.datePopUp = !templateTask.datePopUp;
  }

  dateChanged(templateTask) {
    this.temp = {};
    templateTask.DueDate = templateTask.DueDateFormat;
  }

  closeFamilyList() {
    this.familyDetailsList = this.taskDetailService.closeFamilyList(
      this.taskInput,
      this.mainObject
    );
  }

  selectFamily(id, contactName) {
    this.familyDetailsList = this.taskDetailService.selectFamily(
      id,
      contactName,
      this.taskInput,
      this.taskSet,
      this.mainObject
    );
  }

  editTemplateTask(task) {
    task.isEditMode = true;
    task.datePopUp = true;
    task.DueDateFormat = new Date(task.DueDate);
    if (!this.mainObject.editInfoShown) {
      this.toaster.pop(
        'info',
        'Info',
        'Changing the date will update subsequent tasks.'
      );
      this.mainObject.editInfoShown = true;
    }
  }

  cancelTemplateTaskEditMode(task) {
    this.copiedTemplatedTaskList.map(item => {
      if (item.TaskSettingsID === task.TaskSettingsID) {
        task.TaskName = item.TaskName;
        task.AssignedAdvisorID = item.AssignedAdvisorID;
        task.DueDate = item.DueDate;
        const adviserData = this.taskAdviserList.find(adviser => {
          return adviser.BrokerID === task.AssignedAdvisorID;
        });
        if (adviserData) {
          item.FirstName = adviserData.LastName;
          item.LastName = adviserData.LastName;
          task.FirstName = adviserData.FirstName;
          task.LastName = adviserData.LastName;
        }
      }
      return item;
    });
    task.isEditMode = false;
  }

  saveTemplateTask(task) {
    this.copiedTemplatedTaskList.map(item => {
      if (item.TaskSettingsID === task.TaskSettingsID) {
        item.TaskName = task.TaskName;
        item.AssignedAdvisorID = task.AssignedAdvisorID;
        item.DueDate = task.DueDate;
        const adviserData = this.taskAdviserList.find(adviser => {
          return adviser.BrokerID === task.AssignedAdvisorID;
        });
        if (adviserData) {
          item.FirstName = adviserData.FirstName;
          item.LastName = adviserData.LastName;
          task.FirstName = adviserData.FirstName;
          task.LastName = adviserData.LastName;
        }
      }
      return item;
    });
    task.isEditMode = false;
    this.formatTaskSettingsDueDate(task.TaskTemplateID, task.TaskSettingsID);
  }

  getTemplateTask(templateId) {
    this.selectedTemplateId = templateId;
    this.taskService.getTaskTemplateSettings(templateId).then(respond => {
      this.templatedTaskList = respond.data;
      this.formatTaskSettingsDueDate(templateId);
    });
  }

  formatTaskSettingsDueDate(templateId, taskId = 0) {
    const templateModel = {
      TaskTemplateID: templateId,
      TaskTemplateName: '',
      TaskSettingsList: this.templatedTaskList,
    };

    this.taskService
      .updateDueDateSubsequently(templateModel, taskId)
      .then(newTaskSettings => {
        this.templatedTaskList = newTaskSettings.data;
        this.getBrokerBasicInfo();
      });
  }

  getBrokerBasicInfo() {
    this.dashboardService.getBrokerBasicInfo().then(response => {
      const { data } = response;
      if (!data) return;

      this.mainObject.CreatedByUser = data.BrokerId;

      this.templatedTaskList.forEach(item => {
        item.AssignedAdvisorID = item.AssignedAdvisorID.toString();
        if (item.AssignedAdvisorID === '0') {
          item.AssignedAdvisorID = data.BrokerId;
          item.FirstName = data.FirstName;
          item.AssignedAdvisorID = data.BrokerId;
        }
      });
      this.copiedTemplatedTaskList = [...this.templatedTaskList];
    });
  }

  deleteTemplateTask(task) {
    const index = this.templatedTaskList.indexOf(task);
    if (index > -1) this.templatedTaskList.splice(index, 1);
  }

  filterFamily(query) {
    const result = this.familyList.filter(el => {
      return el.FamilyFullName.toLowerCase().indexOf(query.toLowerCase()) > -1;
    });

    if (!this.quoteId) {
      const involvedParties = [
        {
          FamilyId: 0,
          FamilyFullName: 'All Involved Parties',
          IsClient: true,
        },
      ];
      return [...result, ...involvedParties];
    }
    return [...result];
  }

  searchFamilyInput(searchString) {
    if (this.familyList) {
      this.mainObject.isSearchEnable = true;
      this.familyDetailsList = this.filterFamily(searchString);
      return;
    }

    if (searchString) {
      this.mainObject.isSearchEnable = true;
      this.dashboardService
        .getGenralSearchResult(searchString)
        .then(response => {
          if (!response) return;
          this.getClientResult(response, searchString);
        });
    }
  }

  getClientResult(searchResult, searchString) {
    this.generalSearchService
      .getClientResult(searchResult, searchString)
      .then(res => {
        if (!res || !res.allResults) return;
        this.familyDetailsList = this.generalSearchService.processClientSearch(
          res.allResults
        );
      });
  }

  getAllData() {
    if (this.template_task_id) {
      this.taskService.taskDetailsGet(this.template_task_id).then(respond => {
        this.taskSet = respond.data;
        this.taskSet.DueDate = new Date(this.taskSet.DueDate);
      });
    } else {
      this.taskSet.DueDate = new Date();
    }

    this.taskService.taskTemplateListGet().then(respond => {
      this.taskTemplateList = respond.data;
    });

    if (this.familyId && !this.loanId && !this.quoteId) {
      this.contactService.clientInformGet(this.familyId).then(response => {
        if (!response || !response.data) return;
        const data = response.data;
        this.taskInput.familyValue = this.clientInfoService.getFullName(data);
        this.taskSet.FamilyId = this.familyId;
      });
    }

    this.isCorporateUser =
      this.currentUserService.accessType === ACCESS_TYPE.CORPORATE;
    const isLoanWriter =
      this.isCorporateUser && this.currentUserService.isNZ ? 0 : null;

    this.contactModelService.taskAdviserListGet(isLoanWriter).then(respond => {
      this.taskAdviserList = respond.data;
      this.taskAdviserList = reject(respond.data, ['BrokerID', 0]);
      respond.data.forEach(item => {
        item.BrokerID = item.BrokerID.toString();
      });
    });

    this.contactService.getAllByFilter('*').then(respond => {
      respond.data.FamilyList.forEach(value => {
        if (value.FamilyID === this.familyId) {
          this.taskInput.familyValue = value.FamilyFullName;
          this.taskSet.FamilyId = !this.template_task_id
            ? value.FamilyID
            : this.taskSet.FamilyId;
        }
      });
    });

    if (this.quoteId) {
      const family = this.familyList && this.familyList[0];
      family && this.selectFamily(family.FamilyId, family.FamilyFullName);
    }
  }

  saveTemplatedTask() {
    const postDataList = [];
    this.templatedTaskList.forEach(item => {
      const taskData = {
        FamilyID: this.taskSet.FamilyId,
        TaskID: 0,
        Title: item.TaskName,
        assignedClientID: item.AssignedAdvisorID,
        CreatedByUser: this.mainObject.CreatedByUser,
        DueDate: moment(item.DueDate).format('YYYY-MM-DD'),
        Notes: '',
        TaskSettingsID: item.TaskSettingsID,
        LoanId: this.loanId,
        QuoteID: this.quoteId,
      };
      postDataList.push(taskData);
    });
    const postData = {
      TaskList: postDataList,
      TaskTemplateID: this.selectedTemplateId,
    };

    this.isLoading = true;
    const successHandler = () => {
      this.isLoading = false;
      if (this.template_task_id) {
        this.toaster.pop(
          'success',
          'Updated',
          'Templated task successfully updated'
        );
      } else {
        this.toaster.pop(
          'success',
          'Added',
          'Templated task successfully added'
        );
      }
      this.modalInstance.close('saved');
    };

    this.taskService
      .TaskSetv3Multiple(postData)
      .then(successHandler, toastError);
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}

export default TaskTemplatedCtrl;

import template from './assetFinanceQuickProductFinder.html';
import controller from './assetFinanceQuickProductFinderCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    loanFacility: '<',
    ifinanceFilters: '<',
  },
};

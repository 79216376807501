import { PACKAGE_STATUS, DOCUMENT } from 'Common/constants/eSign';
import swal from 'sweetalert';
import { COLOR } from 'Common/constants/colors';
import { objectLength } from 'Common/utilities/objectValidation';

class ESignSupportingDocuments {
  constructor($timeout, eSignService, downloadDataService) {
    'ngInject';

    this.$timeout = $timeout;
    this.eSignService = eSignService;
    this.packageStatus = PACKAGE_STATUS;
    this.downloadDataService = downloadDataService;
  }

  $onChanges(changes) {
    if (!changes.packageSent || !changes.packageSent.currentValue) return;
    this.waitCallBack = this.$timeout(() => {
      this.getPackages();
    }, 2000);
  }
  $onInit() {
    this.packages = [];
    this.getPackages();
    this.showLoading = true;
  }

  $onDestroy() {
    this.waitCallBack && this.$timeout.cancel(this.waitCallBack);
  }

  getPackages() {
    if (!this.loanId) return;
    this.showLoading = true;
    this.eSignService
      .getPackages(this.loanId)
      .then(response => {
        if (!response || !response.length) return;
        this.packages = response;
      })
      .finally(() => {
        this.showLoading = false;
      });
  }
  reSendPackage(packageId) {
    if (!this.loanId || !packageId) return;
    swal(
      {
        title: 'Please confirm action',
        text: 'Are you sure you want to resend this package?',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: COLOR.SCOOTER,
        confirmButtonText: 'Resend',
        closeOnConfirm: false,
      },
      isConfirm => {
        if (isConfirm) {
          this.eSignService
            .getNotifySigners(packageId, this.loanId)
            .then(response => {
              if (!response || !response.data) return;
              swal('Success', 'Package successfully resent.', 'success');
            });
        }
      }
    );
  }

  checkIfCancelAvailable(eSignPackage) {
    if (!eSignPackage || !objectLength(eSignPackage)) return;
    const { status, documents, packageId } = eSignPackage;
    const checkSignedDocument = documents.filter(
      eSignDocument => eSignDocument.status === DOCUMENT.SIGNED
    );
    const isCancellable =
      status !== PACKAGE_STATUS.PACKAGE_COMPLETE &&
      status !== PACKAGE_STATUS.PACKAGE_TRASHED &&
      (!checkSignedDocument || !checkSignedDocument.length);
    if (isCancellable) {
      this.cancelPackage(packageId);
      return;
    }
    swal({
      title: 'Unable to cancel/delete signed packages',
      text:
        'At least one document in this package has been signed by the client, and therefore, can not be cancelled or deleted.  Possible options include; detach the document from the loan application, or, generate a new document to be sent for signing by the client.',
      type: 'info',
      confirmButtonText: 'Ok',
      closeOnConfirm: true,
    });
  }

  downloadDocument(documentId) {
    if (!documentId) return;
    this.downloadDataService.documentDownload(documentId);
  }

  showPackage(esignPackage) {
    return (
      !this.showLoading &&
      esignPackage &&
      esignPackage.documents &&
      esignPackage.documents.length
    );
  }

  detachDocument(packageId, documentId) {
    if (!packageId || !documentId) return;
    swal(
      {
        title: 'Detach Document',
        text: 'If this is the desired action, please confirm to proceed.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: COLOR.GERALDINE,
        confirmButtonText: 'Detach',
        closeOnConfirm: true,
      },
      isConfirm => {
        if (isConfirm) {
          this.eSignService
            .postDetachDocument(packageId, documentId)
            .then(response => {
              if (!response || !response.data) return;
              swal('Success', 'Document successfully successfully.', 'success');
              this.showLoading = true;
              this.waitCallBack = this.$timeout(() => {
                this.getPackages();
              }, 2000);
            });
        }
      }
    );
  }

  cancelPackage(packageId) {
    if (!packageId) return;
    swal(
      {
        title: 'Cancel Signature Request',
        text:
          'By cancelling this request, client(s) will no longer be able to view or sign the document(s).  The generated PDF(s) will be removed from the MyCRM and E-Sign platforms and will need to be regenerated.  If this is the desired action, please confirm to proceed.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: COLOR.GERALDINE,
        confirmButtonText: 'Cancel & Delete',
        closeOnConfirm: true,
      },
      isConfirm => {
        if (isConfirm) {
          this.eSignService.deletePackage(packageId).then(response => {
            if (!response || !response.data) return;
            swal('Success', 'Package successfully cancelled.', 'success');
            this.showLoading = true;
            this.waitCallBack = this.$timeout(() => {
              this.getPackages();
            }, 2000);
          });
        }
      }
    );
  }
}
export default ESignSupportingDocuments;

import angular from 'angular';

angular
  .module('app')
  .controller(
    'AdditionalPdfPreviewModalCtrl',
    function AdditionalPdfPreviewModalCtrl(
      $scope,
      $window,
      options,
      labels,
      colors,
      graphData,
      loanAmount,
      years,
      interestRate,
      frequency,
      totalMonthlyRepayment,
      resultPanelFreqData,
      resultPanelAdditionalRepayment,
      interestSaved,
      timeSaved,
      customThemeService,
      userService,
      series,
      loanCalculatorSharedData
    ) {
      $scope.data = graphData;
      $scope.options = options;
      $scope.labels = labels;
      $scope.colors = colors;
      $scope.series = series;
      $scope.loanAmount = loanAmount;
      $scope.years = years;
      $scope.interestRate = interestRate;
      $scope.frequency = frequency;
      $scope.totalMonthlyRepayment = totalMonthlyRepayment;
      $scope.resultPanelFreqData = resultPanelFreqData;
      $scope.resultPanelAdditionalRepayment = resultPanelAdditionalRepayment;
      $scope.interestSaved = interestSaved;
      $scope.timeSaved = timeSaved;
      $scope.brandingLogo = customThemeService.branding;
      $scope.getUserInfo = loanCalculatorSharedData.getUserInfo;
      $scope.getUserInfo();
      $scope.date = new Date();
    }
  );

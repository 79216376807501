import template from './lmPdfHeader.html';
import './style.scss';

export default {
  template,
  bindings: {
    pdfTitle: '@',
    loanAmount: '@',
    adviserName: '@',
    loanTerms: '@',
    interestRate: '@',
    frequency: '@',
    date: '@',
    isHideDetails: '<',
  },
};

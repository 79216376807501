import { INSURANCE_TYPES } from 'Common/constants/insuranceOptions';

export default class InsuranceReportService {
  constructor(insuranceQuoteService, insuranceProfilerService) {
    'ngInject';

    this.insuranceQuoteService = insuranceQuoteService;
    this.insuranceProfilerService = insuranceProfilerService;
  }

  sendReportViaEmail(type, reportEmail) {
    switch (type) {
      case INSURANCE_TYPES.QUICK_QUOTE:
        return this.insuranceQuoteService.sendQuoteReportViaEmail(reportEmail);
      case INSURANCE_TYPES.PROFILER:
        return this.insuranceProfilerService.profilerSendEmailReport(
          reportEmail
        );
      default:
        return null;
    }
  }
}

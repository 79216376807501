import { personToSetContactModel } from 'Common/utilities/insurance';

class ProfilerToContactCtrl {
  constructor(
    $timeout,
    $window,
    $state,
    $uibModalInstance,
    $uibModal,
    insuranceProfilerService,
    contactService,
    toaster,
    profilerData
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$window = $window;
    this.$state = $state;
    this.$uibModalInstance = $uibModalInstance;
    this.$uibModal = $uibModal;
    this.insuranceProfilerService = insuranceProfilerService;
    this.contactService = contactService;
    this.toaster = toaster;
    this.profilerData = profilerData;

    this.searchContactText = '';
    this.selectedFamily = {};
    this.getFamilyList = [];
    this.searchContactText = '';
    this.selectedFamily = {};
    this.showContactList = false;
  }
  // 3. Angular Functions
  cancel() {
    this.$uibModalInstance.dismiss('cancel');
  }

  close() {
    this.$uibModalInstance.dismiss('close');
  }

  profilerAssignToClient(familyId, profilerId) {
    this.insuranceProfilerService
      .profilerAssignToClient(familyId, profilerId)
      .then(response => {
        if (response.status === 200) {
          this.toaster.pop(
            'success',
            'Success',
            'Profiler successfully added to contact.'
          );
        } else {
          this.toaster.pop(
            'error',
            'Error',
            'Profiler failed to add in contact'
          );
        }

        this.close();
        this.$state.go('app.insuranceProfilerDetails', {
          insProfilerId: profilerId,
          familyId,
        });
      });
  }

  profilerSaveNewContacts() {
    if (!this.profilerData.familyId) {
      const familyMember = this.profilerData.familyInfo.map(member =>
        personToSetContactModel(member)
      );
      this.contactService.addContact(familyMember).then(response => {
        if (response.data) {
          this.profilerData.familyId = response.data;
          this.profilerAssignToClient(
            response.data,
            this.profilerData.profilerId
          );
        }
      });
    } else {
      this.profilerAssignToClient(
        this.profilerData.familyId,
        this.profilerData.profilerId
      );
    }
  }

  $onInit() {
    const familyNotEmpty =
      this.profilerData.familyInfo && this.profilerData.familyInfo[0];
    if (familyNotEmpty) {
      this.profilerData.familyFullName = `${
        this.profilerData.familyInfo[0].FirstName
      } ${this.profilerData.familyInfo[0].LastName}`;
    }
  }
}

export default ProfilerToContactCtrl;

import swal from 'sweetalert';
import { forEach } from 'lodash';
import { objectLength } from 'Common/utilities/objectValidation';
import { PACKAGE_STATUS } from 'Common/constants/eSign';
import { isValidEmailAddress } from 'Common/utilities/string';

const SEND = {
  CQP: 1,
  CPA: 2,
  BOTH: 3,
};

class SendReportsToEsign {
  constructor(
    $uibModal,
    eSignService,
    loanScenarioService,
    crmConfirmation,
    configService,
    loanAppSharedData
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.eSignService = eSignService;
    this.loanScenarioService = loanScenarioService;
    this.crmConfirmation = crmConfirmation;
    this.configService = configService;
    this.loanAppSharedData = loanAppSharedData;
  }

  $onInit() {
    this.applicants = [];
    this.guarantors = [];
    this.invalidEmails = [];
    this.signeeList = [];
    this.sendButtonText = `Checking for existing package..`;
    this.isCPADownloadPdf = false;
    this.isCQPDownloadPdf = false;
    this.isCPAReviewed = false;
    this.isCQPReviewed = false;
    this.isWaitSubmitResponse = false;
    this.isCheckedTerms = false;
    this.isEsignEnabled();
    this.checkRequiredParameter();
  }

  isEsignEnabled() {
    const isEsignEnabled =
      (this.configService.feature && this.configService.feature.esign) || 0;
    if (isEsignEnabled) return;
    this.closeModal();
  }
  getInvolveParty() {
    if (!this.loanId) return;
    this.invalidEmails = [];
    this.duplicates = [];
    this.loanScenarioService
      .scenarioApplicantListGet(this.loanId)
      .then(response => {
        if (!response || !response.data || !response.data.InvolvedPartyPerson)
          return;
        const { InvolvedPartyPerson: involvedParty } = response.data;
        forEach(involvedParty, val => {
          val.forEach(data => {
            if (data && data.IsApplicant) {
              this.applicants.push(data);
              this.checkEmailValid(data);
            }
            if (data && data.IsGuarantor) {
              this.guarantors.push(data);
              this.checkEmailValid(data);
            }
          });
        });
        this.checkDuplicate();
      });
  }

  checkDuplicate() {
    if (!this.signeeList || !this.signeeList.length) return;
    this.duplicates = this.signeeList.reduce((accum, signee, index, array) => {
      if (array.filter(val => val.email === signee.email).length > 1)
        return accum.concat(signee);
      return accum;
    }, []);
  }

  checkEmailValid(signee) {
    const checkEmailValid =
      signee &&
      signee.Email &&
      signee.Email.length &&
      isValidEmailAddress(signee.Email[0].EmailAddress);
    if (checkEmailValid) {
      const person = {
        name: `${signee.FirstName} ${signee.LastName}`,
        email: signee.Email[0].EmailAddress,
      };
      this.signeeList.push(person);
      return;
    }
    this.invalidEmails.push(signee);
  }

  getRecentPackage() {
    if (!this.loanId) return;
    this.eSignService.getRecentPackage(this.loanId).then(response => {
      if (!response || !response.length) {
        this.sendButtonText = `Send Via E-Sign`;
        return;
      }
      const { status } = response[0];
      this.sendButtonText =
        status !== PACKAGE_STATUS.PACKAGE_COMPLETE &&
        status !== PACKAGE_STATUS.PACKAGE_TRASHED
          ? `Update package`
          : `Send Via E-Sign`;
    });
  }

  checkRequiredParameter() {
    const isValidToViewContent =
      this.loanId && (this.isSendCqp || this.isSendCpa);
    if (isValidToViewContent) {
      this.getInvolveParty();
      this.getRecentPackage();
      return;
    }
    this.closeModal();
    swal({
      title: 'Not allowed!',
      text: 'Please contact support',
      type: 'error',
      showCancelButton: false,
    });
  }

  reviewedCpa() {
    this.isCPAReviewed = true;
  }

  reviewedCqp() {
    this.isCQPReviewed = true;
  }

  removeCpa() {
    if (!this.isSendCpa) return;
    this.isSendCpa = false;
  }

  removeCqp() {
    if (!this.isSendCqp) return;
    this.isSendCqp = false;
  }

  disabledSendToEsign() {
    return (
      (!this.isSendCqp && !this.isSendCpa) ||
      this.isWaitSubmitResponse ||
      (this.invalidEmails && this.invalidEmails.length) ||
      (this.duplicates && this.duplicates.length) ||
      !this.isCheckedTerms ||
      (this.isSendCqp && !this.isCQPReviewed) ||
      (this.isSendCpa && !this.isCPAReviewed)
    );
  }

  sendToEsign() {
    if (this.disabledSendToEsign()) return;
    this.isWaitSubmitResponse = true;
    this.sendButtonText = `Sending ...`;
    this.type = this.isSendCqp && this.isSendCpa ? SEND.BOTH : 0;
    this.type = this.isSendCqp && !this.isSendCpa ? SEND.CQP : this.type;
    this.type = !this.isSendCqp && this.isSendCpa ? SEND.CPA : this.type;
    this.eSignService.getSignDocument(this.loanId, this.type).then(response => {
      this.isWaitSubmitResponse = false;
      if (!response || !objectLength(response)) return;
      this.closeAllModals();
      this.crmConfirmation.open({
        type: 'success',
        title: 'Documents Sent',
        description:
          'The documents you have requested to be signed by your client(s) have now been sent to eSign to manage.  Your client(s) will soon receive an email inviting them to provide a signature on the relevant documents.  You will be updated via email when this has been completed.',
        buttonText: 'Okay got it!',
        modalSize: 'md',
      });
    });
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }
  closeAllModals() {
    this.modalInstance.close({ closeAll: true });
  }
}
export default SendReportsToEsign;

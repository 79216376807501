export function displayMap(editMode, obj) {
  return (
    obj &&
    !editMode &&
    obj.formatted_address &&
    obj.latitude &&
    obj.longitude &&
    !obj.isCustomAddress
  );
}
export function displayCustomAddress(editMode, obj) {
  return (
    obj &&
    !editMode &&
    obj.formatted_address &&
    ((!obj.latitude && !obj.longitude) || obj.isCustomAddress)
  );
}

export function getGoogleMapInstance() {
  if (!window.google || !window.google.maps) return false; // eslint-disable-line
  return window.google.maps; // eslint-disable-line
}

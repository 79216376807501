import angular from 'angular';
import _ from 'lodash';

angular
  .module('app')
  .controller(
    'NewInsuranceApplicationCtrl',
    function newInsuranceNewInsuranceApplicationCtrlpplicationCtrl(
      $rootScope,
      $scope,
      $timeout,
      $interval,
      $uibModal,
      $uibModalInstance,
      $compile,
      contactService,
      loanScenarioService,
      toaster,
      $state,
      optionsService,
      userService,
      corporateService,
      generalService,
      dashboardService,
      clientInfoService
    ) {
      $scope.allocatedAdviser = {};
      $scope.allocatedAdviser.adviserSelected = {};
      $scope.allocatedAdviser.adviserSelected.FullName = {};
      $scope.allocatedAdviser.adviserSelected.FullName.description = {};
      $scope.isDisableAllocateAdviser = false;
      $scope.allocatedAdviser.adviserInitialValue = '';
      $scope.selectedAdviserToEdit = {};
      $scope.submitClicked = false;
      $scope.selectedFamily = undefined;
      if (typeof $scope.brokersInfo === 'undefined') {
        userService.GetUserInfo().then(response => {
          const access = response.data.AccessType;
          dashboardService.getBrokerBasicInfo().then(res => {
            $scope.brokersInfo = res.data;
            if (parseInt(access, 10) === 1 || parseInt(access, 10) === 2)
              $scope.AssignedAdviserId = $scope.brokersInfo.BrokerId;
            $scope.getAccountType();
          });
        });
      }
      angular.extend($scope, {
        getContactAdviser(contactFamilyId) {
          contactService
            .getContactAssignedAdviser(contactFamilyId)
            .then(response => {
              $scope.AssignedAdviserId = response.data;
              return $scope.AssignedAdviserId;
            });
        },
        getAdvisersList() {
          generalService.getBrokerAll().then(response => {
            $scope.advisersList = response.data;
          });
        },
        sampleChange(id) {
          $scope.AssignedAdviserId = id;
        },
        getAllBroker() {
          generalService.getBrokerAll().then(response => {
            $scope.brokersList = response.data;
          });
        },
        getAccountType() {
          clientInfoService.getAccounTypeNumber().then(res => {
            $scope.accessType = res.data;
            if ($scope.accessType) {
              if (parseInt($scope.accessType, 10) === 2) {
                // adviser
                $scope.getAdvisersList($scope.brokerInfo.BrokerId);
              } else if (parseInt($scope.accessType, 10) === 1) {
                $scope.getAllBroker();
                $scope.getUserInfo();
              } else {
                // admin
                $scope.getUserInfo();
              }
            }
          });
        },
        getUserInfo() {
          contactService.getUserInfo().then(response => {
            $scope.userInfo = response.data;
            $scope.currentUserName = response.data.FullName;
            $scope.currentCountryId = response.data.CountryId || 0;
            if (
              parseInt($scope.accessType, 10) !== 2 &&
              parseInt($scope.accessType, 10) !== 1
            ) {
              $scope.getAdvisersDropdown();
            }
          });
        },
        getAdvisersDropdown() {
          corporateService
            .getAdvisersDropdown($scope.userInfo.FamilyId, 0, 0)
            .then(getAdvisersDropdownResponse => {
              if (parseInt($scope.accessType, 10) === 5) {
                $scope.allocatedAdviserList = _.filter(
                  getAdvisersDropdownResponse.data,
                  data => {
                    return data !== '';
                  }
                );
              } else {
                $scope.assistantAdvisersList = _.filter(
                  getAdvisersDropdownResponse.data,
                  data => {
                    return data.FullName !== '';
                  }
                );
                $scope.AssignedAdviserId =
                  $scope.assistantAdvisersList[0].FamilyId;
                $scope.sampleChange($scope.AssignedAdviserId);
              }
            });
        },
        getAdvisersName(adviserList) {
          $scope.allocatedAdviserListName = adviserList.FullName;
        },
      });
      $scope.dismissModal = function() {
        $uibModalInstance.dismiss();
      };
      $scope.searchContact = function() {
        $scope.selectedFamily = undefined;
        contactService.searchFamily($scope.searchContactText).then(response => {
          $scope.getFamilyList = response.data.FamilyList;
        });
      };
      $scope.selectContact = function(obj) {
        $scope.searchContactText = obj.FamilyFullName;
        $scope.selectedFamily = obj;
        $scope.showContactList = false;
      };
      $scope.existingClientSet = function() {
        if (!_.isEmpty($scope.selectedFamily.FamilyID)) {
          $state.go('app.insApp', {
            insAppId: 5,
            familyId: $scope.selectedFamily.FamilyID,
          });
          $scope.dismissModal();
        }
      };
      $scope.getNewContactModel = function(role) {
        let country;
        switch ($rootScope.crmCountry) {
          case 'Australia':
            country = 'AU';
            break;
          case 'New Zealand':
            country = 'NZ';
            break;
          default:
            country = '-1';
        }
        return {
          FamilyId: '0',
          LastName: '',
          FirstName: '',
          MiddleName: '',
          PreferredName: '',
          Title: '',
          Gender: '',
          DOB: '',
          Role: role,
          Employment: [
            {
              Occupation: '',
              OccupationClass: 0,
              Employer: '',
            },
          ],
          SmokerStatus: '',
          Deceased: false,
          DOBReminder: false,
          BestTimeToCall: '',
          IsResident: true,
          Phone: [
            {
              Type: 'Mobile',
              Number: '',
            },
          ],
          Email: [
            {
              Type: 'Email',
              EmailAddress: '',
            },
          ],
          Notes: '',
          CountryCode: country,
          CountryOfBirth: country,
          ResidencyId: '',
          MaritalStatusId: '',
          PreferredContactMethodId: '',
          customerConsentOptions: { ConsentMarketing: true },
          isIncluded: true,
          IsCustomerOfLender: false,
          IsEmployeeOfLender: false,
        };
      };
      optionsService.MaritalStatusGet().then(
        response => {
          $scope.maritalStatus = response.data;
        },
        () => {
          $scope.maritalStatus = [];
        }
      );
      optionsService.ResidencyStatusGet().then(
        response => {
          $scope.residencyStatus = response.data;
        },
        () => {
          $scope.residencyStatus = [];
        }
      );
      optionsService.countryListGet().then(
        response => {
          $scope.countryList = response.data;
        },
        () => {
          $scope.countryList = [];
        }
      );
      optionsService.PreferredContactMethodGet().then(
        response => {
          $scope.preferredContactMethod = response.data;
        },
        () => {
          $scope.preferredContactMethod = [];
        }
      );
      // Occupation list
      $scope.occList = [
        { Name: 'Class 1', id: 1 },
        { Name: 'Class 2', id: 2 },
        { Name: 'Class 3', id: 3 },
        { Name: 'Class 4', id: 4 },
        { Name: 'Class 5', id: 5 },
      ];
      // Add Person Modal
      $scope.personModal = function(type = 1, person) {
        // for applicants as we don't support any other types here
        $uibModal.open({
          templateUrl:
            '/assets/views/insuranceTools/insApp/modal/insurancePersonAdd.html',
          windowClass: 'new-insurance-Application-modal-window',
          controller: 'InsurancePersonAddCtrl',
          scope: $scope,
          size: 'lg',
          resolve: {
            selectedPartyType() {
              return type.toString();
            },
            person() {
              return person;
            },
          },
          backdrop: 'static',
          keyboard: false,
        });
      };
      $timeout(() => {
        const btn = angular.element('#es');
        const cancel = angular.element('#cancel');
        const par = angular.element(
          '.interactive-form'
        ) /* btn.parentNode.parentNode */;
        function generate(elem) {
          return elem.toggleClass('active');
        }
        btn.on('click', () => {
          generate(par);
        });
        cancel.on('click', () => {
          generate(par);
        });
      }, 0);
    }
  );

import {
  insurancePipelineTableForUI,
  insurancePipelineCardForUI,
  insurancePipelineLabelForUI,
  insurancePipelineCardBenefitForUI,
  insurancePipelineFilterForUI,
  insurancePipelineStatusSortingForUI,
  insurancePipelineFiltersForMyCRM,
  insurancePipelineStatusSortingForMyCRM,
  insuranceAppCoverDetailsForUI,
  insuranceBenefitCoverDetailsForUI,
} from 'Common/mappers/insurancePipeline';
import { INSURANCE_PIPELINE_STATUS } from 'Common/constants/insuranceOptions';

export function formatPolicyNumbersCSV(policyNumbersCSV = '') {
  return (
    policyNumbersCSV &&
    policyNumbersCSV.split(',').reduce((accum, policyNumber, index, splits) => {
      const isLast = index === splits.length - 1;
      const separator = isLast ? '' : ', ';
      return `${accum}#${policyNumber.trim()}${separator}`;
    }, '')
  );
}

export function mapInsurancePipelineTable(pipelineTable) {
  if (!pipelineTable) return;
  const mappedPipelineTable = insurancePipelineTableForUI(pipelineTable);
  mappedPipelineTable.items =
    pipelineTable.Items &&
    pipelineTable.Items.map(item => {
      const mappedItem = insurancePipelineCardForUI(item);
      mappedItem.policyNumber = formatPolicyNumbersCSV(mappedItem.policyNumber);
      mappedItem.labels =
        item.CardLabels && item.CardLabels.map(insurancePipelineLabelForUI);
      mappedItem.benefits =
        item.Benefits && item.Benefits.map(insurancePipelineCardBenefitForUI);
      return mappedItem;
    });
  return mappedPipelineTable;
}

export function mapInsurancePipelineFiltersForUI(filters) {
  if (!filters) return;
  const mappedPipelineFilter = insurancePipelineFilterForUI(filters);
  mappedPipelineFilter.statusSorting = filters.StatusSorting
    ? filters.StatusSorting.map(insurancePipelineStatusSortingForUI)
    : [];
  return mappedPipelineFilter;
}

export function mapInsurancePipelineFiltersForMyCRM(filters) {
  if (!filters) return;
  const mappedFilters = insurancePipelineFiltersForMyCRM(filters);
  mappedFilters.StatusSorting = filters.statusSorting
    ? filters.statusSorting.map(insurancePipelineStatusSortingForMyCRM)
    : [];
  return mappedFilters;
}

export function mapInsuranceAppCoverDetails(coverDetails) {
  if (!coverDetails) return { benefitDetails: [] };
  const mappedCoverDetails = insuranceAppCoverDetailsForUI(coverDetails);
  const { BenefitDetails: benefitDetails } = coverDetails;
  mappedCoverDetails.benefitDetails = benefitDetails
    ? benefitDetails.map(insuranceBenefitCoverDetailsForUI)
    : [];
  mappedCoverDetails.insurer.logo = `assets/images/insurers/bordered/${
    coverDetails.ProviderID
  }.png`;
  return mappedCoverDetails;
}

export function checkPipelineColumnHasMore(column, pageSize) {
  return column && column.totalCards - column.page * pageSize > 0;
}

export function isClientFilterValid(filter) {
  return (
    typeof filter !== 'undefined' &&
    filter !== null &&
    (filter.length >= 3 || filter.length === 0)
  );
}

export function isConversionStatus(status) {
  const { IN_FORCE, NOT_PROCEEDED } = INSURANCE_PIPELINE_STATUS;
  return status && (status.id === IN_FORCE || status.id === NOT_PROCEEDED);
}

export function getConciseConversionSummary(conversionSummaries) {
  if (!conversionSummaries) return {};
  const inForce = conversionSummaries.find(
    summary => summary.statusId === INSURANCE_PIPELINE_STATUS.IN_FORCE
  );
  const notProceeded = conversionSummaries.find(
    summary => summary.statusId === INSURANCE_PIPELINE_STATUS.NOT_PROCEEDED
  );
  return {
    inForcePercentage: inForce && inForce.conversionPercentage,
    notProceededPercentage: notProceeded && notProceeded.conversionPercentage,
  };
}

export function checkIsInsuranceAppProceeded(event) {
  return event && event.dest.sortableScope.element[0].id === 'approve';
}

export function getStatusIdConvertedTo(event) {
  const isProceeded = checkIsInsuranceAppProceeded(event);
  return isProceeded
    ? INSURANCE_PIPELINE_STATUS.IN_FORCE
    : INSURANCE_PIPELINE_STATUS.NOT_PROCEEDED;
}

export default class InsuranceModalApplicationService {
  constructor($uibModal) {
    'ngInject';

    this.createInsuranceAppModal = $uibModal;
  }

  onCreate(params) {
    return this.createInsuranceAppModal.open(this.modalOption(params));
  }

  modalTemplate() {
    this.componentTemplate = `<insurance-create-application
                                modal-instance="vm.modalInstance"
                                modal-data="vm.props.params">
                              </insurance-create-application>`;
    return this.componentTemplate;
  }

  modalPropertyData(param = {}) {
    const params = {
      title: 'Create New Insurance Application',
      buttonLabel: 'Next Step',
      searchPlaceHolder: 'Search Client Name',
      ...param,
    };
    this.props = { params };
    return this.props;
  }

  modalOption(params) {
    this.modalOptions = {
      animation: true,
      template: this.modalTemplate(),
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      resolve: {
        props: () => this.modalPropertyData(params),
      },
    };
    return this.modalOptions;
  }

  openWorkbenchModal(quoteId, familyId) {
    return this.createInsuranceAppModal.open({
      animation: true,
      template: `
        <insurance-app-workbench-modal
          modal-instance="vm.modalInstance"
          quote-id="vm.props.quoteId"
          family-id="vm.props.familyId">
        </insurance-app-workbench-modal>`,
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'insurance-app-workbench',
      size: 'lg',
      resolve: {
        props: () => {
          return {
            quoteId,
            familyId,
          };
        },
      },
    });
  }
}

import angular from 'angular';
import axios from 'axios';
import { getToken } from 'Common/utilities/token';
import { setUserInfoData } from 'Common/utilities/user';
import { setConfigData } from 'Common/utilities/config';
import { setFeaturesData } from 'Common/utilities/features';
import packet from './packet';
import {
  APP_MEDIAQUERY,
  JS_REQUIRES,
  DEMO_VIDEO,
} from './config/config.constant';
import './config/config.calendar.js';
import './config/config.xeditable.js';
import configIdleWatcher from './config/config.idlewatcher';
import configRouter from './config/config.router';
import configTranslate from './config/config.translate';
import configLoadingBar from './config/config.loadingbar';
import configBreadcrumb from './config/config.breadcrumb';
import configStorage from './config/config.storage';
import configBootstrapCalendar from './config/config.bootstrapcalendar';

import main from './main';
import {
  runTheme,
  loanMarketValidation,
  runDebug,
  getUserInfo,
  getFeatures,
  handleAuthentication,
} from './bootstrap';

import '../../../sass/styles.scss';
import '../../../sass/plugins.scss';
import '../../../sass/themes/main.scss';
import common from '../../src/common';
import components from '../../src/components';
import scenes from '../../src/scenes';

/* eslint-disable angular/file-name */
angular
  .module('app', [packet, common, components, scenes])
  .constant(...APP_MEDIAQUERY)
  .constant(...JS_REQUIRES)
  .constant(...DEMO_VIDEO)
  .config(configIdleWatcher)
  .config(configRouter)
  .config(configTranslate)
  .config(configLoadingBar)
  .config(configBreadcrumb)
  .config(configStorage)
  .run(configBootstrapCalendar)
  .run(main);

/* eslint-disable angular/window-service, angular/document-service */
// TEMP
window.app = angular.module('app');

angular.element(() => {
  axios.get('/config.json').then(({ data }) => {
    runTheme();
    const isLoanMarket = loanMarketValidation();
    const login = `${window.location.origin}/login/login.html`;
    const token = getToken();
    Object.assign(data, {
      isLoanMarket,
      login,
      token,
    });
    setConfigData(data);
    runDebug(data);
    getUserInfo()
      .then(user => {
        setUserInfoData(user.data);
        return getFeatures();
      })
      .then(feature => {
        setFeaturesData(feature.data);
        handleAuthentication();
      })
      .catch(handleAuthentication);
  });
});

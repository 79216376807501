import angular from 'angular';
import _ from 'lodash';
import { MODAL_SECTION } from 'Common/constants/generateReports';
import { isCancelled } from 'Common/utilities/subscription';

angular
  .module('app')
  .controller('RecommendationModalCtrl', function RecommendationModalCtrl(
    $scope,
    $window,
    $uibModal,
    $uibModalInstance,
    fundingCalculatorService,
    toaster,
    SweetAlert,
    uiService,
    loanProfilerService,
    loanScenarioService,
    activeModalSection,
    eSignSubscription,
    configService,
    multiLoanCpaIds,
    loanAppSharedData,
    currentUserService,
    usersSubscriptionService,
    contactService
  ) {
    $scope.multiLoanCpaIds = multiLoanCpaIds;
    if ($scope.multiLoanCpaIds && $scope.multiLoanCpaIds.length) {
      $scope.loanAppId = $scope.multiLoanCpaIds[0];
    }
    $scope.choosenSection = 0;
    $scope.showCqpBody = false;
    $scope.showCpaBody = false;
    $scope.isSendCPA = false;
    $scope.isSendCQP = false;
    $scope.isEsignEnabled =
      (configService.feature && configService.feature.esign) || 0;
    $scope.modalSection = MODAL_SECTION;

    $scope.isSubscriptionActive =
      eSignSubscription &&
      (eSignSubscription.isActive || isCancelled(eSignSubscription));
    if (!activeModalSection) {
      $scope.cancel();
    } else {
      $scope.activeModalSection = activeModalSection;
    }
    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.openChoosenRecommendation = selectedSection => {
      if (!selectedSection) return;
      if (
        selectedSection === MODAL_SECTION.E_SIGN &&
        !$scope.isSubscriptionActive &&
        $scope.isEsignEnabled
      ) {
        const { AdviserFamilyId } =
          loanAppSharedData && loanAppSharedData.LoanApplicationDetails;
        const { familyId } = currentUserService;
        const props = {
          familyId: AdviserFamilyId,
          subscriptionData: eSignSubscription,
          orderedById: familyId,
        };
        usersSubscriptionService.subscribeToService(props).then(response => {
          if (!response) return;
          $scope.activeModalSection = selectedSection;
          $scope.getSubscription();
        });
        return;
      }
      $scope.activeModalSection = selectedSection;
    };

    $scope.getSubscription = () => {
      const { AdviserFamilyId: familyId } =
        loanAppSharedData && loanAppSharedData.LoanApplicationDetails;
      if (!familyId) return;
      contactService.subscriptionInfo(familyId).then(response => {
        if (!response || !response.length) return;
        loanAppSharedData.adviserSubscription = response;
      });
    };

    $scope.toggleShowCqpBody = () => {
      $scope.showCqpBody = !$scope.showCqpBody;
    };

    $scope.toggleShowCpaBody = () => {
      $scope.showCpaBody = !$scope.showCpaBody;
    };

    $scope.disableSubmitToEsign = () => {
      return !$scope.isSendCPA && !$scope.isSendCQP;
    };

    $scope.submitToESign = () => {
      if ($scope.disableSubmitToEsign() && !$scope.loanAppId) return;
      const props = {
        isSendCpa: $scope.isSendCPA,
        isSendCqp: $scope.isSendCQP,
        loanId: $scope.loanAppId,
      };
      const modalInstance = $uibModal.open({
        template: `<send-reports-to-esign
                  modal-instance="vm.modalInstance"
                  is-send-Cqp="vm.props.isSendCqp"
                  is-send-Cpa="vm.props.isSendCpa"
                  loan-id="vm.props.loanId"
                  >
                </send-reports-to-esign>`,
        size: 'md',
        backdrop: 'static',
        windowClass: 'send-reports-to-esign',
        controller: 'CommonModalPlaceholderCtrl',
        controllerAs: 'vm',
        resolve: {
          props,
        },
      });
      modalInstance.result.then(response => {
        if (!response || !response.closeAll) return;
        $uibModalInstance.close({ closeAll: true });
      });
    };

    $scope.updateIsSendCPA = isSendCPA => {
      $scope.isSendCPA = isSendCPA;
    };

    $scope.updateIsSendCQP = isSendCQP => {
      $scope.isSendCQP = isSendCQP;
    };

    const getCpaCqpTitle = () => {
      const { tradingName, userBrandingCategory } = uiService;
      const baseTitle = 'About Broker';
      const secondTitle =
        userBrandingCategory === 'Own Brand'
          ? tradingName
          : userBrandingCategory;
      const cpaCqpTitle = secondTitle
        ? `${baseTitle} / ${secondTitle}`
        : baseTitle;
      return cpaCqpTitle;
    };
    // models
    angular.extend($scope, {
      isGenerateClientPrelimiaryAssessment: true,
      isCPADownloadPdf: false,
      isCQPDownloadPdf: false,
      CQPFeesTotal: null,
      CPACQPSet: {
        CQP: {},
        CPA: {},
      },
      CQPDateformat: 'dd MMM yyyy',
      CQPAltInputFormats: ['d!/M!/yyyy', 'd!M!yyyy'],
      CQPDateOptions: {
        formatYear: 'yy',
        showWeeks: false,
      },
      CPACQPData: [
        {
          title: 'Cover Page',
          value: 'IsCoverPage',
        },
        {
          title: getCpaCqpTitle(),
          value: 'IsAboutBrokerLoanMarket',
          showChild: false,
          childData: [
            {
              placeholder: 'Content that is prepared goes here...',
              value: 'Content',
              type: 'textarea',
            },
          ],
        },
        {
          title: 'Table of Contents',
          value: 'IsTableOfContents',
        },
        {
          title: 'Executive Summary',
          value: 'IsExecutiveSummary',
        },
        {
          title: 'Situation / Preferences',
          value: 'IsSituationOrPreference',
        },
        {
          title: 'Applicant Summary',
          value: 'IsApplicantSummary',
        },
        {
          title: 'Financial Position',
          value: 'IsFinancialPosition',
        },
        {
          title: 'Securities',
          value: 'IsSecurity',
        },
        {
          title: 'Loan Options',
          value: 'IsLoanOption',
        },
        {
          title: 'Funding Position',
          value: 'IsFundingPosition',
        },

        {
          title: 'Product Comparison',
          value: 'IsProductComparison',
        },
        {
          title: 'Broker Declaration',
          value: 'IsBrokerDeclaration',
        },
        {
          title: 'Customer Acknowledgement',
          value: 'IsCustomerAcknowledgement',
        },
        {
          title: 'Document Checklist',
          value: 'IsDocumentChecklist',
        },
      ],
      selectedTab:
        $scope.multiLoanCpaIds && $scope.multiLoanCpaIds.length ? 'CPA' : 'CQP',
    });

    // methods
    if ($scope.crmCountry === 'New Zealand') {
      _.remove($scope.CPACQPData, object => {
        return (
          object.title === 'Customer Acknowledgement' ||
          object.title === 'Document Checklist'
        );
      });

      $scope.activeTab = 1;
    }
    if ($scope.multiLoanCpaIds && $scope.multiLoanCpaIds.length) {
      $scope.activeTab = 1;
    }
    angular.extend($scope, {
      CQPOpenDate(dateField) {
        $scope.CQPDatePopUp[dateField] = !$scope.CQPDatePopUp[dateField];
      },
      getCPADocument(callback) {
        const self = $scope;
        loanScenarioService
          .RecommendationReportStatusGet($scope.loanAppId)
          .then(
            response => {
              const CPACQPPageObj = response.data;

              if (
                CPACQPPageObj &&
                CPACQPPageObj.CPA &&
                CPACQPPageObj.CPA.Content &&
                typeof CPACQPPageObj.CPA.Content !== 'undefined'
              ) {
                CPACQPPageObj.CPA.Content = CPACQPPageObj.CPA.Content.replace(
                  /<br>/g,
                  '\n'
                );
              }
              CPACQPPageObj.LoanApplicationId = self.loanAppId;
              if (
                CPACQPPageObj &&
                CPACQPPageObj.CQP &&
                typeof CPACQPPageObj.CQP !== 'undefined'
              ) {
                const otherFeesPaidTo = CPACQPPageObj.CQP.OtherFeesPaidTo;
                CPACQPPageObj.CQP.OtherFeesPaidTo =
                  otherFeesPaidTo === '0' ? '' : otherFeesPaidTo;
                const referralPaidTo = CPACQPPageObj.CQP.ReferralFeePaidTo;
                CPACQPPageObj.CQP.ReferralFeePaidTo =
                  referralPaidTo === '0' ? '' : referralPaidTo;
              }
              angular.extend(self.CPACQPSet, CPACQPPageObj);
              self.CQPFeesTotal =
                parseInt(self.CPACQPSet.CQP.EstablishmentFee, 10) +
                parseInt(self.CPACQPSet.CQP.ValuationFee, 10);
              self.isSaveTemplateChecked = self.CPACQPSet.CPA.IsTemplated;
              if (typeof callback !== 'undefined') {
                callback();
              }
            },
            e => {
              toaster.pop('error', e.Message, e.ExceptionMessage);
            }
          );
      },
      updateCPACQP(reCalculate = true) {
        const { CPACQPSet, calculatorFundsSummary } = $scope;
        const checkTypeValid = CPACQPSet && calculatorFundsSummary;
        if (checkTypeValid) {
          if (reCalculate) {
            const TrailCommissionAmt =
              (parseFloat(CPACQPSet.CQP.TrailCommission) / 100) *
              calculatorFundsSummary.totalProposedLending;
            CPACQPSet.CQP.TrailCommission_amt = Number.isInteger(
              Math.floor(TrailCommissionAmt)
            )
              ? Math.floor(TrailCommissionAmt)
              : 0;
            const UpfrontCommissionAmt =
              (parseFloat(CPACQPSet.CQP.UpfrontCommission) / 100) *
              calculatorFundsSummary.totalProposedLending;
            CPACQPSet.CQP.UpfrontCommission_amt = Number.isInteger(
              Math.floor(UpfrontCommissionAmt)
            )
              ? Math.floor(UpfrontCommissionAmt)
              : 0;
          }

          const { isSaveTemplateChecked } = $scope;
          CPACQPSet.CPA.IsTemplated = isSaveTemplateChecked;
          const CPACQPSetCopy = { ...CPACQPSet };
          if (CPACQPSetCopy && CPACQPSetCopy.CPA && CPACQPSetCopy.CPA.Content) {
            CPACQPSetCopy.CPA.Content = CPACQPSetCopy.CPA.Content.replace(
              /\r\n|\r|\n/g,
              '<br>'
            );
          }
          loanScenarioService.RecommendationReportStatus(CPACQPSetCopy);
        }
      },
      updateCQPFeesTotal() {
        if (!$scope.CPACQPSet.CQP.EstablishmentFee)
          $scope.CPACQPSet.CQP.EstablishmentFee = 0;
        if (!$scope.CPACQPSet.CQP.ValuationFee)
          $scope.CPACQPSet.CQP.ValuationFee = 0;
        $scope.CQPFeesTotal =
          parseInt($scope.CPACQPSet.CQP.EstablishmentFee, 10) +
          parseInt($scope.CPACQPSet.CQP.ValuationFee, 10);
      },
      dataURItoBlob(dataURI, type) {
        const binary = $window.atob(dataURI.split(',')[1]);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        return new $window.Blob([new Uint8Array(array)], { type });
      },

      checkSaveTemplateStatus(isChecked) {
        const self = $scope;
        self.isSaveTemplateChecked = isChecked;
        $scope.updateCPACQP();
      },
      getFundsSummary(callback) {
        const errorCallback = e => {
          toaster.pop('error', e.Message, e.ExceptionMessage);
        };

        const loanApplicationId = $scope.loanAppId;
        loanScenarioService
          .getFundingCalculatorNewBrokerEventForScenario($scope.loanAppId)
          .then(response => {
            fundingCalculatorService
              .getFundingCalculatorFundsSummary(response.data)
              .then(fundingCalculatorFundsSummary => {
                const summary = fundingCalculatorFundsSummary;
                $scope.calculatorFundsSummary = summary;
                $scope.updateCPACQP();

                loanScenarioService
                  .loanDetailsGet(loanApplicationId)
                  .then(response3 => {
                    $scope.loanDetails = response3.data;
                    callback(
                      response3.data.LoanFacility,
                      summary,
                      loanApplicationId
                    );
                  }, errorCallback);
              }, errorCallback);
          }, errorCallback);
      },
      updateLVR(loanFacilities, fundsSummary, loanApplicationId) {
        if (
          typeof loanFacilities !== 'undefined' &&
          loanFacilities.length > 0 &&
          typeof fundsSummary !== 'undefined' &&
          typeof loanApplicationId !== 'undefined'
        ) {
          const loanFacility = loanFacilities.splice(0, 1)[0];
          loanProfilerService
            .updateServiceabilityFilteringProductsSettings(
              loanFacility.BrokerEventId,
              {
                GeneralSetting: {
                  lender: ['ALL'],
                  loanTerm: loanFacility.LoanTerm,
                  loanAmount: loanFacility.TotalLoanAmount,
                  security: fundsSummary.totalSecurityValue,
                  LVR: fundsSummary.lvr,
                  splitLoan: false,
                },
              }
            )
            .then(() => {
              $scope.updateLVR(loanFacilities, fundsSummary, loanApplicationId);
            });
        }
      },
      checkCQPPDFLink(CQPPDFLink) {
        if (typeof CQPPDFLink === 'undefined') {
          SweetAlert.swal({
            title: 'Oops!',
            text: 'PDF Commission Guideline is not available for this lender.',
            type: 'warning',
          });
        }
      },
      _init() {
        $scope.getCPADocument($scope.updateCPACQP);
        $scope.getFundsSummary($scope.updateLVR);
      },
    });
    // caller
    $scope._init();
  });

import { COLOR } from 'Common/constants/colors';
import swal from 'sweetalert';

class SendCreditGuideModalCtrl {
  constructor(contactModelService) {
    'ngInject';

    this.contactModelService = contactModelService;
  }

  $onInit() {
    this.isSending = false;
    this.validEmailLength = 0;

    this.email = {
      EmailSubject: `Credit Guide for ${this.contactDetails.clientName}`,
      EmailBody: `
        Hi ${this.getFirstName()}, <br><br>
        Please find the Loan Market credit guide and privacy statements attached. It describes everything you need to know about:<br>
        <ul>
          <li>Who we are</li>
          <li>The Lenders we work with</li>
          <li>Our obligations to you</li>
          <li>How we get paid</li>
          <li>What we do with any private information you provide to us</li>
          <li>How we keep your private information safe,</li>
          <li>Who to talk to if you’re unhappy </li>
        </ul>
        Feel free to ask me any questions you may have. <br><br>
        Regards
      `,
      FamilyID: this.contactDetails.familyID,
      RecipientList: this.getRecipientList(),
      loanAppId: 0,
    };

    const toolBarElements = [
      {
        items: ['BGColor'],
      },
      {
        items: ['JustifyLeft'],
      },
      {
        items: ['BulletedList', '-'],
      },
      {
        items: ['Link'],
      },
      {
        items: ['Image'],
      },
      {
        items: ['Table'],
      },
      {
        items: ['Paste', '-'],
      },
      {
        items: ['Smiley'],
      },
      {
        items: ['-'],
      },
      '/',
      {
        items: ['Format'],
      },
      {
        items: ['Font'],
      },
      {
        items: ['FontSize', '-'],
      },
      {
        items: ['Bold'],
      },
      {
        items: ['Italic'],
      },
      {
        items: ['Underline'],
      },
      {
        items: ['Subscript'],
      },
      {
        items: ['Superscript'],
      },
    ];
    this.options = {
      language: 'en',
      allowedContent: true,
      uiColor: COLOR.CK_EDITOR_BUTTONS,
      height: 300,
      resize_enabled: false,
      removePlugins: 'elementspath',
      toolbar: toolBarElements,
    };
  }

  getFirstName() {
    return this.contactDetails &&
      this.contactDetails.clients.length &&
      this.contactDetails.clients[0].FirstName
      ? this.contactDetails.clients[0].FirstName
      : '';
  }

  getRecipientList() {
    const recipients = [];
    this.contactDetails.clients.forEach(value => {
      const contact = {
        FamilyFullname: value.FullName,
        EmailAddress:
          value.Email && value.Email.length && value.Email[0].EmailAddress
            ? value.Email[0].EmailAddress
            : '',
      };
      recipients.push(contact);

      if (contact.EmailAddress) {
        this.validEmailLength++;
      }
    });
    return recipients;
  }

  sendEmail() {
    this.isSending = true;
    this.contactModelService
      .sendCreditGuide(this.email)
      .then(res => {
        if (!res || !res.succeeded) {
          const message =
            res.messages && res.messages.length
              ? res.messages[0]
              : 'Something went wrong. Please contact support.';
          swal(`Oops!`, message, 'error');
          return;
        }
        swal({
          title: 'Success!',
          text: 'Credit Guide email successfully sent.',
          type: 'success',
          timer: 2000,
          showConfirmButton: false,
        });
        this.closeModal();
      })
      .finally(() => {
        this.isSending = false;
      });
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }
}
export default SendCreditGuideModalCtrl;

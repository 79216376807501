import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';

angular
  .module('app')
  .controller('InsuranceAppCtrl', function InsuranceAppCtrl(
    $rootScope,
    $scope,
    $timeout,
    $interval,
    $compile,
    contactService,
    loanScenarioService,
    loanApplicationServices,
    toaster,
    $state,
    $stateParams,
    utilitiesService,
    insuranceAppService
  ) {
    // 1.A MODELS - Setters
    const insAppId = parseInt($stateParams.insAppId, 10);
    const familyId = parseInt($stateParams.familyId, 10);

    angular.extend($scope, {
      clientHeaderName: '',
      insAppId: insAppId !== 0 ? insAppId : 0,
      familyId: familyId !== 0 ? familyId : 0,
      mainFamily: [],
      validSections: [],
      adviserSelect: false,
      selectClient: false,
      adviserInitials: '',
      applicationInsurance: {},
    });

    /* 3. Functionalities */
    // 3.A - Setters
    function countValids(valid, section) {
      _.remove($scope.validSections, o => {
        return o.section === section;
      });
      if (valid !== true) {
        $scope.validSections.push({ section });
      }

      if ($scope.validSections.length < 1) {
        $rootScope.LoanAppFormWarning = false;
      }
    }

    function showNotesToggleInit() {
      $('.toggle-notes').toggleClass('is-active');
      const parent = $('.toggle-notes').parent('.level-3-section');
      const noteDiv = parent.find('.notes');
      const togNotes = parent.find('.toggle-notes');
      const hideNotes =
        '<img src="/assets/images/loan-app-main-icons/hide.svg" title="Hide broker notes"> &nbsp; Hide notes';
      const showNotes =
        '<img src="/assets/images/loan-app-main-icons/show.svg" title="Show broker notes"> &nbsp;Show notes';

      if ($scope.applicationInsurance.ShowAllNotes) {
        /*
                * Do this broker notes are being shown
                * */
        togNotes.html(hideNotes);
        /*
                Hide/Show note wrapper
                */
        noteDiv.addClass('collapsed');
        /*
                Hide/Show note wrapper
                */
        noteDiv.toggleClass('collapsed');
      } else {
        togNotes.html(showNotes);
        /*
                Hide/Show note wrapper
                */
        noteDiv.toggleClass('collapsed');
      }
    }

    function saveInsuranceApp(advId, showNotes, appId, messageObj) {
      insuranceAppService
        .applicationInsuranceSet(advId, showNotes, appId)
        .then(response => {
          const data = response.data;
          if ($scope.insAppId !== data) {
            $state.go('app.insApp', {
              insAppId: data,
              familyId: $scope.familyId,
            });
          } else {
            showNotesToggleInit();
            toaster.pop(messageObj.type, messageObj.title, messageObj.message);
          }
        });
    }

    function applicationInsuranceSetMethod(advId, showNotes, appId) {
      const messageObj = {
        type: 'success',
        title: 'Success',
        message: 'Insurance Application updated successfully!',
      };
      saveInsuranceApp(advId, showNotes, appId, messageObj);
    }

    // 3.B - Getters
    function getContactFamilyInfo(getFamilyId) {
      contactService.contactFamilyInfoGet(getFamilyId).then(response => {
        $scope.mainFamily = response.data;
        if ($scope.mainFamily.length) {
          $scope.applicationInsurance.AdviserID = $scope.mainFamily.BrokerID;
          const messageObj = {
            type: 'success',
            title: 'Success',
            message: 'New Insurance Application created successfully!',
          };
          saveInsuranceApp(
            $scope.applicationInsurance.AdviserID,
            false,
            $scope.insAppId,
            messageObj
          );
        }
      });
    }

    const getHeaderName = (name, firstName, lastName) => {
      const ifParamExist = name && firstName && lastName;
      if (ifParamExist) {
        const headerName = `${name} & ${firstName} ${lastName}`;
        return headerName;
      }
      return '';
    };

    function getClientInformation() {
      if ($scope.familyId !== 0) {
        contactService.clientInformGet($scope.familyId).then(response => {
          const clientNames = [];
          angular.forEach(response.data, (client, idx) => {
            if (client.Role.toLowerCase() === 'adult') {
              // will only display header name for adults
              const clientName = {};
              if (clientNames.length > 0) {
                let nameValue = {};
                _.forEach(clientNames, value => {
                  if (value.LastName === client.LastName) {
                    nameValue = value;
                  }
                });

                if (nameValue.LastName) {
                  $scope.clientHeaderName = getHeaderName(
                    nameValue.FirstName,
                    client.FirstName,
                    client.LastName
                  );
                  $scope.clientInitials = utilitiesService.filterInitial(
                    client.FirstName,
                    client.LastName
                  );
                } else {
                  $scope.clientHeaderName = getHeaderName(
                    $scope.clientHeaderName,
                    client.FirstName,
                    client.LastName
                  );
                  $scope.clientInitials = utilitiesService.filterInitial(
                    client.FirstName,
                    client.LastName
                  );
                }
              } else {
                clientName.idx = idx;
                clientName.FirstName = client.FirstName;
                clientName.LastName = client.LastName;
                clientNames.push(clientName);
                $scope.clientHeaderName += `${client.FirstName} ${
                  client.LastName
                }`;
                $scope.clientInitials = utilitiesService.filterInitial(
                  client.FirstName,
                  client.LastName
                );
              }
            }
          });
          $scope.clientInformation = response.data;
        });
      }
    }

    function insuranceAppGetMethod(appId) {
      insuranceAppService.insuranceAppGet(appId).then(response => {
        const data = response.data;
        $scope.applicationInsurance = data;

        // trap due to sentry error
        if (!$scope.applicationInsurance) {
          $scope.applicationInsurance = {};
        }

        $scope.adviserInitials = utilitiesService.filterInitialOneString(
          $scope.applicationInsurance.Adviser
        );

        showNotesToggleInit();
      });
    }

    const insuranceAdviserListGet = () => {
      insuranceAppService.insuranceAdviserListGet().then(response => {
        const data = response.data;
        if (data) {
          $scope.insuranceAdvisersList = data;
        } else {
          toaster.pop('error', 'Failed to load advisers list.');
        }
      });
    };

    // 1.B MODELS - Getters
    angular.extend($scope, {
      getClientInformation: () => {
        getClientInformation();
      },
    });

    // 2. METHODS
    angular.extend($scope, {
      countValids: (valid, section) => {
        countValids(valid, section);
      },
      selectAdviser: selectStatus => {
        $scope.adviserSelect = selectStatus === false;
      },
      newAdviserSelected: adviserId => {
        $scope.selectAdviser($scope.adviserSelect);
        applicationInsuranceSetMethod(
          adviserId,
          $scope.applicationInsurance.ShowAllNotes,
          $scope.insAppId
        );
      },
      toggleShowNotes() {
        const messageObj = {
          type: 'success',
          title: 'Success',
          message: 'Show all notes setting has been saved.',
        };
        saveInsuranceApp(
          $scope.applicationInsurance.AdviserID,
          $scope.applicationInsurance.ShowAllNotes,
          $scope.insAppId,
          messageObj
        );
      },
      togglesInit() {
        $timeout(() => {
          /**
           * Toggle for Loan App Sections
           * */
          /* Initialize */
          const heading = angular.element('.level-2-heading');
          const section = angular.element('.level-2-section');

          heading.on('click', e => {
            e.preventDefault();
            // var id = angular.element( this ).attr( 'id' );
            const parent = angular
              .element(e.currentTarget)
              .parent('.level-2-section');

            parent.toggleClass('is-active');
            // Collapse all section
            angular
              .element('.level-2-section:not(.is-collapsed)')
              .removeClass('is-active');
            section.addClass('is-collapsed');

            if (parent.hasClass('is-active')) {
              parent.toggleClass('is-collapsed');
            } else {
              // Expand this section
              parent.addClass('is-collapsed');
            }
          });

          /** * Toggle for Broker Notes for each section* */
          const trigger = angular.element('.toggle-notes');

          trigger.on('click', e => {
            e.preventDefault();
            angular.element(e.currentTarget).toggleClass('is-active');
            const parent = $(e.currentTarget).parent('.level-3-section');
            const noteDiv = parent.find('.notes');
            const togNotes = parent.find('.toggle-notes');
            const hideNotes =
              '<img src="/assets/images/loan-app-main-icons/hide.svg" title="Hide broker notes"> &nbsp; Hide notes';
            const showNotes =
              '<img src="/assets/images/loan-app-main-icons/show.svg" title="Show broker notes"> &nbsp;Show notes';

            if (angular.element(e.currentTarget).hasClass('is-active')) {
              togNotes.html(hideNotes);
            } else {
              togNotes.html(showNotes);
            }

            noteDiv.toggleClass('collapsed');
          });
        }, 1000);
      },
    });

    /**
     * TEMPORARY
     * DEMO
     * */
    $scope.loanAppOptions = {
      dateTypes: [{ name: 'Settlement Date' }, { name: 'Finance Date' }],
      dates: {
        settlementDate: {
          active: false,
          date: new Date(),
        },
        financeDate: {
          active: false,
          date: new Date(),
        },
      },
    };

    $scope.loanAppOptions.dateType = $scope.loanAppOptions.dateTypes[0].name;

    $scope.addDateLoanApp = () => {
      $scope.addDate = false;

      const dateTypes = $scope.loanAppOptions.dateTypes;
      const dateType = $scope.loanAppOptions.dateType;

      $scope.loanAppOptions.dateTypes = _.remove(dateTypes, type => {
        return type.name !== dateType;
      });

      switch (dateType) {
        case 'Settlement Date':
          $scope.loanAppOptions.dates.settlementDate.active = true;
          break;
        case 'Finance Date':
          $scope.loanAppOptions.dates.financeDate.active = true;
          break;
        default:
          break;
      }

      angular.element('.notify-date').hide();
    };

    $scope.openAddDate = () => {
      $scope.addDate = !$scope.addDate;
    };

    $scope.mobileTogglesInit = () => {
      const heading = $('.content-heading');

      heading.on('click', e => {
        e.preventDefault();

        const parent = angular
          .element(e.currentTarget)
          .parent('.level-3-section');

        const sectionDiv = parent.find('.level-3-content');
        parent.toggleClass('is-active');
        sectionDiv.toggleClass('collapsed');

        if (parent.hasClass('is-active')) {
          parent
            .find('.panel-well-dropdown-btn .fa')
            .removeClass('fa-caret-down')
            .addClass('fa-caret-up');
        } else {
          parent
            .find('.panel-well-dropdown-btn .fa')
            .addClass('fa-caret-down')
            .removeClass('fa-caret-up');
        }
      });
    };

    $scope.mobileInit = function() {
      angular.element('.top-category-nav').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        centerMode: false,
        focusOnSelect: true,
        fade: true,
        asNavFor: '.main-category-nav',
      });

      angular.element('.main-category-nav').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.top-category-nav',
        dots: false,
        arrows: false,
        centerMode: true,
        focusOnSelect: true,
      });

      $timeout(() => {
        angular.element('.mobile-wrapper .notes').removeClass('collapsed');
        angular
          .element('.mobile-wrapper .level-3-content')
          .addClass('collapsed');
      }, 500);

      $scope.mobileTogglesInit();
    };

    const initStart = () => {
      if ($scope.insAppId !== 0) {
        insuranceAppGetMethod($scope.insAppId);
        toaster.pop(
          'success',
          'Insurance Application',
          'Insurance Application successfully retrieved.'
        );
      } else {
        getContactFamilyInfo($scope.familyId);
      }

      if ($scope.adviserInitials === '') {
        insuranceAdviserListGet();
      }

      $scope.getClientInformation();
      $scope.togglesInit();
    };

    // initialize
    initStart();
  });

import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { DEFAULT_PIPELINE_FILTER } from 'Common/default/pipeline';

export default class InsurancePipelieFiltersCtrl {
  constructor(currentUserService) {
    'ngInject';

    this.currentUserService = currentUserService;
  }

  $onInit() {
    this.isCorporateUser =
      this.currentUserService.accessType === ACCESS_TYPE.CORPORATE;
    this.adviserFilter = this.adviserFilter || 0;
    this.providerFilter = this.providerFilter || 0;
    this.labelFilter = this.labelFilter || 0;
  }

  $onChanges(changes) {
    const { labels, advisers, statusFilters, providers } = changes;
    if (labels && labels.currentValue) {
      this.labels = [DEFAULT_PIPELINE_FILTER, ...labels.currentValue];
    }
    if (advisers && advisers.currentValue) {
      this.advisers = [
        { brokerId: 0, displayName: 'All' },
        ...advisers.currentValue,
      ];
    }
    if (providers && providers.currentValue) {
      this.providers = [DEFAULT_PIPELINE_FILTER, ...providers.currentValue];
    }
    if (statusFilters) {
      this.statusFilters = statusFilters.currentValue || [];
    }
  }
}

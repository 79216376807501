import angular from 'angular';
import { mapCustomConfig, circleDefaultOptions } from 'Common/config/gMap';
import { getGoogleMapInstance } from 'Common/utilities/map';

class GoogleMapShapesCtrl {
  constructor(googleMapService, $interval, corporateService) {
    'ngInject';

    this.googleMapService = googleMapService;
    this.$interval = $interval;
    this.corporateService = corporateService;
  }

  addCircleRadius(lat, lng) {
    const googleMapWindow = getGoogleMapInstance();
    if (!googleMapWindow) return;
    // Initialize listeners for initial animations
    // Use only on first run
    const circleFitListener = googleMapWindow.event.addListener(
      this.map,
      'bounds_changed',
      () => {
        this.map.fitBounds(this.circle.getBounds());
      }
    );
    googleMapWindow.event.addListener(this.map, 'tilesloaded', () => {
      googleMapWindow.event.removeListener(circleFitListener);
    });
    const circleDefaultOpt = circleDefaultOptions();
    const options = {
      map: this.map,
      center: new googleMapWindow.LatLng(lat, lng),
      radius: this.distanceValue * 1000,
    };
    const circleOptions = { ...circleDefaultOpt, ...options };
    this.circle = new googleMapWindow.Circle(circleOptions);
  }

  circleRadiusReInitialize() {
    this.circle.setRadius(this.distanceValue * 1000);
    this.map.fitBounds(this.circle.getBounds());
  }

  getSelectedValue(value) {
    this.distanceValue = parseInt(value, 10);
    this.circleRadiusReInitialize();
    this.leadDetailsObj.Distance = parseInt(value, 10);
    this.corporateService.leadsProfileSet(
      this.mainFamilyId,
      0,
      this.leadDetailsObj
    );
  }

  loadMap(mapId) {
    const mapConfig = mapCustomConfig();
    const customConfig = {
      map: {
        mapTypeControl: false,
        zoomControl: false,
        streetViewControl: false,
      },
    };
    const map = { ...mapConfig.map, ...customConfig.map };
    const overrideMapCustomConfig = { ...mapConfig, map, hasInfoWindow: false };
    const mapRequiredConfig = {
      mapId,
      latitude: this.addressModelValue.latitude,
      longitude: this.addressModelValue.longitude,
      address: this.addressModelValue.formatted_address,
    };
    this.map = this.googleMapService.mapCorporate(
      mapRequiredConfig,
      overrideMapCustomConfig
    );
    if (!this.map) return;
    this.addCircleRadius(
      this.addressModelValue.latitude,
      this.addressModelValue.longitude
    );
  }

  checkMapEle() {
    const gmapEle = angular.element(`.gmap-shapes-component #${this.mapId}`);
    if (!gmapEle.length) return;
    this.loadMap(this.mapId);
    if (this.mapEleInterval) this.$interval.cancel(this.mapEleInterval);
  }

  $onChanges(changes) {
    if (!changes) return;
    if (changes.addressModel && changes.addressModel.currentValue) {
      this.addressModelValue = changes.addressModel.currentValue;
    }
    if (changes.leadDetails && changes.leadDetails.currentValue) {
      this.leadDetailsObj = changes.leadDetails.currentValue;
      this.distanceValue = parseInt(
        changes.leadDetails.currentValue.Distance,
        10
      );
    }
    this.checkMapEle();
  }

  $onInit() {
    this.options = {
      from: this.minDistance,
      to: this.maxDistance,
      step: 1,
      className: 'clip-slider',
      css: {
        background: {
          'background-color': 'silver',
        },
        range: {
          'background-color': '#579BCB',
        },
      },
    };
    this.mapEleInterval = this.$interval(this.checkMapEle.bind(this), 1000);
  }

  $onDestroy() {
    this.$interval.cancel(this.mapEleInterval);
  }
}

export default GoogleMapShapesCtrl;

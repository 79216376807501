import { getGoogleMapInstance } from 'Common/utilities/map';
import { mapCustomConfig, mycrmCustomGMapStyles } from 'Common/config/gMap';

class GoogleMapService {
  constructor($q, $window, $timeout, $document, configService, $cacheFactory) {
    'ngInject';

    this.$q = $q;
    this.$window = $window;
    this.$timeout = $timeout;
    this.$document = $document;
    this.configService = configService;
    this.$cacheFactory = $cacheFactory;
  }

  cacheGoogleMap() {
    this.cacheGoogleMapService =
      this.get('googleMapServiceCache') ||
      this.$cacheFactory('googleMapServiceCache');
  }

  validateMapOptions(lat = 0, long = 0, type, address) {
    const cacheCall = this.cacheGoogleMapService.get(`${type}_${address}`);
    if ((!lat || !long) && !cacheCall && address) {
      this.cacheGoogleMapService.put(`${type}_${address}`, 1);
      this.$timeout(() => {
        this.cacheGoogleMapService.remove(`${type}_${address}`);
      }, 3000);
    }
    return lat && long;
  }

  setGoogleMapOptions(options) {
    this.googleMapWindow = getGoogleMapInstance();
    const { latitude, longitude, mapId, address, map } = options;
    if (!this.googleMapWindow && !address && !mapId && !latitude && !longitude)
      return;
    const mapOptions = {
      center: new this.googleMapWindow.LatLng(latitude, longitude),
      styles: mycrmCustomGMapStyles(),
    };
    const mapOverrideOptions = { ...mapOptions, ...map };
    const mapElement =
      this.$document && this.$document[0].getElementById(mapId);
    const googleMap = new this.googleMapWindow.Map(
      mapElement,
      mapOverrideOptions
    );
    return googleMap;
  }

  addGMapMarker(map, options) {
    if (!this.googleMapWindow) return;
    const { latitude, longitude, address, marker } = options;
    const markerOptions = {
      map,
      position: new this.googleMapWindow.LatLng(latitude, longitude),
      title: address,
      ...marker,
    };
    const mapMarker = new this.googleMapWindow.Marker(markerOptions);
    return mapMarker;
  }

  addGMapInfoWindow(options) {
    if (!this.googleMapWindow) return;
    const { address, infowindow } = options;
    const infoWindowOptions = { content: address, ...infowindow };
    const infoWindow = new this.googleMapWindow.InfoWindow(infoWindowOptions);
    return infoWindow;
  }

  mapCenterChanged(googleMap, latitude, longitude) {
    googleMap.addListener('center_changed', () => {
      this.googleMapWindow.event.trigger(googleMap, 'resize');
      this.googleMapWindow.event.clearListeners(googleMap, 'center_changed');
      googleMap.setCenter(new this.googleMapWindow.LatLng(latitude, longitude));
    });
  }

  mapCorporate(mapRequiredConfig, overrideMapCustomConfig = mapCustomConfig()) {
    const { latitude, longitude } = mapRequiredConfig;
    const { hasInfoWindow } = overrideMapCustomConfig;
    if (!this.googleMapWindow && !mapRequiredConfig && !overrideMapCustomConfig)
      return;

    const mapConfig = { ...mapRequiredConfig, ...overrideMapCustomConfig };
    const googleMap = this.setGoogleMapOptions(mapConfig);
    const marker = this.addGMapMarker(googleMap, mapConfig);

    if (!hasInfoWindow) return googleMap;
    this.mapCenterChanged(googleMap, latitude, longitude);
    const infoWindow = this.addGMapInfoWindow(mapConfig);
    infoWindow.open(googleMap, marker);
    return googleMap;
  }
}

export default GoogleMapService;

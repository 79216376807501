import template from './assetFinanceProductFinder.html';
import controller from './assetFinanceProductFinderCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    brokerEventId: '<',
    loanScenarioId: '<',
    loanAppId: '<',
    onSearch: '&',
  },
};

import angular from 'angular';

angular
  .module('app')
  .controller('AttachFileMgmtCtrl', function AttachFileMgmtCtrl(
    $scope,
    $rootScope,
    $http,
    $timeout,
    $uibModalInstance,
    $stateParams,
    contactService,
    $filter,
    configService,
    userService,
    toaster,
    $state,
    familyId,
    broadcastValue
  ) {
    $scope.emailInterface = {};
    $scope.uploadCount = 0;
    $scope.currentUploadIndex = 0;
    $scope.success = false;
    $scope.error = false;
    $scope.familyId = familyId || $state.params.familyId;
    $scope.hideDocumentProgress = false;
    $scope.documentLists = [];
    $scope.userDetails = {};
    $scope.files_added = [];
    $scope.activeTab = 0;
    $scope.files_attached = [];
    $scope.filesAddedQueue = [];
    $scope.lastCommit = 0;
    $scope.isUploadedFile = false;
    $scope.hasFileQueue = false;
    $scope.filesAddedTmp = [];
    let isFileAdded = false;

    const loadAllRelatedDocuments = () => {
      contactService.getDocumentList($scope.familyId).then(response => {
        const tmp = [];
        if (!response || !response.data) return;
        angular.forEach(response.data, value => {
          value.showIcon = false;
          tmp.push(value);
        });
        $scope.documentsLists = tmp;
      });
    };

    $scope.$on('$dropletReady', () => {
      $scope.emailInterface.allowedExtensions([/.+/]);
      $scope.emailInterface.setRequestUrl(
        `${configService.resource}/contacts/DocumentUpload`
      );
      $scope.emailInterface.setRequestHeaders({
        Authorization: configService.token,
      });
      $scope.emailInterface.defineHTTPSuccess([/2.{2}/]);
    });

    $scope.closeModal = () => {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.$on('$dropletSuccess', (event, file) => {
      if (!isFileAdded) {
        $scope.currentUploadIndex++;
        $scope.emailInterface.progress.percent = 0;
        $scope.filesAddedTmp = [...$scope.filesAddedTmp, ...file];
        $scope.isUploadedFile = true;
        toaster.pop('success', 'Success', 'Successfully uploaded file(s)');
        isFileAdded = true;
      }
    });

    $scope.$on('$dropletError', () => {
      $scope.isUploadedFile = true;
      $scope.currentUploadIndex++;
      $scope.emailInterface.progress.percent = 0;
      toaster.pop('error', 'Something went wrong', 'Please try again');
    });

    $scope.$on('$dropletFileAdded', (event, file) => {
      $scope.hasFileQueue = true;
      $scope.files_attached = [...$scope.files_attached, file];
      $scope.filesUploaded = $scope.files_attached.length;
      isFileAdded = false;
      $scope.isUploadedFile = false;
      $scope.emailInterface.uploadFiles();
    });

    $scope.addFileOnQueue = file => {
      $scope.hasFileQueue = true;
      file.showIcon = !file.showIcon;
      if (!file) return;
      const fileAddQueue = () => {
        $scope.filesAddedQueue &&
          Object.keys($scope.filesAddedQueue).forEach(i => {
            if (!$scope.filesAddedQueue[i]) return false;
            if (
              parseInt($scope.filesAddedQueue[i].DocumentId, 10) ===
              parseInt(file.DocumentId, 10)
            ) {
              $scope.filesAddedQueue.splice(i, 1);
            }
          });
      };
      if (file.showIcon) $scope.filesAddedQueue.push(file);
      else fileAddQueue();
      $scope.isUploadedFile = !!$scope.filesAddedQueue.length;
    };

    $scope.attachedtoEmail = () => {
      if (!$scope.filesAddedTmp || !$scope.filesAddedTmp.length) return;
      angular.forEach($scope.filesAddedTmp, value => {
        $scope.filesAddedQueue.push(value);
      });
      $scope.$parent.$broadcast(broadcastValue, $scope.filesAddedQueue);
      $uibModalInstance.dismiss('cancel');
    };
    const init = () => {
      userService.GetUserInfo().then(response => {
        if (!response || !response.data) return;
        $scope.userDetails = response.data;
      });
      loadAllRelatedDocuments();
    };
    init();
  });

import angular from 'angular';
import _ from 'lodash';

angular
  .module('app')
  .directive('modalScrollTab', function modalScrollTab($window, $timeout) {
    return {
      compile() {
        return function() {
          // intiate variables
          const scrollable = $window.document.querySelector('.modal-body');
          const sticky = $window.document.querySelector('[data-sticky]');
          let y = sticky.offsetTop + 20;
          let x = sticky.offsetLef;
          let z = $window.getComputedStyle(sticky).width;

          // recalculate values on resize
          $window.addEventListener('resize', () => {
            y = sticky.offsetTop + 20;
            x = sticky.offsetLef;
            z = $window.getComputedStyle(sticky).width;
          });

          // function for creating styles
          function setStyles(el, styles) {
            styles &&
              Object.keys(styles).forEach(style => {
                el.style[style] = styles[style];
              });
          }

          // fix the left hand nav on scroll
          scrollable.addEventListener('scroll', () => {
            if (scrollable.scrollTop > 0) {
              setStyles(sticky, {
                width: z,
                position: 'fixed',
                top: y,
                left: x,
              });
            } else {
              sticky.removeAttribute('style');
            }
          });

          const nav = $window.document.querySelector('.info-section');

          // function to reset class
          // used in the nav items
          function resetClass() {
            const t = nav.children;
            for (let r = 0, s = t.length; r < s; r += 1) {
              t[r].classList.remove('active');
            }
          }

          // Thanks to VIJAY
          // Get the nav-contents
          const navContents = $window.document.querySelectorAll('[data-name]');

          // set the default active
          nav.children[0].classList.add('active');

          // assign active on scroll
          // can use some refactor to make it
          // more flexible
          scrollable.addEventListener('scroll', () => {
            if (!navContents || !navContents.length) return;
            navContents.forEach((el, index) => {
              if (index === 0 && _.size(navContents) > 1) {
                if (
                  scrollable.scrollTop === el.offsetTop &&
                  scrollable.scrollTop < navContents[1].offsetTop
                ) {
                  resetClass();
                  if (
                    nav &&
                    nav.children &&
                    nav.children[0] &&
                    nav.children[0].classList
                  ) {
                    nav.children[0].classList.add('active');
                  }
                }
              } else if (scrollable.scrollTop >= el.offsetTop) {
                resetClass();
                if (
                  nav &&
                  nav.children &&
                  nav.children[index] &&
                  nav.children[index].classList
                ) {
                  nav.children[index].classList.add('active');
                }
              }

              // We need to know if we really reached the bottom of the scrollable area
              if (
                Math.floor(scrollable.scrollTop + scrollable.offsetHeight) ===
                  scrollable.scrollHeight ||
                Math.ceil(scrollable.scrollTop + scrollable.offsetHeight) ===
                  scrollable.scrollHeight
              ) {
                resetClass();
                const navChildren = nav.children[_.size(navContents) - 1];
                if (navChildren && navChildren.classList) {
                  navChildren.classList.add('active');
                }
              }
            });
          });

          // get all anchors

          const anchors = $window.document.querySelectorAll('a[data-target]');

          // scroll to function
          function scrollTo(element, to, duration) {
            if (duration <= 0) return;
            const difference = to - element.scrollTop;
            const perTick = (difference / duration) * 10;

            $timeout(() => {
              element.scrollTop += perTick;
              if (element.scrollTop === to) return;
              scrollTo(element, to, duration - 10);
            }, 10);
          }

          if (anchors) {
            anchors.forEach(el => {
              el.addEventListener('click', e => {
                const targetLink = e.target.dataset.target;
                const targetContext = $window.document.querySelector(
                  `[data-name="${targetLink}"`
                );
                const targetContextInt = targetContext.offsetTop;
                // initiate scroll
                scrollTo(scrollable, targetContextInt, 225);
              });
            });
          }
        };
      },
    };
  });

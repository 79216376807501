import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import swal from 'sweetalert';

angular
  .module('app')
  .controller('NewEmailCampaignCtrl', function NewEmailCampaignCtrl(
    $scope,
    $rootScope,
    $http,
    SweetAlert,
    configService,
    $filter,
    dashboardService,
    imageDataURI,
    $timeout,
    $window,
    $templateCache,
    communicateService,
    colorService,
    toaster,
    NgTableParams,
    userService,
    corporateService,
    contactService,
    $uibModal,
    $state,
    cfpLoadingBar,
    optionsService,
    referralsModel,
    referralsService,
    uiService
  ) {
    // MODEL
    // /////////////////
    const confirmButtonColor = '#F68F8F';
    const lastFilterGender = [];
    const lastFilter = [];
    const lastFilterCategory = [];
    const lastFilterLender = [];
    const lenderPlace = 0;
    const lastFilterRating = [];
    const lastFilterEnquirySource = [];
    const lastFilterAdviser = [];
    $scope.selectedRecipientList = [];
    $scope.isShowEmailCampaignReferrers = uiService.showEmailCampaignReferrers();

    angular.extend($scope, {
      totalContact: 0,
      isDownloading: false,
      totalPagingCount: 0,
      emailCampaignRepeater: [],
      campaignModel: {
        filterBy: 'client',
      },
      filterRefreshData: [
        {
          location: [],
          postCode: [],
          ageFrom: null,
          ageTo: null,
          gender: [],
          loanStatus: [],
          loanRateType: [],
          lender: [],
          contactStatus: [],
          categories: [],
          ratings: [],
          enquirySource: [],
          adviser: [],
          locationFilter: '',
          postCodeFilter: '',
          contactStatusFilter: '',
        },
      ],
      contactStatus: [
        { ContactStatusId: 1, ContactStatusDescription: 'Lead' },
        { ContactStatusId: 2, ContactStatusDescription: 'Application' },
        { ContactStatusId: 3, ContactStatusDescription: 'Existing Client' },
        {
          ContactStatusId: 4,
          ContactStatusDescription: 'Professional Partner',
        },
        { ContactStatusId: 5, ContactStatusDescription: 'Previous' },
      ],
      selectionStorage: [],
      isAllSelected: { val: true },
      currentPage: 1,
      pageSize: 15,
      allPageSize: 0,
      campaignRecipientList: [],
      selectedRecipientList: [],
      inProgress: false,
      displayECampFilter: false,
      selectedContacts: 0,
      propertyName: 'FamilyFullName',
      reverse: false,
      locationList: [],
      postCodeList: [],
      genderList: {},
      loanStatusListSelected: [],
      loanStatusList: {},
      loanRateTypeList: [],
      loanRateTypeListSelected: [],
      categoriesList: {},
      lenderList: {},
      lenderListSelected: [],
      categoryListSelected: [],
      ratingsList: {},
      ratingListSelected: [],
      enquirySourceList: {},
      enquirySourceListSelected: [],
      adviserList: {},
      adviserListSelected: [],
    });
    $scope.isDisabledAdviser = true;
    optionsService.getMostRecentLeads().then(response => {
      if (!response || !response.data) return;
      $scope.mostRecentLeads = response.data;
      $scope.campaignModel.mostRecentLead = $scope.mostRecentLeads[0]; // set initial value
    });

    // FUNCTIONALITIES
    // /////////////////
    function getRandomBg() {
      return colorService.getRandomColor();
    }

    // WATCHERS
    // /////////////////

    $scope.$watch(
      () => {
        $scope.windowWidth = $window.innerWidth;
        if ($scope.windowWidth > 992) {
          $scope.isMobileDisplayView = false;
        } else if ($scope.windowWidth <= 992) {
          $scope.isMobileDisplayView = true;
        }
        return $scope.windowWidth;
      },
      () => {
        return $scope.windowWidth;
      },
      true
    );

    /** ****************************************************************** */
    const downloadCSVFile = data => {
      $scope.isDownloading = false;

      const element = $window.document.createElement('a');
      element.setAttribute(
        'href',
        `data:${data.ContentType};base64,${data.DocumentContent}`
      );
      element.setAttribute('download', data.Name);
      element.setAttribute('id', 'downloadCSVFile');

      element.style.display = 'none';
      $window.document.body.appendChild(element);

      const eventTimeout = $timeout(() => {
        $window.document.getElementById('downloadCSVFile').click();
        $window.document.body.removeChild(element);
        $timeout.cancel(eventTimeout);
      });
      const loadingBarTimeout = $timeout(() => {
        cfpLoadingBar.complete();
        $timeout.cancel(loadingBarTimeout);
      }, 750);
    };

    const checkIfEmailCampaignIsCorp = campaignModel => {
      if (!campaignModel) return 0;
      let adviserId = 0;
      if (campaignModel.isCorporateUser) {
        adviserId =
          campaignModel.adviserUnderOrgObj &&
          campaignModel.adviserUnderOrgObj.originalObject
            ? campaignModel.adviserUnderOrgObj.originalObject.FamilyId
            : 0;
      } else {
        adviserId =
          $scope.adviserListSelected && $scope.adviserListSelected.length
            ? $scope.adviserListSelected[0].FamilyId
            : 0;
      }
      return adviserId;
    };

    const getStatusId = campaignModel => {
      if (!campaignModel) return -1;
      return campaignModel.statusId;
    };

    const getOrgId = (campaignModel, key) => {
      if (
        !campaignModel ||
        !campaignModel.originalObject ||
        !campaignModel.originalObject[key]
      )
        return 0;
      return campaignModel.originalObject[key];
    };

    const getMostRecentLeads = (campaignModel, key) => {
      if (!campaignModel || !campaignModel[key]) return 0;
      return campaignModel[key];
    };

    const getEmailCampaignReferrersParams = campaignModel => {
      const adviserIdValue = checkIfEmailCampaignIsCorp(campaignModel);
      return {
        userId: 0,
        adviserId: adviserIdValue,
        isActive: campaignModel.contactReferrerStatusSelected
          ? getStatusId(campaignModel.contactReferrerStatusSelected)
          : -1,
        referrerOrgId: campaignModel.referrerOrgObj
          ? getOrgId(campaignModel.referrerOrgObj, 'ReferrerOrgId')
          : 0,
        adviserOrgId: campaignModel.adviserOrgObj
          ? getOrgId(campaignModel.adviserOrgObj, 'AdviserOrganizationId')
          : 0,
        recentLeadInDays: campaignModel.mostRecentLead
          ? getMostRecentLeads(campaignModel.mostRecentLead, 'from')
          : 0,
        recentLeadToInDays: campaignModel.mostRecentLead
          ? getMostRecentLeads(campaignModel.mostRecentLead, 'to')
          : 0,
      };
    };

    const getDownloadFileData = qry => {
      if ($scope.campaignModel.filterBy === 'referrers') {
        const emailCampaignParamsforDwnload = getEmailCampaignReferrersParams(
          $scope.campaignModel
        );
        const {
          userId,
          isActive,
          referrerOrgId,
          adviserOrgId,
          adviserId,
          recentLeadInDays,
          recentLeadToInDays,
        } = emailCampaignParamsforDwnload;
        const totalPageNumber =
          $scope.selectedRecipientList.length !==
          $scope.campaignRecipientList.length
            ? $scope.selectedRecipientList.length
            : 0;
        communicateService
          .getEmailCampaignReferrersExcelExport(
            userId,
            isActive,
            referrerOrgId,
            adviserOrgId,
            adviserId,
            recentLeadInDays,
            recentLeadToInDays,
            $scope.currentPage,
            totalPageNumber
          )
          .then(
            response => {
              if (!response || !response.data) return;
              downloadCSVFile(response.data);
            },
            () => {
              toaster.pop('error', 'Something went wrong', 'Please try again');
            }
          );
      } else {
        communicateService.exportRecipientsToCSV(qry).then(
          response => {
            if (response.data && response.data.length) {
              downloadCSVFile(response.data);
            } else {
              toaster.pop('Error', 'No file to download.');
            }
          },
          () => {
            toaster.pop('error', 'Something went wrong', 'Please try again');
          }
        );
      }
    };

    const getKeys = obj => {
      return obj && Object.keys(obj);
    };

    const createQry = (obj, keys) => {
      let str = '';
      keys.forEach(k => {
        if (k === 'contactStatus') {
          // exclude contactStatus property
          return;
        }

        if (typeof obj[k] !== 'number') {
          if (!_.isEmpty(obj[k])) {
            if (str !== '') {
              str = `${str}&${k}=${obj[k]}`;
            } else {
              str = `?${k}=${obj[k]}`;
            }
          }
        } else if (!_.isNil(obj[k])) {
          if (str !== '') {
            str = `${str}&${k}=${obj[k]}`;
          } else {
            str = `?${k}=${obj[k]}`;
          }
        }
      });
      return str;
    };

    $scope.downloadSpreadsheet = () => {
      $scope.isDownloading = true;
      cfpLoadingBar.start();
      cfpLoadingBar.inc();
      const filterObj = _.first($scope.filterRefreshData);
      if (!_.isNil(filterObj.contactStatus)) {
        filterObj.contactStatusFilter = '';
      }
      filterObj.pageNumber = 1;
      if (
        $scope.isAllSelected.val &&
        typeof _.first($scope.campaignRecipientList).TotalCount !== 'undefined'
      ) {
        filterObj.pageSize = Math.ceil(
          _.first($scope.campaignRecipientList).TotalCount
        );
      } else {
        filterObj.pageSize = $scope.allPageSize;
      }
      let referralCategoryIds;
      let enquirySourceIds;
      if (filterObj.enquirySource) {
        const enquirySourceObj = Object.assign([], filterObj.enquirySource);
        referralCategoryIds = _.map(enquirySourceObj, 'ReferralCategoryId');
        referralCategoryIds = referralCategoryIds
          ? referralCategoryIds.toString()
          : '';
        enquirySourceIds = _.map(enquirySourceObj, 'EnquirySourceId');
        enquirySourceIds = enquirySourceIds ? enquirySourceIds.toString() : '';
      }
      const isSelectedAll = $scope.isAllSelected.val;
      const familyIds = $scope.selectedRecipientList.map(item => item.FamilyID);
      const keys = getKeys(filterObj);
      const index = keys.indexOf('enquirySource');
      if (index > -1) {
        keys.splice(index, 1);
      }
      const qry = createQry(filterObj, keys);
      const qryByIds = familyIds.join(',');
      if ($scope.isAllSelected.val && $scope.selectedContacts > 500) {
        swal(
          {
            title: 'Downloading...',
            text:
              'It takes some time to download all recipients. Are you willing to wait?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor,
            confirmButtonText: 'Yes!',
            closeOnConfirm: true,
          },
          isConfirm => {
            if (isConfirm) {
              getDownloadFileData(
                `${qry}&familyIds=${qryByIds}&isSelectedAll=${isSelectedAll}&referralCategoryIds=${referralCategoryIds}&enquirySource=${enquirySourceIds}`
              );
            } else {
              $scope.isDownloading = false;
              $timeout(() => {
                cfpLoadingBar.complete();
              }, 750);
            }
          }
        );
      } else {
        getDownloadFileData(
          `${qry}&familyIds=${qryByIds}&isSelectedAll=${isSelectedAll}&referralCategoryIds=${referralCategoryIds}&enquirySource=${enquirySourceIds}`
        );
      }
    };

    $scope.mailChimpFound = false;
    function checkMailChimp() {
      communicateService.getCampaignReports().then(response => {
        if (response.data && !response.data.isError) {
          $scope.mailChimpFound = true;
        }
      });
    }

    angular.extend($scope, {
      selectTemplate(emailRecipient) {
        if ($scope.mailChimpFound) {
          $uibModal.open({
            templateUrl: '/assets/views/modals/mailchimpCampaignTemplate.html',
            controller: 'SelectTemplateModalCtrl',
            resolve: {
              emailRecipient: () => emailRecipient,
            },
          });
        } else {
          swal({
            title: 'No MailChimp Account',
            text:
              "It appears that you don't currently have a Mailchimp account connected - please contact Support to arrange",
            type: 'info',
          });
        }
      },
    });

    /** ****************************************************************** */

    angular.element($window).bind('resize', () => {
      $scope.windowWidth = $window.innerWidth;
    });

    /** ****************************************************************** */
    function showFilterVal() {
      $scope.displayECampFilter = !$scope.displayECampFilter;
    }

    function sortBy(propertyName) {
      $scope.reverse =
        propertyName !== null && $scope.propertyName === propertyName
          ? !$scope.reverse
          : false;
      $scope.propertyName = propertyName;
    }

    /** ****************************************************************** */
    function removeLocationList(item) {
      $scope.locationList.splice(item, 1);
      if (typeof _.first($scope.filterRefreshData) !== 'undefined') {
        _.first($scope.filterRefreshData).location.splice(item, 1);
      }
    }

    function removePostCodeList(item) {
      $scope.postCodeList.splice(item, 1);
    }

    /** ****************************************************************** */

    function pushLocation(value) {
      if (value) {
        $scope.locationList.push(value);
        _.first($scope.filterRefreshData).location.push(value);
        _.first($scope.filterRefreshData).locationFilter = '';
      }
    }

    /** ****************************************************************** */

    function pushPostCode(value) {
      if (!_.isUndefined(value) && value !== '') {
        $scope.postCodeList.push(value);
        _.first($scope.filterRefreshData).postCode.push(value);
        _.first($scope.filterRefreshData).postCodeFilter = '';
      }
    }

    /** ****************************************************************** */

    function genderGet() {
      const temp = {
        GenderID: -1,
        GenderName: 'All Gender',
        ticked: false,
      };
      const data = [
        {
          GenderID: 1,
          GenderName: 'Male',
        },
        {
          GenderID: 2,
          GenderName: 'Female',
        },
      ];
      data.unshift(temp);

      const tmp = [];
      data &&
        Object.keys(data).forEach(x => {
          const value = data[x];
          if (lastFilterGender.indexOf('All') > -1) {
            value.ticked = parseInt(value.GenderID, 10) === -1;
          } else if (lastFilterGender.indexOf(data[x].GenderName) > -1) {
            value.ticked = true;
          }
          tmp.push(value);
        });

      $scope.genderList = tmp;
    }

    $scope.onSelectAllGender = () => {
      _.first($scope.filterRefreshData).gender = [];
      for (let i = 0; i < $scope.genderList.length; i++) {
        if ($scope.genderList[i].GenderID > -1) {
          $scope.genderList[i].ticked = false;
        } else {
          $scope.genderList[i].ticked = true;
        }
      }
    };

    function isOtherTickedGender(data) {
      let bool = false;
      _.first($scope.filterRefreshData).gender = [];
      data.forEach(val => {
        if (val.GenderID > -1 && val.ticked) {
          bool = true;
          _.first($scope.filterRefreshData).gender.push(val.GenderID);
        }
      });

      return bool;
    }

    $scope.genderListSelected = [];
    $scope.onItemSelectGender = data => {
      if (data.GenderID === -1) {
        let i;
        for (i = 0; i < $scope.genderList.length; i++) {
          if ($scope.genderList[i].GenderID > -1) {
            $scope.genderList[i].ticked = false;
          } else {
            $scope.genderList[i].ticked = true;
          }
        }
      } else {
        let i;
        for (i = 0; i < $scope.genderList.length; i++) {
          if (
            parseInt($scope.genderList[i].GenderID, 10) === -1 &&
            !isOtherTickedGender($scope.genderList)
          ) {
            $scope.genderList[i].ticked = true;
            $scope.genderListSelected.push($scope.genderList[i]);
          } else if (parseInt($scope.genderList[i].GenderID, 10) === -1) {
            $scope.genderList[i].ticked = false;
          }
        }
      }
    };

    function onSelectAll() {
      _.first($scope.filterRefreshData).loanStatus = [];
      for (let i = 0; i < $scope.loanStatusList.length; i++) {
        if ($scope.loanStatusList[i].LoanStatusID > -1) {
          $scope.loanStatusList[i].ticked = false;
        } else {
          $scope.loanStatusList[i].ticked = true;
        }
      }
    }

    function isOtherTicked(data) {
      let bool = false;
      $scope.loanStatusListSelected = [];
      data.forEach(val => {
        if (val.LoanStatusID > -1 && val.ticked) {
          bool = true;
          _.first($scope.filterRefreshData).loanStatus.push(val.LoanStatusID);
        }
      });

      return bool;
    }

    function onItemSelect(data) {
      if (parseInt(data.LoanStatusID, 10) === -1) {
        let i;
        for (i = 0; i < $scope.loanStatusList.length; i++) {
          if ($scope.loanStatusList[i].LoanStatusID > -1) {
            $scope.loanStatusList[i].ticked = false;
          } else {
            $scope.loanStatusList[i].ticked = true;
          }
        }
      } else {
        let i;
        for (i = 0; i < $scope.loanStatusList.length; i++) {
          if (
            parseInt($scope.loanStatusList[i].LoanStatusID, 10) === -1 &&
            !isOtherTicked($scope.loanStatusList)
          ) {
            $scope.loanStatusList[i].ticked = true;
            $scope.loanStatusListSelected.push($scope.loanStatusList[i]);
          } else if ($scope.loanStatusList[i].LoanStatusID === -1) {
            $scope.loanStatusList[i].ticked = false;
          }
        }
      }
    }

    function isOtherLoanRateTypesTicked() {
      let isOthersTicked = false;
      $scope.loanRateTypeListSelected = [];
      _.each($scope.loanRateTypeList, type => {
        if (type.type !== 'All' && type.ticked) {
          isOthersTicked = true;
          const filterData = $scope.filterRefreshData
            ? $scope.filterRefreshData[0]
            : null;
          if (
            filterData &&
            filterData.loanRateType &&
            !_.some(filterData.loanRateType, val => val === type.type)
          ) {
            filterData.loanRateType.push(type.type);
          }
        }
      });
      return isOthersTicked;
    }

    $scope.onLoanRateTypeSelect = data => {
      if (data.type === 'All') {
        _.each($scope.loanRateTypeList, type => {
          type.ticked = type.type === 'All';
        });
      } else {
        _.each($scope.loanRateTypeList, type => {
          if (type.type === 'All' && !isOtherLoanRateTypesTicked()) {
            type.ticked = true;
            $scope.loanRateTypeListSelected.push(type);
          } else if (type.type === 'All') {
            type.ticked = false;
          }
        });
      }

      if (!data.ticked) {
        const filterRefreshData = $scope.filterRefreshData
          ? $scope.filterRefreshData[0]
          : null;
        if (filterRefreshData && filterRefreshData.loanRateType) {
          const removedIndex = _.findIndex(
            filterRefreshData.loanRateType,
            type => type === data.type
          );
          filterRefreshData.loanRateType.splice(removedIndex, 1);
        }
      }
    };

    $scope.onLoanRateTypeToggleAll = () => {
      const filterRefreshData = $scope.filterRefreshData
        ? $scope.filterRefreshData[0]
        : null;
      if (filterRefreshData) {
        filterRefreshData.loanRateType = [];
      }

      $scope.loanRateTypeListSelected = [];
      const loanRateTypes = $scope.loanRateTypeList;
      $scope.loanRateTypeList = [];
      _.each(loanRateTypes, type => {
        type.ticked = type.type === 'All';
        if (type.ticked) {
          $scope.loanRateTypeListSelected.push(type);
        }
      });
      $timeout(() => {
        $scope.loanRateTypeList = loanRateTypes;
      });
    };

    /** ****************************************************************** */

    function onSelectAllLender() {
      _.first($scope.filterRefreshData).lender = [];
      for (let i = 0; i < $scope.lenderList.length; i++) {
        if ($scope.lenderList[i].ProviderId > -1) {
          $scope.lenderList[i].ticked = false;
        } else {
          $scope.lenderList[i].ticked = true;
        }
      }
    }

    function isOtherTickedLender(data) {
      let bool = false;
      _.first($scope.filterRefreshData).lender = [];
      data.forEach(val => {
        if (val.ProviderId > -1 && val.ticked) {
          bool = true;
          _.first($scope.filterRefreshData).lender.push(val.ProviderId);
        }
      });

      return bool;
    }

    function onItemSelectLender(data) {
      if (data.ProviderId === -1) {
        let i;
        for (i = 0; i < $scope.lenderList.length; i++) {
          if ($scope.lenderList[i].ProviderId > -1) {
            $scope.lenderList[i].ticked = false;
          } else {
            $scope.lenderList[i].ticked = true;
          }
        }
      } else {
        let i;
        for (i = 0; i < $scope.lenderList.length; i++) {
          if (
            parseInt($scope.lenderList[i].ProviderId, 10) === -1 &&
            !isOtherTickedLender($scope.lenderList)
          ) {
            $scope.lenderList[i].ticked = true;
            $scope.lenderListSelected.push($scope.lenderList[i]);
          } else if (parseInt($scope.lenderList[i].ProviderId, 10) === -1) {
            $scope.lenderList[i].ticked = false;
          }
        }
      }
    }

    /** ****************************************************************** */

    function getLoanRateTypes() {
      userService.GetUserInfo().then(response => {
        if (response.data) {
          const list = [];
          list.push({
            type: 'All',
            LoanRateTypeName: 'All Loan Rate Types',
            ticked: true,
          });
          switch (response.data.CountryId) {
            case 2: // Australia
              list.push({ type: 'Floating', LoanRateTypeName: 'Variable' });
              break;
            default:
              // NZ
              list.push({ type: 'Floating', LoanRateTypeName: 'Floating' });
          }
          list.push({ type: 'Fixed', LoanRateTypeName: 'Fixed' });
          $scope.loanRateTypeList = list;
        }
      });
    }

    function onSelectAllCategory() {
      _.first($scope.filterRefreshData).categories = [];
      for (let i = 0; i < $scope.categoriesList.length; i++) {
        if ($scope.categoriesList[i].CategoryID > -1) {
          $scope.categoriesList[i].ticked = false;
        } else {
          $scope.categoriesList[i].ticked = true;
        }
      }
    }

    function isOtherTickedCategory(data) {
      let bool = false;
      _.first($scope.filterRefreshData).categories = [];
      data.forEach(val => {
        if (val.CategoryID > -1 && val.ticked) {
          bool = true;
          _.first($scope.filterRefreshData).categories.push(val.CategoryID);
        }
      });

      return bool;
    }

    function onItemSelectCategory(data) {
      if (data.CategoryID === -1) {
        let i;
        for (i = 0; i < $scope.categoriesList.length; i++) {
          if ($scope.categoriesList[i].CategoryID > -1) {
            $scope.categoriesList[i].ticked = false;
          } else {
            $scope.categoriesList[i].ticked = true;
          }
        }
        _.first($scope.filterRefreshData).categories = [];
      } else {
        let i;
        for (i = 0; i < $scope.categoriesList.length; i++) {
          if (
            parseInt($scope.categoriesList[i].CategoryID, 10) === -1 &&
            !isOtherTickedCategory($scope.categoriesList)
          ) {
            $scope.categoriesList[i].ticked = true;
            $scope.categoryListSelected.push($scope.categoriesList[i]);
          } else if (parseInt($scope.categoriesList[i].CategoryID, 10) === -1) {
            $scope.categoriesList[i].ticked = false;
          }
        }
      }
    }

    // Filter Select all
    $scope.ratingLocalLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: '<span>Reset</span>',
      search: 'Search',
      nothingSelected:
        "<div class='buttonLabel padding-left-10'>All Rating</div>", // default-label is deprecated and replaced with this.
    };

    function onSelectAllRating() {
      _.first($scope.filterRefreshData).ratings = [];
      for (let i = 0; i < $scope.ratingsList.length; i++) {
        if ($scope.ratingsList[i].RatingID > -1) {
          $scope.ratingsList[i].ticked = false;
        } else {
          $scope.ratingsList[i].ticked = true;
        }
      }
    }

    function isOtherTickedRating(data) {
      let bool = false;
      _.first($scope.filterRefreshData).ratings = [];
      data.forEach(val => {
        if (val.RatingID > -1 && val.ticked) {
          bool = true;
          _.first($scope.filterRefreshData).ratings.push(val.RatingID);
        }
      });

      return bool;
    }

    function onItemSelectRating(data) {
      if (data.RatingID === -1) {
        for (let i = 0; i < $scope.ratingsList.length; i++) {
          if ($scope.ratingsList[i].RatingID > -1) {
            $scope.ratingsList[i].ticked = false;
          } else {
            $scope.ratingsList[i].ticked = true;
          }
        }
      } else {
        for (let i = 0; i < $scope.ratingsList.length; i++) {
          if (
            $scope.ratingsList[i].RatingID === -1 &&
            !isOtherTickedRating($scope.ratingsList)
          ) {
            $scope.ratingsList[i].ticked = true;
            $scope.ratingListSelected.push($scope.ratingsList[i]);
          } else if ($scope.ratingsList[i].RatingID === -1) {
            $scope.ratingsList[i].ticked = false;
          }
        }
      }
    }

    function onChangeAllSelection() {
      for (let i = 0; i < $scope.enquirySourceList.length; i++) {
        if (
          $scope.enquirySourceList[i].EnquirySourceId === 0 &&
          $scope.enquirySourceList[i].ReferralCategoryId === 0
        ) {
          $scope.enquirySourceList[i].ticked = true;
          _.first($scope.filterRefreshData).enquirySource = [
            $scope.enquirySourceList[i],
          ];
        } else {
          $scope.enquirySourceList[i].ticked = false;
        }
      }
    }

    function onSelectAllEnquirySource() {
      _.first($scope.filterRefreshData).enquirySource = [];
      onChangeAllSelection();
    }

    function isOtherTickedEnquirySource(data) {
      let bool = false;
      _.first($scope.filterRefreshData).enquirySource = [];
      data.forEach(val => {
        if (
          val.EnquirySourceId !== 0 &&
          val.ReferralCategoryId !== 0 &&
          val.ticked
        ) {
          bool = true;
          _.first($scope.filterRefreshData).enquirySource.push(val);
        }
      });

      return bool;
    }

    function onItemSelectEnquirySource(data) {
      if (data.EnquirySourceId === 0 && data.ReferralCategoryId === 0) {
        onChangeAllSelection();
      } else {
        let i;
        for (i = 0; i < $scope.enquirySourceList.length; i++) {
          if (
            parseInt($scope.enquirySourceList[i].EnquirySourceId, 10) === 0 &&
            parseInt($scope.enquirySourceList[i].ReferralCategoryId, 10) ===
              0 &&
            !isOtherTickedEnquirySource($scope.enquirySourceList)
          ) {
            $scope.enquirySourceList[i].ticked = true;
            $scope.enquirySourceListSelected.push($scope.enquirySourceList[i]);
          } else if (
            $scope.enquirySourceList[i].EnquirySourceId === 0 &&
            $scope.enquirySourceList[i].ReferralCategoryId === 0
          ) {
            $scope.enquirySourceList[i].ticked = false;
          }
        }
      }
    }

    function onSelectAllAdviser() {
      _.first($scope.filterRefreshData).adviser = [];
      for (let i = 0; i < $scope.adviserList.length; i++) {
        if ($scope.adviserList[i].FamilyId > -1) {
          $scope.adviserList[i].ticked = false;
        } else {
          $scope.adviserList[i].ticked = true;
        }
      }
    }

    function isOtherTickedAdviser(data) {
      let bool = false;
      _.first($scope.filterRefreshData).adviser = [];
      data.forEach(val => {
        if (val.FamilyId > -1 && val.ticked) {
          bool = true;
          _.first($scope.filterRefreshData).adviser.push(val.FamilyId);
        }
      });

      return bool;
    }

    function onItemSelectAdviser(data) {
      if (data.FamilyId === -1) {
        for (let i = 0; i < $scope.adviserList.length; i++) {
          if ($scope.adviserList[i].FamilyId > -1) {
            $scope.adviserList[i].ticked = false;
          } else {
            $scope.adviserList[i].ticked = true;
          }
        }
      } else {
        for (let i = 0; i < $scope.adviserList.length; i++) {
          if (
            parseInt($scope.adviserList[i].FamilyId, 10) === -1 &&
            !isOtherTickedAdviser($scope.adviserList)
          ) {
            $scope.adviserList[i].ticked = true;
            $scope.adviserListSelected.push($scope.adviserList[i]);
          } else if (parseInt($scope.adviserList[i].FamilyId, 10) === -1) {
            $scope.adviserList[i].ticked = false;
          }
        }
      }
    }

    /** ****************************************************************** */
    // Campaing for Corp User
    function getUserInfo() {
      $scope.campaignModel.isCorporateUser = false;
      $scope.campaignModel.isClientHidden = false;
      userService.GetUserInfo().then(response => {
        const userInfo = response.data;
        if (userInfo) {
          $scope.campaignModel.FamilyId = userInfo.FamilyId;
          $scope.campaignModel.isCorporateUser = response.data.AccessType === 5;
          if (userInfo.CountryId === 1 && userInfo.AccessType === 5) {
            $scope.campaignModel.filterBy = 'adviser';
            $scope.campaignModel.isClientHidden = true;
          }
        }
      });
    }

    $scope.onItemSelectCorp = (
      data,
      newEmailCampaignFieldName,
      source,
      selection
    ) => {
      // selection = [];
      let selectionData = selection;
      _.map(source, row => {
        if (data[newEmailCampaignFieldName] === -1) {
          if (row[newEmailCampaignFieldName] === -1) {
            row.ticked = true;
            selectionData = row;
          } else {
            row.ticked = false;
          }
        } else {
          if (row[newEmailCampaignFieldName] === -1) {
            row.ticked = false;
          }
          if (
            selectionData.length === 0 &&
            row[newEmailCampaignFieldName] === -1
          ) {
            row.ticked = true;
          }
        }
      });
    };

    function getContactType() {
      contactService.getContactType().then(response => {
        // response.data.splice(0, 1); //remove client type
        const data = _.filter(response.data, tempData => {
          return (
            parseInt(tempData.ContactTypeId, 10) !== 0 &&
            parseInt(tempData.ContactTypeId, 10) !== 5
          );
        }); // Remove Client and Referrer Org from the list
        _.map(data, row => {
          row.ticked = false;
        });
        const all = {
          ContactTypeId: -1,
          ContactTypeDesc: 'All Types',
          ticked: true,
        };
        data.unshift(all);
        $scope.campaignModel.contactTypeRaw = angular.copy(data);
        $scope.campaignModel.contactType = data;
        $scope.campaignModel.contactTypeSelected = data[0];
      });
    }

    $scope.resetContactType = () => {
      const data = angular.copy($scope.campaignModel.contactTypeRaw);
      $scope.campaignModel.contactType = data;
      $scope.campaignModel.contactTypeSelected = data[0];
    };

    function operatingCountriesGet() {
      corporateService.operatingCountriesGet().then(response => {
        const data = response.data.splice(0, 3);
        _.map(data, row => {
          row.ticked = false;
        });
        const all = {
          CountryId: -1,
          CountryCode: '',
          CountryName: 'All Countries',
          CallingCode: '',
          ticked: true,
        };
        data.unshift(all);
        $scope.campaignModel.reportingCountryRaw = angular.copy(data);
        $scope.campaignModel.reportingCountry = data;
        $scope.campaignModel.reportingCountrySelected = data[0];
      });
    }

    $scope.resetOperatingCountry = () => {
      const data = angular.copy($scope.campaignModel.reportingCountryRaw);
      $scope.campaignModel.reportingCountry = data;
      $scope.campaignModel.reportingCountrySelected = data[0];
    };

    function reportingState() {
      corporateService.reportingStatesGet().then(response => {
        const data = response.data;
        _.map(data, row => {
          row.ticked = false;
        });
        const all = {
          ReportingStateId: -1,
          ReportingStateName: 'All States',
          ticked: true,
        };
        data.unshift(all);
        $scope.campaignModel.reportingStateRaw = angular.copy(data);
        $scope.campaignModel.reportingState = data;
        $scope.campaignModel.reportingStateSelected = data[0];
      });
    }
    reportingState();

    $scope.resetReportingState = () => {
      const data = angular.copy($scope.campaignModel.reportingStateRaw);
      $scope.campaignModel.reportingState = data;
      $scope.campaignModel.reportingStateSelected = data[0];
    };

    function reportingRegion() {
      corporateService.reportingRegionsGet().then(response => {
        const data = response.data;
        _.map(data, row => {
          row.ticked = false;
        });
        const all = {
          ReportingRegionId: -1,
          ReportingRegionName: 'All Regions',
          ticked: true,
        };
        data.unshift(all);
        $scope.campaignModel.reportingRegionRaw = angular.copy(data);
        $scope.campaignModel.reportingRegion = data;
        $scope.campaignModel.reportingRegionSelected = data[0];
      });
    }

    $scope.resetReportingRegion = () => {
      const data = angular.copy($scope.campaignModel.reportingRegionRaw);
      $scope.campaignModel.reportingRegion = data;
      $scope.campaignModel.reportingRegionSelected = data[0];
    };

    function getBrokerStatusList() {
      corporateService.brokerStatusListGet().then(response => {
        const data = response.data;
        _.map(data, row => {
          row.ticked = false;
        });
        const all = {
          BrokerStatusId: -1,
          BrokerStatusName: 'All Status',
          ticked: true,
        };
        data.unshift(all);
        $scope.campaignModel.contactStatusRaw = angular.copy(data);
        $scope.campaignModel.contactStatus = data;
        $scope.campaignModel.contactStatusSelected = data[0];
      });
    }

    const getContactReferrerStatus = () => {
      let data = [
        {
          statusId: 0,
          statusName: 'Inactive',
          ticked: false,
        },
        {
          statusId: 1,
          statusName: 'Active',
          ticked: false,
        },
      ];
      const all = {
        statusId: -1,
        statusName: 'All Status',
        ticked: true,
      };
      data = [all, ...data];
      $scope.campaignModel.contactReferrerStatus = [...data];
      $scope.campaignModel.contactReferrerStatusSelected = data[0];
    };

    $scope.referrerOrgListCampaign = [];
    $scope.searchAPIReferrerOrg = searchText => {
      if (searchText) {
        $scope.$broadcast('angucomplete-alt:clearInput', 'referrer');
        return referralsService
          .getReferrerOrgList(searchText)
          .then(response => {
            const { data } = response;
            if (!data || !data.length) return [];
            const orgList = _.uniqBy(data, i =>
              [i.ReferrerOrgId, i.ReferrerOrgName].join()
            );
            $scope.referrerOrgListCampaign = orgList.filter(row => {
              return row.ReferrerOrgName && row.ReferrerOrgId;
            });
            return $scope.referrerOrgListCampaign;
          });
      }
    };

    $scope.campaignModel.adviserId = 0;
    const getAdvisersList = adviserOrgId => {
      corporateService
        .advisersUnderOrganizationGet(adviserOrgId)
        .then(response => {
          if (!response || !response.data) return;
          $scope.adviserUnderOrganisationList = _.filter(
            response.data,
            data => {
              return data.FullName !== '';
            }
          );
          $scope.campaignModel.adviserUnderOrgObj = {
            originalObject: $scope.adviserUnderOrganisationList.find(
              org => org.FamilyId === $scope.campaignModel.adviserId
            ),
          };
        });
    };

    $scope.campaignModel.adviserOrg = 0;
    const adviserOrganizationListGet = () => {
      corporateService.adviserOrganizationListGet().then(response => {
        if (!response || !response.data) return;
        $scope.adviserOrganisationList = _.filter(response.data, data => {
          return data.AdviserOrganizatioName !== '';
        });
        $scope.campaignModel.adviserOrgObj = {
          originalObject: $scope.adviserOrganisationList.find(
            org => org.AdviserOrganizationId === $scope.campaignModel.adviserOrg
          ),
        };
      });
    };

    $scope.adviserOrgSelected = selected => {
      $scope.campaignModel.adviserOrgObj = 0;
      if (!selected || !selected.originalObject) return;
      $scope.isDisabledAdviser = !!(
        selected.originalObject.AdviserOrganizationId === 0
      );
      $scope.campaignModel.adviserOrgObj = selected;
      getAdvisersList(selected.originalObject.AdviserOrganizationId);
    };

    $scope.resetContactStatus = () => {
      const data = angular.copy($scope.campaignModel.contactStatusRaw);
      $scope.campaignModel.contactStatusFilter = data;
      $scope.campaignModel.contactStatusSelected = data[0];
    };

    function getEnquirySource() {
      communicateService.inquirySourceListGet().then(response => {
        const data = response.data;
        _.map(data, row => {
          row.ticked = false;
        });
        const all = {
          EnquirySourceId: -1,
          EnquirySource: 'All Sources',
          ticked: true,
        };
        data.unshift(all);
        $scope.campaignModel.enquirySourceRaw = angular.copy(data);
        $scope.campaignModel.enquirySource = data;
        $scope.campaignModel.enquirySourceSelected = data[0];
      });
    }

    $scope.resetEnquirySource = () => {
      const data = angular.copy($scope.campaignModel.enquirySourceRaw);
      $scope.campaignModel.enquirySource = data;
      $scope.campaignModel.enquirySourceSelected = data[0];
    };

    function getClientType() {
      corporateService.getClientType().then(response => {
        const data = response.data;
        _.map(data, row => {
          row.ticked = false;
        });
        const all = {
          ClientTypeID: -1,
          ClientTypeName: 'All Types',
          ticked: true,
        };
        data.unshift(all);
        $scope.campaignModel.clientTypeRaw = angular.copy(data);
        $scope.campaignModel.clientType = data;
        $scope.campaignModel.clientTypeSelected = data[0];
      });
    }

    $scope.resetClientType = () => {
      const data = angular.copy($scope.campaignModel.clientTypeRaw);
      $scope.campaignModel.clientType = data;
      $scope.campaignModel.clientTypeSelected = data[0];
    };

    function getCategoryList() {
      communicateService.categoryListGet().then(response => {
        const data = response.data;
        _.map(data, row => {
          row.ticked = false;
        });
        const all = {
          CategoryID: -1,
          CategoryName: 'All Categories',
          ticked: true,
        };
        data.unshift(all);
        $scope.campaignModel.categoryRaw = angular.copy(data);
        $scope.campaignModel.category = data;
        $scope.campaignModel.categorySelected = data[0];
      });
    }
    $scope.resetCategory = () => {
      const data = angular.copy($scope.campaignModel.categoryRaw);
      $scope.campaignModel.category = data;
      $scope.campaignModel.categorySelected = data[0];
    };

    function getMultiSelectObj(types) {
      return {
        selectAll: '<span>Select All</span>',
        selectNone: '<span>Deselect All </span>',
        reset: '<span>Reset</span>',
        search: 'Search',
        nothingSelected: `<div class='buttonLabel padding-left-10'>All ${types ||
          'Types'}</div>`, // default-label is deprecated and replaced with this.
      };
    }

    // utilities
    // /////////
    function dataHandler(currentPage, action) {
      switch (action) {
        case 'init':
          toaster.pop('success', 'Success', 'Results Refresh successfully!');
          break;

        case 'toggleAll':
          _.each($scope.campaignRecipientList, itm => {
            itm.selected = $scope.isAllSelected.val;
          });
          if ($scope.isAllSelected.val) {
            $scope.selectedRecipientList = $scope.campaignRecipientList;
          } else {
            $scope.selectedRecipientList = [];
          }
          if (
            $scope.campaignRecipientList &&
            $scope.campaignRecipientList.length
          ) {
            $scope.selectedContacts = $scope.isAllSelected.val
              ? _.first($scope.campaignRecipientList).TotalCount
              : 0;
          } else {
            $scope.selectedContacts = 0;
          }
          break;

        case 'pagination':
          $scope.selectedRecipientList.forEach(selected => {
            $scope.campaignRecipientList = $scope.campaignRecipientList.map(
              list => {
                const selectedID = selected.FamilyID
                  ? selected.FamilyID
                  : selected.ReferrerId;
                const listId = list.FamilyID ? list.FamilyID : list.ReferrerId;
                const selectedEmailAddress = selected.EmailAddress
                  ? selected.EmailAddress
                  : selected.Email;
                const listEmailAddress = list.EmailAddress
                  ? list.EmailAddress
                  : list.Email;
                return selectedID === listId &&
                  selectedEmailAddress === listEmailAddress
                  ? { ...list, selected: true }
                  : list;
              }
            );
          });

          if ($scope.isAllSelected.val) {
            if (
              $scope.campaignRecipientList &&
              $scope.campaignRecipientList.length
            ) {
              $scope.selectedContacts = _.first(
                $scope.campaignRecipientList
              ).TotalCount;
            } else {
              $scope.selectedContacts = 0;
            }
          } else {
            $scope.selectedContacts = _.size($scope.selectedRecipientList);
          }

          break;
        default:
      }

      $scope.emailCampaignRepeater = $scope.campaignRecipientList;
      if ($scope.campaignRecipientList && $scope.campaignRecipientList.length) {
        $scope.totalPagingCount = _.first(
          $scope.campaignRecipientList
        ).TotalCount;
      } else {
        $scope.totalPagingCount = 0;
      }

      if ($scope.campaignModel.filterBy === 'referrers') {
        $scope.tableParams = new NgTableParams(
          {
            page: 1,
            count: $scope.totalPagingCount,
            sorting: {
              ReferrerName: 'asc',
            },
          },
          {
            counts: [],
            total: $scope.totalPagingCount,
            getData($defer, params) {
              let filterData = $scope.emailCampaignRepeater;
              if ($scope.searchText) {
                const regExp = new RegExp($scope.searchText, 'i');
                filterData = $scope.emailCampaignRepeater.filter(obj => {
                  return (
                    obj.ReferrerName.match(regExp) ||
                    obj.Email.match(regExp) ||
                    obj.ReferrerOrgEntityLegalName.match(regExp) ||
                    obj.TotalNumberOfLeads.match(regExp)
                  );
                });
              }
              const orderedData = params.sorting()
                ? $filter('orderBy')(filterData, params.orderBy())
                : filterData;
              $defer.resolve(
                orderedData.slice(
                  (params.page() - 1) * params.count(),
                  params.page() * params.count()
                )
              );
            },
          }
        );
      }

      $scope.tableParams = new NgTableParams(
        {
          page: 1,
          count: $scope.totalPagingCount,
          sorting: {
            FamilyFullName: 'asc',
          },
        },
        {
          counts: [],
          total: $scope.totalPagingCount,
          getData($defer, params) {
            let filterData = $scope.emailCampaignRepeater;
            if (!_.isEmpty($scope.searchText)) {
              const regExp = new RegExp($scope.searchText, 'i');
              filterData = _.filter($scope.emailCampaignRepeater, obj => {
                return (
                  String(obj.FamilyFullName).match(regExp) ||
                  String(obj.City).match(regExp) ||
                  String(obj.ContactNumber).match(regExp) ||
                  String(obj.ContactType).match(regExp)
                );
              });
            }
            const orderedData = params.sorting()
              ? $filter('orderBy')(filterData, params.orderBy())
              : filterData;
            $defer.resolve(
              orderedData.slice(
                (params.page() - 1) * params.count(),
                params.page() * params.count()
              )
            );
          },
        }
      );

      $scope.inProgress = false;
    }

    function refreshResultsMethodAdviser() {
      $scope.inProgress = true;
      const reportingCountry = $scope.campaignModel.reportingCountrySelected
        .map(elem => {
          return elem.CountryId;
        })
        .join(',');

      const tempReportingState = $scope.campaignModel.reportingStateSelected
        .map(elem => {
          return elem.ReportingStateId;
        })
        .join(',');

      const tempReportingRegion = $scope.campaignModel.reportingRegionSelected
        .map(elem => {
          return elem.ReportingRegionId;
        })
        .join(',');

      const contactStatus = $scope.campaignModel.contactStatusSelected
        .map(elem => {
          return elem.BrokerStatusId;
        })
        .join(',');

      const categories = $scope.campaignModel.categorySelected
        .map(elem => {
          return elem.CategoryID;
        })
        .join(',');

      const enquirySource = $scope.campaignModel.enquirySourceSelected
        .map(elem => {
          return elem.EnquirySourceId;
        })
        .join(',');

      const clientType = $scope.campaignModel.clientTypeSelected
        .map(elem => {
          return elem.ClientTypeID;
        })
        .join(',');

      const contactType = $scope.campaignModel.contactTypeSelected
        .map(elem => {
          return elem.ContactTypeId;
        })
        .join(',');

      communicateService
        .getCampaignAdviser(
          parseInt(reportingCountry, 10) === -1 ? '' : reportingCountry,
          parseInt(tempReportingState, 10) === -1 ? '' : reportingState,
          parseInt(tempReportingRegion, 10) === -1 ? '' : reportingRegion,
          parseInt(contactStatus, 10) === -1 ? '' : contactStatus,
          parseInt(categories, 10) === -1 ? '' : categories,
          parseInt(enquirySource, 10) === -1 ? '' : enquirySource,
          parseInt(clientType, 10) === -1 ? '' : clientType,
          parseInt(contactType, 10) === -1 ? '' : contactType,
          $scope.currentPage,
          $scope.pageSize
        )
        .then(response => {
          $scope.campaignRecipientList = response.data;

          const currentPage = 1;
          if ($scope.isAllSelected.val) {
            $scope.campaignRecipientList = response.data;
            _.each($scope.campaignRecipientList, itm => {
              itm.selected = $scope.isAllSelected.val;
            });
            dataHandler(currentPage, 'toggleAll');
          }
        });
    }

    function tagSelect(action, obj) {
      switch (action) {
        default:
          _.each(obj, o => {
            o.selected = $scope.isAllSelected.val;
          });
          break;
      }

      return obj;
    }

    function getCampaignRecipients(
      location,
      postCode,
      ageFrom,
      ageTo,
      gender,
      loanStatus,
      loanRateType,
      lender,
      contactStatusFilter,
      categories,
      ratings,
      enquirySource,
      referralCategoryIds,
      adviser,
      currentPage,
      pageSize,
      action,
      dataObj
    ) {
      let currentPageValue = currentPage;

      switch (action) {
        case 'init':
          if ($scope.tempCurrentPage === 1) {
            currentPageValue = 1;
          }

          communicateService
            .campaignRecipientGet({
              location,
              postCode,
              ageFrom,
              ageTo,
              gender,
              loanStatus,
              loanRateType,
              lender,
              contactStatusFilter,
              categories,
              ratings,
              enquirySource,
              referralCategoryIds,
              adviser,
              currentPageValue,
              pageSize,
            })
            .then(response => {
              if ($scope.isAllSelected.val) {
                $scope.campaignRecipientList = response.data;
                if (
                  $scope.campaignRecipientList &&
                  $scope.campaignRecipientList.length
                ) {
                  $scope.selectedContacts = _.first(
                    $scope.campaignRecipientList
                  ).TotalCount;
                  $scope.allPageSize = _.first(
                    $scope.campaignRecipientList
                  ).TotalCount;
                } else {
                  $scope.selectedContacts = 0;
                }
                _.each($scope.campaignRecipientList, itm => {
                  itm.selected = $scope.isAllSelected.val;
                });

                $scope.selectedRecipientList = $scope.campaignRecipientList;

                dataHandler(currentPageValue, action);
              }
            });
          break;

        case 'pagination':
          communicateService
            .campaignRecipientGet({
              location,
              postCode,
              ageFrom,
              ageTo,
              gender,
              loanStatus,
              loanRateType,
              lender,
              contactStatus: contactStatusFilter,
              categories,
              rating: ratings,
              referralSource: enquirySource,
              referralCategoryIds,
              adviser,
              pageNumber: currentPageValue,
              pageSize,
            })
            .then(response => {
              $scope.campaignRecipientList = response.data;
              if ($scope.isAllSelected.val) {
                $scope.campaignRecipientList = tagSelect(
                  'all',
                  $scope.campaignRecipientList
                );
                if (
                  $scope.campaignRecipientList &&
                  $scope.campaignRecipientList.length
                ) {
                  $scope.selectedContacts = _.first(
                    $scope.campaignRecipientList
                  ).TotalCount;
                } else {
                  $scope.selectedContacts = 0;
                }
              } else {
                $scope.selectedContacts = _.size($scope.selectedRecipientList);
              }

              dataHandler(currentPageValue, action);
            });
          break;
        case 'toggleData':
          $scope.campaignRecipientList = $scope.campaignRecipientList.map(
            campaignRecipient => {
              const id = campaignRecipient.FamilyID
                ? campaignRecipient.FamilyID
                : campaignRecipient.ReferrerId;
              const objId = dataObj.FamilyID
                ? dataObj.FamilyID
                : dataObj.ReferrerId;
              if (id === objId) {
                const filter = $scope.selectedRecipientList.filter(selected => {
                  const selectedId = selected.FamilyID
                    ? selected.FamilyID
                    : selected.ReferrerId;
                  return selectedId === objId;
                });
                if (filter.length === 0) {
                  $scope.selectedRecipientList.push(campaignRecipient);
                } else {
                  const selected = $scope.selectedRecipientList.filter(s => {
                    const sId = s.FamilyID ? s.FamilyID : s.ReferrerId;
                    return sId !== id;
                  });
                  $scope.selectedRecipientList = selected;
                }
              }
              return campaignRecipient;
            }
          );

          $scope.selectedContacts = $scope.selectedRecipientList.length;
          dataHandler(currentPageValue, action);
          break;
        default:
          dataHandler(currentPageValue, action);
          break;
      }
    }

    function refreshResultsMethod(action, dataObj) {
      $scope.inProgress = true;

      const filterRefreshData = _.first($scope.filterRefreshData);

      switch (_.first($scope.filterRefreshData).contactStatus) {
        case '':
          break;

        default:
          break;
      }

      $scope.filterRefreshData[0] = filterRefreshData;
      let referralCategoryIds;
      let enquirySourceIds;
      if (filterRefreshData.enquirySource) {
        const enquirySourceCloneObj = Object.assign(
          [],
          filterRefreshData.enquirySource
        );
        referralCategoryIds = _.map(
          enquirySourceCloneObj,
          'ReferralCategoryId'
        );
        referralCategoryIds = referralCategoryIds
          ? referralCategoryIds.toString()
          : '';
        enquirySourceIds = _.map(enquirySourceCloneObj, 'EnquirySourceId');
        enquirySourceIds = enquirySourceIds ? enquirySourceIds.toString() : '';
      }
      getCampaignRecipients(
        filterRefreshData.location,
        filterRefreshData.postCode,
        filterRefreshData.ageFrom,
        filterRefreshData.ageTo,
        filterRefreshData.gender,
        filterRefreshData.loanStatus,
        filterRefreshData.loanRateType,
        filterRefreshData.lender,
        filterRefreshData.contactStatusFilter,
        filterRefreshData.categories,
        filterRefreshData.ratings,
        enquirySourceIds,
        referralCategoryIds,
        filterRefreshData.adviser,
        $scope.currentPage,
        $scope.pageSize,
        action,
        dataObj
      );
    }

    const refreshResultsReferrer = () => {
      $scope.inProgress = true;
      const emailCampaignParams = getEmailCampaignReferrersParams(
        $scope.campaignModel
      );
      const {
        userId,
        isActive,
        referrerOrgId,
        adviserOrgId,
        adviserId,
        recentLeadInDays,
        recentLeadToInDays,
      } = emailCampaignParams;
      communicateService
        .getEmailCampaignReferrers(
          userId,
          isActive,
          referrerOrgId,
          adviserOrgId,
          adviserId,
          recentLeadInDays,
          recentLeadToInDays,
          $scope.currentPage,
          $scope.pageSize
        )
        .then(response => {
          if (!response && !response.data) return;
          $scope.campaignRecipientList = response.data.Result;
          const currentPage = 1;
          if ($scope.isAllSelected.val) {
            $scope.campaignRecipientList = $scope.campaignRecipientList.map(
              campaignRecipient => ({
                ...campaignRecipient,
                selected: $scope.isAllSelected.val,
                TotalCount: response.data.Count,
              })
            );
            dataHandler(currentPage, 'toggleAll');
          }
        });
    };

    function refreshResults(value) {
      $scope.tempCurrentPage = 0;

      switch ($scope.campaignModel.filterBy) {
        case 'adviser':
          refreshResultsMethodAdviser();
          break;
        case 'referrers':
          refreshResultsReferrer();
          break;
        default:
          if (value === 'refresh' || value === 'init') {
            $scope.isAllSelected.val = true;
            $scope.tempCurrentPage = 1;
            refreshResultsMethod('init');
          } else if (value === 'pagination') {
            $scope.tempCurrentPage = $scope.currentPage;
            refreshResultsMethod('pagination');
          }
          break;
      }
    }

    function toggleAll(value) {
      $scope.isAllSelected.val = !value;
      refreshResultsMethod('toggleAll');
    }

    function optionToggled(data) {
      if (!data.selected) {
        $scope.isAllSelected.val = data.selected;
      }
      refreshResultsMethod('toggleData', data);
    }

    function contactInfo(id) {
      if (!$scope.campaignModel.isClientHidden) {
        if (typeof id !== 'undefined' && id > 0) {
          $state.go('app.contactsSingle', { familyId: id });
        } else {
          toaster.pop(
            'info',
            'Notice',
            'You may have selected a non MyCRM client'
          );
        }
      }
    }

    /** ****************************************************************** */

    $scope.$watch(
      () => {
        $scope.sidebarheight = $('#sidebar-1').height();
        return $scope.sidebarheight;
      },
      value => {
        $scope.sidebarheight = value + 135;
      },
      true
    );

    // METHODS
    // /////////////////
    angular.extend($scope, {
      getRandomBg,
      toggleAll,
      optionToggled,
      contactInfo,
      contactTypeLocalLang: getMultiSelectObj('Types'),
      countryLocalLang: getMultiSelectObj('Countries'),
      stateLocalLang: getMultiSelectObj('States'),
      regionLocalLang: getMultiSelectObj('Regions'),
      statusLocalLang: getMultiSelectObj('Status'),
      sourceLocalLang: getMultiSelectObj('Sources'),
      clientLocalLang: getMultiSelectObj('Clients'),
      catLocalLang: getMultiSelectObj('Categories'),
      genderLocalLang: getMultiSelectObj('Gender'),
      localLang: getMultiSelectObj('Loan Status'),
      lenderLocalLang: getMultiSelectObj('Lender'),
      categoryLocalLang: getMultiSelectObj('Category'),
      enquirySourceLocalLang: getMultiSelectObj('Enquiry Source'),
      adviserLocalLang: getMultiSelectObj('Adviser'),
      loanRateTypesLocalLang: getMultiSelectObj('Loan Rate Type'),
      showFilterVal,
      sortBy,
      removeLocationList,
      removePostCodeList,
      pushLocation,
      pushPostCode,
      onSelectAll,
      onItemSelect,
      onItemSelectLender,
      onSelectAllLender,
      onSelectAllCategory,
      onItemSelectCategory,
      onSelectAllRating,
      onItemSelectRating,
      onSelectAllEnquirySource,
      onItemSelectEnquirySource,
      onSelectAllAdviser,
      onItemSelectAdviser,
      refreshResults,
    });

    function init() {
      _.first($scope.filterRefreshData).contactStatus = 'Please Select Status';
      getUserInfo();
      getContactType();
      operatingCountriesGet();
      getBrokerStatusList();
      getContactReferrerStatus();
      adviserOrganizationListGet();
      reportingRegion();
      getEnquirySource();
      getClientType();
      getCategoryList();
      getLoanRateTypes();
      genderGet();
      checkMailChimp();

      communicateService.categoryListGet().then(response => {
        const temp = {
          CategoryID: -1,
          CategoryName: 'All Category',
          ticked: false,
        };
        response.data.unshift(temp);
        const tmp = [];
        response.data &&
          Object.keys(response.data).forEach(x => {
            const value = response.data[x];
            if (lastFilterCategory.indexOf('All') > -1) {
              value.ticked = parseInt(value.CategoryID, 10) === -1;
            } else if (
              lastFilterCategory.indexOf(response.data[x].CategoryName) > -1
            ) {
              value.ticked = true;
            }
            tmp.push(value);
          });
        $scope.categoriesList = tmp;
      });

      communicateService.lenderListGet(lenderPlace).then(response => {
        const temp = {
          ProviderId: -1,
          ProviderName: 'All Lender',
          ticked: false,
        };
        response.data.unshift(temp);
        const tmp = [];
        response.data &&
          Object.keys(response.data).forEach(x => {
            const value = response.data[x];
            if (lastFilterLender.indexOf('All') > -1) {
              value.ticked = parseInt(value.ProviderId, 10) === -1;
            } else if (
              lastFilterLender.indexOf(response.data[x].ProviderName) > -1
            ) {
              value.ticked = true;
            }
            tmp.push(value);
          });
        $scope.lenderList = tmp;
      });

      communicateService.ratingListGet().then(response => {
        const temp = {
          RatingID: -1,
          RatingName: 'All Rating',
          ticked: false,
        };
        response.data.unshift(temp);
        const tmp = [];
        response.data &&
          Object.keys(response.data).forEach(x => {
            const value = response.data[x];
            if (lastFilterRating.indexOf('All') > -1) {
              value.ticked = parseInt(value.RatingID, 10) === -1;
            } else if (
              lastFilterRating.indexOf(response.data[x].RatingName) > -1
            ) {
              value.ticked = true;
            }
            tmp.push(value);
          });
        $scope.ratingsList = tmp;
      });

      optionsService.getEnquirySourceList().then(response => {
        const tempEnquirySourceList =
          response.data &&
          response.data.reduce((accum, enquiry) => {
            let newAccum = accum;
            if (enquiry.EnquirySourceItem && enquiry.EnquirySourceItem.length) {
              const tempEnquiry = {
                EnquirySource: `<strong> ${
                  enquiry.ReferralCategoryName
                } </strong>`,
                msGroup: true,
              };
              accum.push(tempEnquiry);
              const tempList = _.map(enquiry.EnquirySourceItem, item => {
                return {
                  EnquirySourceId: item.ReferralItemId,
                  EnquirySource: item.ReferralItemName,
                  ReferralCategoryId: enquiry.ReferralCategoryId,
                  ReferralCategoryName: enquiry.ReferralCategoryName,
                };
              });
              newAccum = accum.concat(tempList);
              newAccum.push({ msGroup: false });
            }
            return newAccum;
          }, []);
        const temp = {
          EnquirySourceId: 0,
          EnquirySource: '<strong> All Enquiry Source </strong>',
          ticked: true,
          ReferralCategoryId: 0,
        };
        tempEnquirySourceList.unshift(temp);
        const tmp = [];
        tempEnquirySourceList &&
          Object.keys(tempEnquirySourceList).forEach(x => {
            const value = tempEnquirySourceList[x];
            if (lastFilterEnquirySource.indexOf('All') > -1) {
              value.ticked = parseInt(value.EnquirySourceId, 10) === 0;
            } else if (
              lastFilterEnquirySource.indexOf(
                tempEnquirySourceList[x].EnquirySource
              ) > -1
            ) {
              value.ticked = true;
            }
            tmp.push(value);
          });
        $scope.enquirySourceList = tmp;
      });

      communicateService.getAdvisersList().then(response => {
        const temp = {
          FamilyId: -1,
          FullName: 'All Adviser',
          ticked: false,
        };
        response.data.unshift(temp);
        const tmp = [];
        response.data &&
          Object.keys(response.data).forEach(x => {
            const value = response.data[x];
            if (lastFilterAdviser.indexOf('All') > -1) {
              value.ticked = parseInt(value.FamilyId, 10) === -1;
            } else if (
              lastFilterAdviser.indexOf(response.data[x].FullName) > -1
            ) {
              value.ticked = true;
            }
            tmp.push(value);
          });
        $scope.adviserList = tmp;
        refreshResults('init');
      });

      communicateService.loanStatusListGet().then(response => {
        const temp = {
          LoanStatusID: -1,
          LoanStatusName: 'All Loan Status',
          ticked: false,
        };
        response.data.unshift(temp);
        const tmp = [];
        response.data &&
          Object.keys(response.data).forEach(x => {
            const value = response.data[x];
            if (lastFilter.indexOf('All') > -1) {
              value.ticked = parseInt(value.LoanStatusID, 10) === -1;
            } else if (
              lastFilter.indexOf(response.data[x].LoanStatusName) > -1
            ) {
              value.ticked = true;
            }
            tmp.push(value);
          });
        $scope.loanStatusList = tmp;
      });
    }

    init();
  });

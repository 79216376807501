import template from './insurancePipelineCard.html';
import controller from './insurancePipelineCardCtrl.js';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    quoteId: '<',
    statusId: '<',
    clientId: '<',
    clientName: '<',
    providerId: '<',
    providerName: '<',
    broker: '<',
    countryCode: '<',
    policyNumber: '<',
    annualPremium: '<',
    benefits: '<',
    labels: '<',
    labelsSelection: '<',
    lastDateUpdated: '<',
    isCompact: '<',
    onAddLabel: '&',
    onUpdateLabel: '&',
    onDeleteLabel: '&',
    onAddLabelToCard: '&',
    onDeleteLabelFromCard: '&',
    onViewWorkbench: '&',
  },
};

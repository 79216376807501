import moment from 'moment';
import { AUSTRALIAN_STATE } from 'Common/constants/australianState';
import { isAlphaNumeric } from 'Common/utilities/string';
import { getShortDatePattern } from 'Common/utilities/date';
import { addressToVM, getCurrentAddress } from 'Common/utilities/address';
import {
  creditCheckVMToPostBody,
  clientToCreditCheckVM,
} from 'Common/utilities/creditChecks';

const DRIVERS_LICENCSE_VALIDATION = {
  MIN_LENGTH: 4,
  MAX_LENGTH: 9,
};

const CONTACT_TYPES = {
  EXISTING: 'existing',
  NON_MYCRM: 'nonMyCRM',
};

class SetCreditCheckModalCtrl {
  constructor(creditChecksService, generalService) {
    'ngInject';

    this.creditChecksService = creditChecksService;
    this.generalService = generalService;
  }

  $onInit() {
    this.states = AUSTRALIAN_STATE;
    this.contactTypes = CONTACT_TYPES;
    this.minAge = 15;

    this.getShortDatePattern = getShortDatePattern;

    this.creditCheck = { contactType: CONTACT_TYPES.EXISTING };
    this.contactType = CONTACT_TYPES.EXISTING;

    this.initDatePicker();
    this.validateDOB();
    this.validateDriversLicense();
  }

  initDatePicker() {
    const maxDOB = moment()
      .subtract(this.minAge, 'years')
      .toDate();
    this.dateSettings = {
      altInputFormats: ['d!/M!/yyyy', 'd!M!yyyy'],
      initDate: maxDOB,
      maxDate: maxDOB,
    };
  }

  onContactSelectorInitialized(api) {
    this.contactSelector = api;
    if (this.selectedContact) {
      this.contactSelector.setSelectedContact(this.selectedContact);
    }
  }

  onClientSelected(client) {
    if (!client) return;

    this.selectedClient = client;
    this.creditCheck = {
      ...this.creditCheck,
      ...clientToCreditCheckVM(client),
    };
    this.setDOB(this.creditCheck.dateOfBirth);
    this.validateDOB();

    if (client.Address && client.Address.length) {
      const currentAddress = getCurrentAddress(client.Address);
      this.onAddressSelected(currentAddress);
    } else {
      this.clearAddress();
    }
  }

  clearAddress() {
    const {
      route,
      streetAddress,
      streetNumber,
      suburb,
      state,
      postCode,
      streetName,
      ...cleanCreditCheck
    } = this.creditCheck;
    this.creditCheck = cleanCreditCheck;
  }

  onAddressSelected(address) {
    if (address && address.formatted_address) {
      this.generalService
        .geocodeSearch(address.formatted_address)
        .then(response => {
          if (!response || !response.data) return;

          const {
            route,
            streetAddress,
            streetNumber,
            locality: suburb,
            stateShortName: state,
            postCode,
          } = addressToVM(response.data);
          this.creditCheck = {
            ...this.creditCheck,
            streetNumber,
            suburb,
            state,
            postCode,
          };
          this.creditCheck.streetName = streetAddress || route;
        });
    }
  }

  toggleDatePicker() {
    this.dateIsOpen = !this.dateIsOpen;
  }

  toggleSearchAddress() {
    this.searchAddressShown = !this.searchAddressShown;
  }

  setDOB(dob) {
    if (dob) {
      this.dob = moment(dob).toDate();
    }
  }

  setContactType(type) {
    if (this.creditCheck.contactType === type) return;

    const currentCreditCheck = { ...this.creditCheck };
    if (this.prevCreditCheck) {
      const { clientId, firstName, lastName } = this.prevCreditCheck;
      this.creditCheck = { ...this.creditCheck, clientId, firstName, lastName };
    } else {
      if (type === CONTACT_TYPES.NON_MYCRM) {
        this.creditCheck.clientId = 0;
      }
      this.creditCheck.firstName = null;
      this.creditCheck.lastName = null;
    }
    this.prevCreditCheck = currentCreditCheck;
    this.creditCheck.contactType = type;
  }

  isValidCreditCheck() {
    if (!this.hasSelectedClient()) return false;

    const isValidGender = !!this.creditCheck.gender;
    return (
      isValidGender &&
      this.isValidDOB &&
      this.isValidAddress() &&
      this.isValidDriversLicense
    );
  }

  isValidAddress() {
    const { streetName, suburb } = this.creditCheck;
    const isValidStreetName = streetName && streetName.trim();
    const isValidSuburb = suburb && suburb.trim();
    return isValidStreetName && isValidSuburb;
  }

  hasSelectedClient() {
    let hasSelectedClient;
    if (this.creditCheck.contactType === CONTACT_TYPES.EXISTING) {
      const isValidClientId = this.creditCheck.clientId;
      hasSelectedClient = isValidClientId;
    } else {
      const { firstName, lastName } = this.creditCheck;
      const isValidFirstName = firstName && firstName.trim();
      const isValidLastName = lastName && lastName.trim();
      hasSelectedClient = isValidFirstName && isValidLastName;
    }
    return hasSelectedClient;
  }

  validateDOB() {
    const date = this.dob && moment(this.dob);
    const isValidDate = date && date.isValid();
    if (!isValidDate) {
      this.isValidDOB = false;
      return;
    }

    const contactAge = moment().diff(date, 'year');
    this.isValidDOB = contactAge >= this.minAge;
  }

  validateDriversLicense() {
    const { driversLicense } = this.creditCheck;
    const isEmptyDriversLicense = !driversLicense;
    const isValidFormat = isAlphaNumeric(
      driversLicense,
      DRIVERS_LICENCSE_VALIDATION.MIN_LENGTH,
      DRIVERS_LICENCSE_VALIDATION.MAX_LENGTH
    );
    this.isValidDriversLicense = isEmptyDriversLicense || isValidFormat;
  }

  save() {
    if (!this.isValidCreditCheck()) return;

    this.isSubmitting = true;
    this.creditCheck.dateOfBirth = this.getFormattedDOB();
    this.creditChecksService
      .setCreditChecks(creditCheckVMToPostBody(this.creditCheck))
      .then(
        response => {
          this.isSubmitting = false;
          this.modalInstance.close(response.data);
        },
        () => {
          this.isSubmitting = false;
        }
      );
  }

  getFormattedDOB() {
    const momentDOB = moment(this.dob);
    const formattedDOB = `${momentDOB.format('YYYY-MM-DDT00:00:00.000')}Z`;
    return formattedDOB;
  }

  close() {
    this.modalInstance.dismiss();
  }
}

export default SetCreditCheckModalCtrl;

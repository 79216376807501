import angular from 'angular';
import { MODAL_SECTION } from 'Common/constants/generateReports';
import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import { COUNTRY_TYPE } from 'Common/constants/countryType';

angular
  .module('app')
  .controller('RecommendationReportCtrl', function RecommendationReportCtrl(
    $rootScope,
    $scope,
    $uibModal,
    uiService,
    loanAppSharedData,
    configService
  ) {
    $scope.disableCQPModal = true;

    $scope.$on('isAdviserSubscription', () => {
      $scope.disableCQPModal = false;
    });
    const activeModalSection = uiService.isCountry('New Zealand')
      ? MODAL_SECTION.CPA
      : MODAL_SECTION.OPTION_WINDOW;
    const openRecommendationModal = () => {
      if ($scope.disableCQPModal) return;
      const { userSubscription, esign } = configService.feature;
      const multiLoanCpaIds = null;
      const { CountryID: countryId } = loanAppSharedData.LoanApplicationDetails;
      if (userSubscription && esign && countryId === COUNTRY_TYPE.AUSTRALIA) {
        const { adviserSubscription } = loanAppSharedData;
        $scope.eSignSubscription =
          adviserSubscription &&
          adviserSubscription.find(
            obj => obj.subscriptionProductId === SUBSCRIPTIONTYPE.E_SIGN
          );
      } else {
        $scope.eSignSubscription = {};
      }
      const modalInstance = $uibModal.open({
        templateUrl:
          '/assets/views/loanApplication/sidebar/modal/recommendationReport.html',
        controller: 'RecommendationModalCtrl',
        windowClass: 'mc-recommendation-report-modal',
        size: 'md',
        scope: $scope,
        resolve: {
          activeModalSection: () => activeModalSection,
          eSignSubscription: () => $scope.eSignSubscription,
          multiLoanCpaIds: () => multiLoanCpaIds,
        },
      });
      modalInstance.result.then(response => {
        if (!response || !response.closeAll) return;
        $rootScope.$broadcast('eSignPackageSent', response.closeAll);
      });
    };

    const isUserNZ = () => {
      return uiService.isCountry('New Zealand');
    };
    angular.extend($scope, {
      openRecommendationModal,
      isUserNZ,
    });
  });

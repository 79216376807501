import moment from 'moment';
import {
  toastSaveSuccess,
  toastSaveDelete,
  toastError,
  showActionConfirmation,
} from 'Common/utilities/alert';

import {
  INPUT_DATE_FORMAT,
  ALT_INPUT_FORMATS,
  DATE_OPTIONS,
} from 'Common/constants/workbench';

export function mapBenefitDetailsStatus(benefitDetails, statuses) {
  return (
    statuses &&
    benefitDetails &&
    benefitDetails.map(detail => {
      const status = statuses.find(stat => stat.name === detail.status);
      return {
        ...detail,
        statusId: status ? status.id : 0,
      };
    })
  );
}

export function updateBenefitDetailInList(updatedBenefitDetail, list) {
  if (!updatedBenefitDetail || !list) return list;
  const indexOfBenefitCover = list.findIndex(
    detail => detail.id === updatedBenefitDetail.id
  );
  return [
    ...list.slice(0, indexOfBenefitCover),
    { ...updatedBenefitDetail },
    ...list.slice(indexOfBenefitCover + 1),
  ];
}

export default class InsuranceAppCoverDetailsCtrl {
  constructor(insurancePipelineService) {
    'ngInject';

    this.insurancePipelineService = insurancePipelineService;
    this.INPUT_DATE_FORMAT = INPUT_DATE_FORMAT;
    this.ALT_INPUT_FORMATS = ALT_INPUT_FORMATS;
    this.DATE_OPTIONS = DATE_OPTIONS;
  }

  $onInit() {
    this.loadStatuses().then(() => this.loadCoverDetails());

    this.onInit &&
      this.onInit({
        api: {
          refresh: () => this.loadCoverDetails(),
        },
      });
  }

  loadStatuses() {
    return this.insurancePipelineService
      .getInsurancePipelineStatus(false)
      .then(response => {
        this.statuses = response;
        return response;
      });
  }

  loadCoverDetails() {
    this.insurancePipelineService
      .getInsuranceAppCoverDetails(this.quoteId)
      .then(response => {
        if (!response) return;
        this.coverDetails = {
          ...response,
          benefitDetails: mapBenefitDetailsStatus(
            response.benefitDetails,
            this.statuses
          ),
        };
      });
  }

  openDate(field) {
    switch (field) {
      case 'dateStarted':
        this.dateStartedIsOpen = !this.dateStartedIsOpen;
        break;
      case 'dateSubmitted':
        this.dateSubmittedIsOpen = !this.dateSubmittedIsOpen;
        break;
      default:
    }
  }

  toggleEditBenefitCover(id) {
    const { benefitDetails } = this.coverDetails;
    const benefitCover = benefitDetails.find(detail => detail.id === id);
    benefitCover.isEditMode = !benefitCover.isEditMode;
    if (benefitCover.isEditMode) {
      benefitCover.copy = { ...benefitCover };
    } else if (benefitCover.copy) {
      const originalBenefitCover = {
        ...benefitCover.copy,
        isEditMode: benefitCover.isEditMode,
      };
      this.coverDetails.benefitDetails = updateBenefitDetailInList(
        originalBenefitCover,
        benefitDetails
      );
    }
  }

  saveBenefitCover(id) {
    const { benefitDetails } = this.coverDetails;
    const benefitCover = benefitDetails.find(detail => detail.id === id);
    if (!benefitCover) return;

    const { statusId, policyNumber } = benefitCover;
    this.insurancePipelineService
      .saveInsuranceAppBenefitCover(id, { statusId, policyNumber })
      .then(() => {
        benefitCover.copy = null;
        this.toggleEditBenefitCover(id);
        this.onUpdated && this.onUpdated();
        toastSaveSuccess();
      })
      .catch(toastError);
  }

  deleteBenefitCover(id) {
    showActionConfirmation(
      'Are you sure you want to delete the benefit cover?',
      'You will not be able to retrieve this later.',
      confirm => {
        if (!confirm) return;
        this.insurancePipelineService
          .deleteInsuranceAppBenefitCover(id)
          .then(() => {
            toastSaveDelete();
            const { benefitDetails } = this.coverDetails;
            const indexOfDeletedCover = benefitDetails.findIndex(
              detail => detail.id === id
            );
            this.coverDetails.benefitDetails = [
              ...benefitDetails.slice(0, indexOfDeletedCover),
              ...benefitDetails.slice(indexOfDeletedCover + 1),
            ];
            this.onUpdated && this.onUpdated();
          })
          .catch(toastError);
      }
    );
  }

  onDateChange() {
    const { dateSubmitted, dateStarted } = this.coverDetails;
    const params = {
      dateSubmitted:
        dateSubmitted && moment(dateSubmitted).format('YYYY-MM-DD'),
      dateStarted: dateStarted && moment(dateStarted).format('YYYY-MM-DD'),
    };
    this.insurancePipelineService
      .saveInsuranceAppCoverDetails(this.quoteId, params)
      .then(() => toastSaveSuccess())
      .catch(toastError);
  }

  onSelectStatus(benefitCoverId, statusId) {
    const { benefitDetails } = this.coverDetails;
    const benefitCover = benefitDetails.find(
      detail => detail.id === benefitCoverId
    );
    const status = this.statuses.find(stat => stat.id === statusId);
    benefitCover.status = status.name;
  }
}

import template from './opportunityNotesModal.html';
import controller from './opportunityNotesModalCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    modalInstance: '<',
    noteObj: '<',
    isAdd: '<',
    referralId: '<',
    isSmsEnabled: '<',
    isNotOpportunityModule: '@',
  },
};

import swal from 'sweetalert';
import toastr from 'toastr';
import toastrConfig from 'Common/config/toastr';

const alertError = (message, autoClose, title) => {
  swal({
    title,
    text: message,
    type: 'error',
    timer: autoClose ? 2000 : null,
    showConfirmButton: !autoClose,
  });
};

export const alertSucess = (alertTitle, message, autoClose) => {
  swal({
    title: alertTitle || 'Success',
    text: message,
    type: 'success',
    timer: autoClose ? 2000 : null,
    showConfirmButton: !autoClose,
  });
};

export const displayError = (
  e,
  autoClose = true,
  title = 'Yikes! Something is wrong'
) => {
  const messages = e && e.messages ? e.messages : ['Please try again.'];
  if (typeof e === 'string') return alertError(e, autoClose, title);
  return alertError(messages.join('\n'), autoClose, title);
};

const alertInfo = (message, autoClose) => {
  swal({
    title: 'Just a heads up',
    text: message,
    type: 'info',
    timer: autoClose ? 2000 : null,
    showConfirmButton: !autoClose,
  });
};

export const displayInfo = (e, autoClose = false) => {
  const messages = e && e.messages ? e.messages : ['Please try again.'];
  if (typeof e === 'string') return alertInfo(e, autoClose);
  return alertInfo(messages.join('\n'), autoClose);
};

export const displayWarning = (message, alertTitle, autoClose = true) => {
  swal({
    title: alertTitle,
    text: message || 'Warning',
    type: 'warning',
    timer: autoClose ? 2000 : null,
    showConfirmButton: !autoClose,
  });
};

export const toastError = e => {
  toastr.options = toastrConfig;
  const messages = e && e.messages ? e.messages : ['Yikes! Something is wrong'];
  if (typeof e === 'string') return toastr.error(e, 'Error');
  return messages.forEach(message => toastr.error(message, 'Error'));
};

export const toastSaveSuccess = toasterService => {
  toastr.options = toastrConfig;
  if (toasterService)
    toasterService.pop('success', 'Success', 'Successfully Saved!');
  else toastr.success('Successfully Saved!', 'Success');
};

export const toastSaveDelete = toasterService => {
  toastr.options = toastrConfig;
  if (toasterService)
    toasterService.pop('success', 'Success', 'Successfully Deleted!');
  else toastr.success('Successfully Deleted!', 'Success');
};

export const showActionConfirmation = (
  confirmationTitle,
  confirmationMessage,
  onActionConfirmed
) => {
  swal(
    {
      title: confirmationTitle,
      text: confirmationMessage,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes',
      closeOnConfirm: true,
    },
    onActionConfirmed
  );
};

export const toastSuccess = e => {
  toastr.options = toastrConfig;
  const messages = e && e.messages ? e.messages : ['Operation successful'];
  if (typeof e === 'string') return toastr.success(e, 'Success');
  return messages.forEach(message => toastr.success(message, 'Success'));
};

import angular from 'angular';

angular.module('app').factory('loanScenarioService', [
  '$q',
  'LoanScenarioModel',
  function($q, LoanScenarioModel) {
    var factory = this;

    var loanScenario = new LoanScenarioModel();

    factory.LoanAppProviderWorksheetGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.LoanAppProviderWorksheetGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.FactFindDocumentGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.FactFindDocumentGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.loanInsuranceGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.loanInsuranceGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.loanInsuranceSet = function(data) {
      var defer = $q.defer();
      loanScenario.loanInsuranceSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.loanDeclarationGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.loanDeclarationGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.loanDeclarationSet = function(data) {
      var defer = $q.defer();
      loanScenario.loanDeclarationSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.RecommendationReportStatusGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.RecommendationReportStatusGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.RecommendationReportStatus = function(data) {
      var defer = $q.defer();
      loanScenario.RecommendationReportStatus(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.cpaPageStatusGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.cpaPageStatusGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.cpaPageStatus = function(data) {
      var defer = $q.defer();
      loanScenario.cpaPageStatus(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.cpaDocumentGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.cpaDocumentGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.cqpDocumentGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.cqpDocumentGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.loanDetailsGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.loanDetailsGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.loanDetailsSet = function(data) {
      var defer = $q.defer();
      loanScenario.loanDetailsSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.loanDepositGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.loanDepositGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.loanDepositSet = function(data) {
      var defer = $q.defer();
      loanScenario.loanDepositSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.loanFacilityGet = function(loanApplicationId, loanFacilityId) {
      var defer = $q.defer();
      loanScenario.loanFacilityGet(
        loanApplicationId,
        loanFacilityId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.loanFacilitySet = function(data) {
      var defer = $q.defer();
      loanScenario.loanFacilitySet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.loanFacilityDelete = function(loanScenarioId, LoanFacilityId) {
      var defer = $q.defer();
      loanScenario.loanFacilityDelete(
        loanScenarioId,
        LoanFacilityId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.contactLoanClientDelete = function(loandSecurityInfoID) {
      var defer = $q.defer();
      loanScenario.contactLoanClientDelete(
        loandSecurityInfoID,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.familyDeclarationsGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.familyDeclarationsGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.familyDeclarationsSet = function(data) {
      var defer = $q.defer();
      loanScenario.familyDeclarationsSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getLoanApplicationCQPFeeStructure = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.getLoanApplicationCQPFeeStructure(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setLoanApplicationCQPFeeStructure = function(structure) {
      var defer = $q.defer();
      loanScenario.setLoanApplicationCQPFeeStructure(
        structure,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.deleteLoanApplicationCQPFeeStructure = function(
      loanApplicationId,
      LoanApplicationCQPFeeId
    ) {
      var defer = $q.defer();
      loanScenario.deleteLoanApplicationCQPFeeStructure(
        loanApplicationId,
        LoanApplicationCQPFeeId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getLoanApplicationCQPReferralFeeStructure = function(
      loanApplicationId
    ) {
      var defer = $q.defer();
      loanScenario.getLoanApplicationCQPReferralFeeStructure(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setLoanApplicationCQPReferralFeeStructure = function(structure) {
      var defer = $q.defer();
      loanScenario.setLoanApplicationCQPReferralFeeStructure(
        structure,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.deleteLoanApplicationCQPReferralFeeStructure = function(
      loanApplicationId,
      LoanApplicationCQPFeeId
    ) {
      var defer = $q.defer();
      loanScenario.deleteLoanApplicationCQPReferralFeeStructure(
        loanApplicationId,
        LoanApplicationCQPFeeId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getLoanApplicationCQP = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.getLoanApplicationCQP(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setLoanApplicationCQP = function(loanApplicationId, lenderId) {
      var defer = $q.defer();
      loanScenario.setLoanApplicationCQP(
        loanApplicationId,
        lenderId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.CQPCommissionInfoGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.CQPCommissionInfoGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.CPADeclarationsGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.CPADeclarationsGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.CPADeclarationsSet = function(data) {
      var defer = $q.defer();
      loanScenario.CPADeclarationsSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.referrerInfoGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.referrerInfoGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.referrerInfoSet = function(data) {
      var defer = $q.defer();
      loanScenario.referrerInfoSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.lenderSubmissionGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.lenderSubmissionGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.lenderSubmissionSet = function(data) {
      var defer = $q.defer();
      loanScenario.lenderSubmissionSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.loanSubmissionBrokerNotesGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.loanSubmissionBrokerNotesGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.loanSubmissionBrokerNotesSet = function(data) {
      var defer = $q.defer();
      loanScenario.loanSubmissionBrokerNotesSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.brokerNotesGet = function(loanAppId) {
      var defer = $q.defer();
      loanScenario.brokerNotesGet(
        loanAppId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.brokerNotesSet = function(loanAppId, sectId, content) {
      var defer = $q.defer();
      loanScenario.brokerNotesSet(
        loanAppId,
        sectId,
        content,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.lenderBranchDetailGet = function(
      loanApplicationId,
      lenderBranchId
    ) {
      var defer = $q.defer();
      loanScenario.lenderBranchDetailGet(
        loanApplicationId,
        lenderBranchId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.scenarioListGet = function(familyID, searchString) {
      var defer = $q.defer();
      loanScenario.scenarioListGet(
        familyID,
        searchString,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.scenarioCompletionStatus = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.scenarioCompletionStatus(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.FinancialsSummaryGet = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.FinancialsSummaryGet(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.scenarioDetailsGet = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.scenarioDetailsGet(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.scenarioSet = function(data) {
      var defer = $q.defer();
      loanScenario.scenarioSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.scenarioDelete = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.scenarioDelete(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.scenarioClientListGet = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.scenarioClientListGet(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.scenarioApplicantListGet = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.scenarioApplicantListGet(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.scenarioSearchClientGet = function(
      searchString,
      partyType,
      loanApplicationID,
      loanID
    ) {
      var defer = $q.defer();
      loanScenario.scenarioSearchClientGet(
        searchString,
        partyType,
        loanApplicationID,
        loanID,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.scenarioApplicantSet = function(postData) {
      var defer = $q.defer();
      loanScenario.scenarioApplicantSet(
        postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.contactLoanClientSet = function(
      loanId,
      clientEntityId,
      IsClient,
      IsGuarantor,
      IsAddKid,
      IsApplicant,
      IsSolicitor,
      IsAccountant
    ) {
      var defer = $q.defer();
      loanScenario.contactLoanClientSet(
        loanId,
        clientEntityId,
        IsClient,
        IsGuarantor,
        IsAddKid,
        IsApplicant,
        IsSolicitor,
        IsAccountant,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.scenarioClientDelete = function(
      loanScenarioId,
      clientEntityId,
      isClient
    ) {
      var defer = $q.defer();
      loanScenario.scenarioClientDelete(
        loanScenarioId,
        clientEntityId,
        isClient,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.availableDocumentListGet = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.availableDocumentListGet(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.documentPageCountGet = function(documentId) {
      var defer = $q.defer();
      loanScenario.documentPageCountGet(
        documentId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.documentListGet = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.documentListGet(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.documentListGetLenderWorksheet = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.documentListGetLenderWorksheet(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.documentDetailsGet = function(
      loanScenarioId,
      documentId,
      thumbWidth
    ) {
      var defer = $q.defer();
      loanScenario.documentDetailsGet(
        loanScenarioId,
        documentId,
        thumbWidth,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.documentDelete = function(
      loanScenarioId,
      documentId,
      loanScenarioDocumentId
    ) {
      var defer = $q.defer();
      loanScenario.documentDelete(
        loanScenarioId,
        documentId,
        loanScenarioDocumentId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.documentPageSet = function(postData) {
      var defer = $q.defer();
      loanScenario.documentPageSet(
        postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.documentUpload = function($postData) {
      var defer = $q.defer();
      loanScenario.documentUpload(
        $postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.documentSet = function($postData) {
      var defer = $q.defer();
      loanScenario.documentSet(
        $postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    // Need Analysis Tab

    factory.getNeedsAnalysis = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.getNeedsAnalysis(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setNeedsAnalysis = function($postdata) {
      var defer = $q.defer();
      loanScenario.setNeedsAnalysis(
        $postdata,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getNewBrokerEventForScenario = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.getNewBrokerEventForScenario(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getFundingCalculatorNewBrokerEventForScenario = function(
      loanScenarioId
    ) {
      var defer = $q.defer();
      loanScenario.getFundingCalculatorNewBrokerEventForScenario(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getLoanScenarioNeedsAnalysisLoanPurpose = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.getLoanScenarioNeedsAnalysisLoanPurpose(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    /*
    * Get the Borrowers
    * */

    factory.BorrowersDetailsGet = function(familyID) {
      var defer = $q.defer();
      loanScenario.BorrowersDetailsGet(
        familyID,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    /**
     * Assets
     * */
    factory.AssetInfoGet = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.AssetInfoGet(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.AssetDetailsGet = function(familyId, loanScenarioId, assetId) {
      var defer = $q.defer();
      loanScenario.AssetDetailsGet(
        familyId,
        loanScenarioId,
        assetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.AssetClientGet = function(familyId, loanScenarioId, assetId) {
      var defer = $q.defer();
      loanScenario.AssetClientGet(
        familyId,
        loanScenarioId,
        assetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.AssetTypeGet = function() {
      var defer = $q.defer();
      loanScenario.AssetTypeGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.AssetInfoDelete = function(loanScenarioId, assetId) {
      var defer = $q.defer();
      loanScenario.AssetInfoDelete(
        loanScenarioId,
        assetId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.AssetDetailsSet = function(data) {
      var defer = $q.defer();
      loanScenario.AssetDetailsSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    /**
     * Liabilities
     * */
    factory.LiabilityInfoGet = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.LiabilityInfoGet(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.LiabilityDetailsGet = function(
      familyId,
      loanScenarioId,
      liabilityId
    ) {
      var defer = $q.defer();
      loanScenario.LiabilityDetailsGet(
        familyId,
        loanScenarioId,
        liabilityId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.LiabilityClientGet = function(
      familyId,
      loanScenarioId,
      liabilityId
    ) {
      var defer = $q.defer();
      loanScenario.LiabilityClientGet(
        familyId,
        loanScenarioId,
        liabilityId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.LiabilityTypeGet = function() {
      var defer = $q.defer();
      loanScenario.LiabilityTypeGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.subLiabilityTypeGet = function(liabilityId) {
      var defer = $q.defer();
      loanScenario.subLiabilityTypeGet(
        liabilityId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.LiabilityInfoDelete = function(loanScenarioId, liabilityId) {
      var defer = $q.defer();
      loanScenario.LiabilityInfoDelete(
        loanScenarioId,
        liabilityId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.LiabilityDetailsSet = function(data) {
      var defer = $q.defer();
      loanScenario.LiabilityDetailsSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    /**
     * Income
     * */
    factory.IncomeInfoGet = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.IncomeInfoGet(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.IncomeDetailsGet = function(familyId, loanScenarioId, incomeId) {
      var defer = $q.defer();
      loanScenario.IncomeDetailsGet(
        familyId,
        loanScenarioId,
        incomeId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.IncomeClientGet = function(familyId, loanScenarioId, incomeId) {
      var defer = $q.defer();
      loanScenario.IncomeClientGet(
        familyId,
        loanScenarioId,
        incomeId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.IncomeTypeGet = function() {
      var defer = $q.defer();
      loanScenario.IncomeTypeGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.IncomeInfoDelete = function(loanScenarioId, incomeId) {
      var defer = $q.defer();
      loanScenario.IncomeInfoDelete(
        loanScenarioId,
        incomeId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.IncomeDetailsSet = function(data) {
      var defer = $q.defer();
      loanScenario.IncomeDetailsSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    /**
     * Expense
     * */
    factory.ExpenseInfoGet = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.ExpenseInfoGet(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.ExpenseDetailsGet = function(familyId, loanScenarioId, expenseId) {
      var defer = $q.defer();
      loanScenario.ExpenseDetailsGet(
        familyId,
        loanScenarioId,
        expenseId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.ExpenseClientGet = function(familyId, loanScenarioId, expenseId) {
      var defer = $q.defer();
      loanScenario.ExpenseClientGet(
        familyId,
        loanScenarioId,
        expenseId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.ExpenseTypeGet = function() {
      var defer = $q.defer();
      loanScenario.ExpenseTypeGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.ExpenseInfoDelete = function(loanScenarioId, expenseId) {
      var defer = $q.defer();
      loanScenario.ExpenseInfoDelete(
        loanScenarioId,
        expenseId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.ExpenseDetailsSet = function(data) {
      var defer = $q.defer();
      loanScenario.ExpenseDetailsSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    // Employment tab
    factory.LoanScenarioEmploymentGet = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.LoanScenarioEmploymentGet(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    // new  version
    factory.EmploymentGet = function(loanScenarioId, employmentId) {
      var defer = $q.defer();
      loanScenario.EmploymentGet(
        loanScenarioId,
        employmentId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.EmploymentDelete = function(employmentId) {
      var defer = $q.defer();
      loanScenario.EmploymentDelete(
        employmentId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.CountryListGet = function() {
      var defer = $q.defer();
      loanScenario.CountryListGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.LoanScenarioIdentificationDocumentsSet = function(data) {
      var defer = $q.defer();
      loanScenario.LoanScenarioIdentificationDocumentsSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.LoanScenarioIdentificationDocumentsGet = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.LoanScenarioIdentificationDocumentsGet(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.EmploymentRoleRoleName = function(criteria) {
      var defer = $q.defer();
      loanScenario.EmploymentRoleRoleName(
        criteria,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.LoanScenarioEmploymentSet = function(data) {
      var defer = $q.defer();
      loanScenario.LoanScenarioEmploymentSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    // new version
    factory.EmploymentSet = function(data) {
      var defer = $q.defer();
      loanScenario.EmploymentSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.LoanScenarioIdentificationDocumentsTypeget = function() {
      var defer = $q.defer();
      loanScenario.LoanScenarioIdentificationDocumentsTypeget(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.EmploymentTypesGet = function() {
      var defer = $q.defer();
      loanScenario.EmploymentTypesGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.EmploymentTypeGet = function() {
      var defer = $q.defer();
      loanScenario.EmploymentTypeGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.EmployerTypeGet = function() {
      var defer = $q.defer();
      loanScenario.EmployerTypeGet(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.IdentificationSet = function(data) {
      var defer = $q.defer();
      loanScenario.IdentificationSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.IdentificationGet = function(loanApplicationId, identificationId) {
      var defer = $q.defer();
      loanScenario.IdentificationGet(
        loanApplicationId,
        identificationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.IdentificationDelete = function(identificationId) {
      var defer = $q.defer();
      loanScenario.IdentificationDelete(
        identificationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.FamilyIdentityStatusSet = function(data) {
      var defer = $q.defer();
      loanScenario.FamilyIdentityStatusSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.FamilyIdentityStatusGet = function(loanApplicationId, familyId) {
      var defer = $q.defer();
      loanScenario.FamilyIdentityStatusGet(
        loanApplicationId,
        familyId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    // Insurance Tab

    /* factory.InsuranceSet = function (data) {
        var defer = $q.defer();
        loanScenario.InsuranceSet(data, function (response) {
            factory.data = response.data;
            return defer.resolve(response);
        }, function (error) {
            factory.data = [];
            return defer.reject(error);
        });
        return defer.promise;
    }; */
    // factory.InsuranceGet = function (loanScenarioId) {
    //     var defer = $q.defer();
    //     loanScenario.InsuranceGet(loanScenarioId,function (response) {
    //         factory.data = response.data;
    //         return defer.resolve(response);
    //     }, function (error) {
    //         factory.data = [];
    //         return defer.reject(error);
    //     });
    //     return defer.promise;
    // };
    // Address/Security Tab

    factory.getLoanScenarioSecurityInfo = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.getLoanScenarioSecurityInfo(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getLoanScenarioSecurityInfo = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.getLoanScenarioSecurityInfo(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getPropertyStatus = function() {
      var defer = $q.defer();
      loanScenario.getPropertyStatus(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getAddress = function(id) {
      var defer = $q.defer();
      loanScenario.getAddress(
        id,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setAddress = function(data) {
      var defer = $q.defer();
      loanScenario.setAddress(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getPropertyType = function() {
      var defer = $q.defer();
      loanScenario.getPropertyType(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getProperty = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.getProperty(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getUsage = function() {
      var defer = $q.defer();
      loanScenario.getUsage(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getZoning = function() {
      var defer = $q.defer();
      loanScenario.getZoning(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getTitleType = function() {
      var defer = $q.defer();
      loanScenario.getTitleType(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getTenureType = function() {
      var defer = $q.defer();
      loanScenario.getTenureType(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setLoanScenarioSecurityInfo = function($postData) {
      var defer = $q.defer();
      loanScenario.setLoanScenarioSecurityInfo(
        $postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getLoanScenarioSecurityClientInfo = function(
      loanScenarioId,
      loanSercurityId
    ) {
      var defer = $q.defer();
      loanScenario.getLoanScenarioSecurityClientInfo(
        loanScenarioId,
        loanSercurityId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getLoanScenarioSingleSecurityInfo = function(loanSercurityId) {
      var defer = $q.defer();
      loanScenario.getLoanScenarioSingleSecurityInfo(
        loanSercurityId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.deleteLoanScenarioSecurityInfo = function($postData) {
      var defer = $q.defer();
      loanScenario.deleteLoanScenarioSecurityInfo(
        $postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getAssetType = function() {
      var defer = $q.defer();
      loanScenario.getAssetType(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getCTRLEquitySource = function() {
      var defer = $q.defer();
      loanScenario.getCTRLEquitySource(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getDepositEquity = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.getDepositEquity(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setDepositEquity = function($postData) {
      var defer = $q.defer();
      loanScenario.setDepositEquity(
        $postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.deleteDepositEquity = function(depositEquityId) {
      var defer = $q.defer();
      loanScenario.deleteDepositEquity(
        depositEquityId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.linkedFundingCalculatorSet = function(
      loanApplicationId,
      brokerEventId
    ) {
      var defer = $q.defer();
      loanScenario.linkedFundingCalculatorSet(
        loanApplicationId,
        brokerEventId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.linkedFundingCalculatorGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.linkedFundingCalculatorGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.fundingCalculatorDetailsGet = function(brokerEventId) {
      var defer = $q.defer();
      loanScenario.fundingCalculatorDetailsGet(
        brokerEventId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    // Product Selection Tab
    factory.getServiceabilityProductSearch = function(searchProduct) {
      var defer = $q.defer();
      loanScenario.getServiceabilityProductSearch(
        searchProduct,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getRecentlyViewedProduct = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.getRecentlyViewedProduct(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getPopularSearchedProducts = function() {
      var defer = $q.defer();
      loanScenario.getPopularSearchedProducts(
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setSearchedProduct = function($postData) {
      var defer = $q.defer();
      loanScenario.setSearchedProduct(
        $postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getLoanScenarioSelectedProduct = function(loanScenarioId) {
      var defer = $q.defer();
      loanScenario.getLoanScenarioSelectedProduct(
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.deleteSelectedProduct = function(productId, loanScenarioId) {
      var defer = $q.defer();
      loanScenario.deleteSelectedProduct(
        productId,
        loanScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getScenarioProviderList = function(applicationId) {
      var defer = $q.defer();
      loanScenario.getScenarioProviderList(
        applicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setScenarioProvider = function(loanApplicationId, providerId) {
      var defer = $q.defer();
      loanScenario.setScenarioProvider(
        loanApplicationId,
        providerId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setScenarioImportantDate = function(postdata) {
      var defer = $q.defer();
      loanScenario.setScenarioImportantDate(
        postdata,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getRelatedDocumentHeadsup = function(familyid) {
      var defer = $q.defer();
      loanScenario.getRelatedDocumentHeadsup(
        familyid,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setRelatedDocumentHeadsup = function(
      familyid,
      isRelatedDocumentShow
    ) {
      var defer = $q.defer();
      loanScenario.setRelatedDocumentHeadsup(
        familyid,
        isRelatedDocumentShow,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getAdviserComment = function(loanApplicationId, commentId) {
      var defer = $q.defer();
      loanScenario.getAdviserComment(
        loanApplicationId,
        commentId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setAdviserComment = function(comment) {
      var defer = $q.defer();
      loanScenario.setAdviserComment(
        comment,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.deleteAdviserComment = function(commentId) {
      var defer = $q.defer();
      loanScenario.deleteAdviserComment(
        commentId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setProposedLendingProduct = function(data) {
      var defer = $q.defer();
      loanScenario.setProposedLendingProduct(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.ReportSettingsServiceabilityGet = function(lendingScenarioId) {
      var defer = $q.defer();
      loanScenario.ReportSettingsServiceabilityGet(
        lendingScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.ReportSettingsFundingGet = function(lendingScenarioId) {
      var defer = $q.defer();
      loanScenario.ReportSettingsFundingGet(
        lendingScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.ReportSettingsGet = function(lendingScenarioId) {
      var defer = $q.defer();
      loanScenario.ReportSettingsGet(
        lendingScenarioId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };
    factory.ReportSettingsSet = function(data) {
      var defer = $q.defer();
      loanScenario.ReportSettingsSet(
        data,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.documentCheckListGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.documentCheckListGet(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.documentCheckListPut = function(loanApplicationId, isTicked) {
      var defer = $q.defer();
      loanScenario.documentCheckListPut(
        loanApplicationId,
        isTicked,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.preApprovalCheckPut = function(loanApplicationId, isTicked) {
      var defer = $q.defer();
      loanScenario.preApprovalCheckPut(
        loanApplicationId,
        isTicked,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getLoanSecurity = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.getLoanSecurity(
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.contactLoanSecurityInfoSet = function($postData) {
      var defer = $q.defer();
      loanScenario.contactLoanSecurityInfoSet(
        $postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.contactLoanSecurityInfoGet = function(loanId, loanSecurityInfoId) {
      var defer = $q.defer();
      loanScenario.contactLoanSecurityInfoGet(
        loanId,
        loanSecurityInfoId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.LenderFeesGet = function(
      productId,
      brokerEventId,
      productLoanStructureId,
      loanApplicationId
    ) {
      var defer = $q.defer();
      loanScenario.LenderFeesGet(
        productId,
        brokerEventId,
        productLoanStructureId,
        loanApplicationId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.LenderFeesSet = function($postData) {
      var defer = $q.defer();
      loanScenario.LenderFeesSet(
        $postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.getLoanScenarioEnquiry = function(id) {
      var defer = $q.defer();
      loanScenario.getLoanScenarioEnquiry(
        id,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setLoanScenarioEnquiry = function(post) {
      var defer = $q.defer();
      loanScenario.setLoanScenarioEnquiry(
        post,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.share = function(data) {
      const {
        loanApplicationId,
        clientId,
        familyId,
        eMail,
        sms,
        clientMessage,
      } = data;
      var defer = $q.defer();
      loanScenario.share(
        loanApplicationId,
        clientId,
        familyId,
        eMail,
        sms,
        clientMessage,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.loanApplicationLockStatus = function(loanApplicationId, isLocked) {
      var defer = $q.defer();
      loanScenario.loanApplicationLockStatus(
        loanApplicationId,
        isLocked,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.LoanFacilityTBASet = function(
      loanApplicationId,
      loanFacilityId,
      isTBA
    ) {
      var defer = $q.defer();
      loanScenario.LoanFacilityTBASet(
        loanApplicationId,
        loanFacilityId,
        isTBA,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.setLoanApplicationShowCPACQPRecommendation = function(
      loanAppId,
      isShow
    ) {
      var defer = $q.defer();
      loanScenario.setLoanApplicationShowCPACQPRecommendation(
        loanAppId,
        isShow,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.NZLoanAppDocumentGet = function(loanAppId) {
      var defer = $q.defer();
      loanScenario.NZLoanAppDocumentGet(
        loanAppId,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.ExpenseDetailsSetMultiple = function($postData) {
      var defer = $q.defer();
      loanScenario.ExpenseDetailsSetMultiple(
        $postData,
        response => {
          factory.data = response.data;
          return defer.resolve(response);
        },
        error => {
          factory.data = [];
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.LoanAppProviderEmailGet = function(loanAppId, providerId) {
      var defer = $q.defer();
      if (Number(loanAppId) > 0 && Number(providerId) > 0) {
        loanScenario.LoanAppProviderEmailGet(
          loanAppId,
          providerId,
          response => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          error => {
            factory.data = [];
            return defer.reject(error);
          }
        );
      } else {
        defer.reject({
          data: 'Invalid LoanScenarioId and/or ProviderId',
        });
      }
      return defer.promise;
    };

    factory.LoanAppProviderEmailSet = function(
      loanAppId,
      providerId,
      lenderEmail
    ) {
      var defer = $q.defer();
      if (Number(loanAppId) > 0 && Number(providerId) > 0) {
        loanScenario.LoanAppProviderEmailSet(
          loanAppId,
          providerId,
          lenderEmail,
          response => {
            factory.data = response.data;
            return defer.resolve(response);
          },
          error => {
            factory.data = [];
            return defer.reject(error);
          }
        );
      } else {
        defer.reject({
          data: 'Invalid LoanScenarioId and/or ProviderId',
        });
      }
      return defer.promise;
    };

    factory.emailLoanAppToLenderNZ = function(data) {
      var defer = $q.defer();
      loanScenario.emailLoanAppToLenderNZ(
        data,
        response => {
          return defer.resolve(response);
        },
        error => {
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.LoanAppIsFirstHomeBuyerSet = function(
      clientId,
      loanApplicationId,
      isFirstHomeBuyer
    ) {
      var defer = $q.defer();
      loanScenario.LoanAppIsFirstHomeBuyerSet(
        clientId,
        loanApplicationId,
        isFirstHomeBuyer,
        response => {
          return defer.resolve(response);
        },
        error => {
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.LoanAppIsFirstHomeBuyerGet = function(clientId, loanApplicationId) {
      var defer = $q.defer();
      loanScenario.LoanAppIsFirstHomeBuyerGet(
        clientId,
        loanApplicationId,
        response => {
          return defer.resolve(response);
        },
        error => {
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.workbenchRequirementGet = function(loanId) {
      var defer = $q.defer();
      loanScenario.workbenchRequirementGet(
        loanId,
        response => {
          return defer.resolve(response);
        },
        error => {
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.workbenchRequirementSet = function(data) {
      var defer = $q.defer();
      loanScenario.workbenchRequirementSet(
        data,
        response => {
          return defer.resolve(response);
        },
        error => {
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.workbenchRequirementCompletion = function(data) {
      var defer = $q.defer();
      loanScenario.workbenchRequirementCompletion(
        data,
        response => {
          return defer.resolve(response);
        },
        error => {
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.workbenchRequirementDelete = function(data) {
      var defer = $q.defer();
      loanScenario.workbenchRequirementDelete(
        data,
        response => {
          return defer.resolve(response);
        },
        error => {
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.importantDateGet = function(loanApplicationId) {
      var defer = $q.defer();
      loanScenario.importantDateGet(
        loanApplicationId,
        response => {
          return defer.resolve(response);
        },
        error => {
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.workbenchNextWorkDate = function(data) {
      var defer = $q.defer();
      loanScenario.workbenchNextWorkDate(
        data,
        response => {
          return defer.resolve(response);
        },
        error => {
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.workbenchRequirementLog = function(loanId) {
      var defer = $q.defer();
      loanScenario.workbenchRequirementLog(
        loanId,
        response => {
          return defer.resolve(response);
        },
        error => {
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.workbenchRequirementLogDelete = function(data) {
      var defer = $q.defer();
      loanScenario.workbenchRequirementLogDelete(
        data,
        response => {
          return defer.resolve(response);
        },
        error => {
          return defer.reject(error);
        }
      );
      return defer.promise;
    };

    factory.linkLoanAppDocsToContact = data => {
      const defer = $q.defer();
      if (data && data.loanAppId) {
        loanScenario.linkLoanAppDocsToContact(
          data,
          response => defer.resolve(response),
          error => defer.reject(error)
        );
      } else {
        defer.reject({ data: 'Invalid arguments' });
      }
      return defer.promise;
    };

    factory.completionStatusSet = function(data) {
      var defer = $q.defer();
      const argumentValid = data && data.loanApplicationId && data.sectionID;
      if (argumentValid) {
        loanScenario.completionStatusSet(
          data,
          response => {
            return defer.resolve(response);
          },
          error => {
            return defer.reject(error);
          }
        );
      } else {
        defer.reject({ data: 'Invalid argument' });
      }
      return defer.promise;
    };

    factory.assignAssistant = (loanScenarioId, assistantFamilyId) => {
      const defer = $q.defer();
      if (loanScenarioId && assistantFamilyId) {
        loanScenario.assignAssistant(
          assistantFamilyId,
          loanScenarioId,
          response => defer.resolve(response)
        );
      } else {
        defer.reject({ data: 'Invalid arguments' });
      }
      return defer.promise;
    };

    factory.availableDocuments = loanScenarioId => {
      const defer = $q.defer();
      if (loanScenarioId) {
        loanScenario.availableDocuments(loanScenarioId, response =>
          defer.resolve(response)
        );
      } else {
        defer.reject({ data: 'Invalid arguments' });
      }
      return defer.promise;
    };

    return factory;
  },
]);

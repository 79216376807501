export function productPdfBuilderForUI(data) {
  return {
    name: data.Name,
    contentType: data.ContentType,
    documentContent: data.DocumentContent,
    documentExists: data.DocumentExists,
    isSvg: data.IsSvg,
  };
}
export function putProductFinderSettingsBuilderForMyCRM(settings) {
  const { generalSetting, customSelection } = settings;
  return {
    GeneralSetting: {
      Lender: generalSetting.lender,
      loanTerm: generalSetting.loanTerm,
      loanAmount: generalSetting.loanAmount,
      security: generalSetting.security,
      LVR: generalSetting.lvr,
      splitLoan: generalSetting.splitLoan,
    },
    CustomSelection: {
      LoanAmount: customSelection.loanAmount,
      DepositAmount: customSelection.depositAmount,
      MaxRate: customSelection.maxRate,
      RateType: customSelection.rateType,
      Financials: customSelection.financials,
      ProductType: customSelection.productType,
      Intention: customSelection.intention,
      Repayment: customSelection.repayment,
      AdvanceFilters: customSelection.advanceFilters,
      loanStructureID: customSelection.loanStructureId,
    },
  };
}
export function assessmentDetailCalculationsBuilderForUI(data) {
  return {
    familyId: data.FamilyId,
    dateOfCalculation: data.DateOfCalculation,
    typeOfCalculation: data.TypeOfCalculation,
  };
}
export function incomeTypeOptionBuilderForMyCRM(data) {
  return {
    Name: data.Name,
    Value: data.Value,
  };
}
export function clientOptionBuilderForMyCRM(data) {
  return {
    ClientNo: data.ClientNo,
    ClientName: data.ClientName,
    ClientID: data.ClientID,
  };
}
export function productsGeneralSettingsBuilderForUI(data) {
  return {
    brokerEventID: data.brokerEventID,
    lender: data.Lender,
    loanTerm: data.loanTerm,
    clientType: parseInt(data.ClientType, 10),
    loanAmount: data.loanAmount,
    lVR: data.LVR,
    splitLoan: data.splitLoan,
    isAssetFinance: data.IsAssetFinance,
    assetType: parseInt(data.AssetType, 10),
    financeType: parseInt(data.FinanceType, 10),
    purchasePrice: data.PurchasePrice,
    commissionPercent: data.CommissionPercent,
    commissionAmount: data.CommissionAmount,
    residualPercent: data.ResidualPercent,
    residualAmount: data.ResidualAmount,
    documentFeePercent: data.DocumentFeePercent,
    documentFeeAmount: data.DocumentFeeAmount,
    propertyOwner: data.PropertyOwner,
    redBookCode: data.RedBookCode,
    description: data.Description,
    vehicleClassType: parseInt(data.VehicleClassType, 10),
    vehicleMake: data.VehicleMake,
    vehicleFamily: data.VehicleFamily,
    vehicleYear: parseInt(data.VehicleYear, 10),
    vehicleMonth: data.VehicleMonth,
    vehicleModel: data.VehicleModel,
  };
}

export function assetFinanceFavouriteProductBuilderForUI(data) {
  const getAssetFinanceAttribute = property => {
    return (
      data.AssetFinanceProduct &&
      data.AssetFinanceProduct.attributes &&
      data.AssetFinanceProduct.attributes[property]
    );
  };

  return {
    productId: data.ProductID,
    lenderName: getAssetFinanceAttribute('lender'),
    assetVariant: getAssetFinanceAttribute('asset_variant'),
    supplierType: getAssetFinanceAttribute('supplier'),
    establishmentFee: getAssetFinanceAttribute('establishment_fee'),
    accountKeepingFee: getAssetFinanceAttribute('account_keeping_fee'),
    amountFinanced: getAssetFinanceAttribute('amount_finance'),
    repaymentAmount: getAssetFinanceAttribute('repayment'),
    repaymentFrequency: getAssetFinanceAttribute('term'),
    interestRate: getAssetFinanceAttribute('interest_rate'),
    isRecommended: data.IsRecommended,
    assetFinanceProduct: data.AssetFinanceProduct,
  };
}

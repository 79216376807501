import angular from 'angular';
/**
 * Downloads a document using directive
 * @name downloadDocumentDir
 * @isDownloading {boolean} bind this to your controller, will check if the file is being downloaded, will turn to FALSE if finished downloading.
 * @documentIdBeingDownloaded {int} bind this to your controller, this will return an ID of the document being downloaded to be used by your controller.
 * @documentId {int} pass the documentId from your controller to this directive.
 */
angular
  .module('app')
  .directive('downloadDocumentDir', function downloadDocumentDir(
    generalService,
    loanScenarioService,
    loanScenarioModelService,
    $window,
    $compile,
    toaster,
    downloadDataService,
    loanProfilerService,
    nextGenLoanAppService,
    uiService,
    $sce,
    $timeout
  ) {
    return {
      scope: {
        isDownloading: '=',
        documentIdBeingDownloaded: '=',
        documentId: '@',
        downloadDocumentDirType: '@',
        loanAppId: '@',
        multiLoanCpaIds: '@',
        servProvWorksheetObject: '=',
        downloadFile: '=', // direct document object to be downloaded required properties: @ContentType, @DocumentContent, @Name, @extension
      },
      link(scope, element) {
        element.on('click', () => {
          if (scope.isDownloading) {
            // makes sure it won't download multiple times
            return false;
          } else if (scope.documentId) {
            scope.documentIdBeingDownloaded = parseInt(scope.documentId, 10);
            scope.isDownloading = true;
            generalService
              .documentGet(scope.documentIdBeingDownloaded)
              .then(response => {
                if (response.data) {
                  try {
                    const eventTimeout = $timeout(() => {
                      const byteString = $window.atob(
                        response.data.DocumentContent
                      );
                      const a = angular.element('<a></a>');
                      const ab = new ArrayBuffer(byteString.length);
                      const ia = new Uint8Array(ab);
                      for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                      }
                      const blob = new $window.Blob([ia], {
                        type: response.data.ContentType,
                      });
                      a[0].href = $window.URL.createObjectURL(blob);
                      a[0].download = response.data.Name;
                      a[0].click();
                      scope.isDownloading = false;
                      $timeout.cancel(eventTimeout);
                    });
                  } catch (error) {
                    scope.isDownloading = false;
                  }
                }
              });
          } else if (scope.downloadDocumentDirType === 'Fact Find Download') {
            scope.isDownloading = true;
            loanScenarioService.FactFindDocumentGet(scope.loanAppId).then(
              response => {
                scope.isDownloading = false;
                const docFile = response.data;
                downloadDataService.download(
                  `data:${docFile.ContentType};base64,${
                    docFile.DocumentContent
                  }`,
                  'factfind.pdf',
                  docFile.ContentType
                );
              },
              () => {
                scope.isDownloading = false;
                toaster.pop(
                  'error',
                  'There seems to be an issue downloading the pdf file. Please try again.',
                  'Unable to download the file'
                );
              }
            );
          } else if (
            scope.downloadDocumentDirType === 'cpa' ||
            scope.downloadDocumentDirType === 'cqp'
          ) {
            const service = scope.multiLoanCpaIds
              ? loanScenarioModelService
              : loanScenarioService;
            const endPointMethod = scope.multiLoanCpaIds
              ? 'cpaDocument'
              : `${scope.downloadDocumentDirType}DocumentGet`;
            const param = scope.multiLoanCpaIds
              ? { loanApplicationIds: scope.multiLoanCpaIds }
              : scope.loanAppId;

            scope.isDownloading = true;
            if (typeof service[endPointMethod] === 'function') {
              service[endPointMethod](param).then(
                response => {
                  const docFile = response.data;
                  downloadDataService.download(
                    `data:${docFile.ContentType};base64,${
                      docFile.DocumentContent
                    }`,
                    `${scope.downloadDocumentDirType}report.pdf`,
                    docFile.ContentType
                  );
                  scope.isDownloading = false;
                },
                () => {
                  scope.isDownloading = false;
                  toaster.pop(
                    'error',
                    'There seems to be an issue downloading the pdf file. Please try again.',
                    'Unable to download the file'
                  );
                }
              );
            }
          } else if (
            scope.downloadDocumentDirType === 'serviceabilityProviderWorksheet'
          ) {
            scope.isDownloading = true;
            loanProfilerService
              .ServiceabilityProviderWorksheetGet(
                scope.servProvWorksheetObject.brokerEventId,
                scope.servProvWorksheetObject.providerId,
                scope.servProvWorksheetObject.loanAmount
              )
              .then(response => {
                const docFile = response.data;
                downloadDataService.download(
                  `data:${docFile.ContentType};base64,${
                    docFile.DocumentContent
                  }`,
                  `${docFile.Name} - Lender Spreadsheet.xlsx`,
                  docFile.ContentType
                );
                scope.isDownloading = false;
              });
          } else if (scope.downloadDocumentDirType === 'applyOnlinePdf') {
            scope.isDownloading = true;
            loanScenarioService
              .NZLoanAppDocumentGet(scope.loanAppId)
              .then(response => {
                const docFile = response.data;
                downloadDataService.download(
                  `data:${docFile.ContentType};base64,${
                    docFile.DocumentContent
                  }`,
                  `${docFile.Name}`,
                  docFile.ContentType
                );
                scope.isDownloading = false;
              });
          } else if (scope.downloadDocumentDirType === 'auViewSubmission') {
            scope.isDownloading = true;
            if (uiService.isCountry('Australia')) {
              const loan = {
                LoanScenarioID: scope.loanAppId,
              };
              nextGenLoanAppService.nextGenAOLView(loan).then(response => {
                const nextGenObj = {
                  ...response.data,
                  https: $sce.trustAsResourceUrl(response.data.https),
                };
                const guid = nextGenObj.GUID || '';
                const startPage = nextGenObj.StartPage || '';
                const applicationViewURL = `${
                  nextGenObj.https
                }?GUID=${guid}&StartPage=${startPage}`;
                $window.open(applicationViewURL);
                scope.isDownloading = false;
              });
            }
          } else if (
            scope.downloadFile &&
            scope.downloadFile.ContentType &&
            scope.downloadFile.DocumentContent &&
            scope.downloadFile.Name &&
            scope.downloadFile.extension
          ) {
            scope.isDownloading = true;
            downloadDataService.download(
              `data:${scope.downloadFile.ContentType};base64,${
                scope.downloadFile.DocumentContent
              }`,
              `${scope.downloadFile.Name}.${scope.downloadFile.extension}`,
              scope.downloadFile.ContentType
            );
            scope.isDownloading = false;
          }
        });
      },
    };
  });

import angular from 'angular';

angular
  .module('app')
  .directive('adjustHeightMaxSibling', function adjustHeightMaxSibling(
    $interval,
    $window
  ) {
    return {
      scope: {
        identifier: '@adjustHeightMaxSibling',
      },
      restriction: 'A',
      link: (scope, element, attr) => {
        const iteration = parseInt(attr.iteration, 10);
        const adjustHeight = () => {
          if (!scope.identifier) return;
          const selector = `.${scope.identifier}`;
          let siblings = element[0].querySelectorAll(selector);
          siblings = Array.from(siblings);
          let maxHeight = 0;
          siblings.forEach(node => {
            angular.element(node).css('height', `auto`);
            if (node.clientHeight > maxHeight) {
              maxHeight = node.clientHeight;
            }
          });

          if (maxHeight === 0) return;

          siblings.forEach(node => {
            angular.element(node).css('height', `${maxHeight}px`);
          });
        };

        const adjustHeightInterval = $interval(adjustHeight, 1000, iteration);
        element.on('$destroy', () => {
          $interval.cancel(adjustHeightInterval);
        });

        angular.element($window).on('resize', adjustHeight());

        adjustHeight();
      },
    };
  });

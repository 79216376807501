import { findIndex } from 'lodash';
import { toastError } from 'Common/utilities/alert';
import {
  getConciseConversionSummary,
  isConversionStatus,
} from 'Common/utilities/insurancePipeline';

const buildColumnsSorting = statusSorting => {
  if (!statusSorting) return {};
  return statusSorting.reduce((accum, sorting) => {
    const { statusId, field, direction } = sorting;
    accum[statusId] = { field, direction };
    return accum;
  }, {});
};

const buildStatusSorting = columnsSorting => {
  return (
    columnsSorting &&
    Object.keys(columnsSorting).reduce((accum, key) => {
      return [...accum, { ...columnsSorting[key], statusId: key }];
    }, [])
  );
};

export default class InsuranceApplicationsPipelineCtrl {
  constructor(
    insurancePipelineService,
    insuranceModalApplicationService,
    tasksService
  ) {
    'ngInject';

    this.insuranceCreateApp = insuranceModalApplicationService;
    this.insurancePipelineService = insurancePipelineService;
    this.tasksService = tasksService;
    this.COMFORTABILITY = {
      COMFORTABLE: 0,
      COMPACT: 1,
    };
  }

  $onInit() {
    this.loadPipelineStatuses(() => this.loadFilters(() => this.loadLabels()));
    this.loadConversionSummary();
    this.loadNotProceededStatuses();
    this.loadAdvisers();
    this.loadProviders();
  }

  loadFilters(callback) {
    this.insurancePipelineService
      .getInsurancePipelineFilters()
      .then(response => {
        this.filters = response || this.getDefaultFilters();
        this.mode = this.filters.viewMode;
        this.selectedView = this.filters.isCompact
          ? this.COMFORTABILITY.COMPACT
          : this.COMFORTABILITY.COMFORTABLE;
        this.columnsSorting = buildColumnsSorting(this.filters.statusSorting);
        this.statusFilters =
          this.filters.statusFilters &&
          this.filters.statusFilters.map(filter => {
            return this.statuses.find(status => status.id === filter);
          });
        if (!response) this.saveFilters(this.filters);
      })
      .finally(() => {
        callback && callback();
      });
  }

  getDefaultFilters() {
    const statusFilters = this.statuses.map(status => status.id);
    return { viewMode: 'cards', isCompact: false, statusFilters };
  }

  loadLabels() {
    this.insurancePipelineService
      .getInsurancePipelineLabels()
      .then(response => {
        this.labels = response;
      });
  }

  loadPipelineStatuses(callback) {
    this.insurancePipelineService
      .getInsurancePipelineStatus(false)
      .then(response => {
        this.statuses = response.map(status => {
          return { ...status, PipelineStatus: status.name };
        });
        this.groupedStatuses = this.statuses.reduce(
          (accum, status) => {
            const groupIndex = isConversionStatus(status) ? 1 : 0;
            accum[groupIndex] = [...accum[groupIndex], status];
            return accum;
          },
          [[], []]
        );
      })
      .catch(e => toastError(e))
      .finally(callback);
  }

  loadAdvisers() {
    this.tasksService.taskAdviserListGet().then(response => {
      this.advisers =
        response &&
        response.filter(adviser => {
          return (
            adviser.accessType === 'Adviser' ||
            adviser.accessType === 'Principal Adviser'
          );
        });
    });
  }

  loadProviders() {
    this.insurancePipelineService.getInsuranceProviders().then(response => {
      this.providers = response;
    });
  }

  onTableViewInit(api) {
    this.tableView = api;
  }

  onCardsViewInit(api) {
    this.cardsView = api;
  }

  loadConversionSummary() {
    this.insurancePipelineService
      .getPipelineCoversionSummary()
      .then(response => {
        this.conversionSummary = getConciseConversionSummary(response);
      });
  }

  loadNotProceededStatuses() {
    this.insurancePipelineService.getNotProceededReasons().then(response => {
      this.notProceedingReasons = response.map(reason => {
        return {
          PipelineSubStatusID: reason.id,
          SubStatusName: reason.description,
        };
      });
    });
  }

  onAddLabel(label) {
    this.insurancePipelineService
      .saveInsurancePipelineLabel(label)
      .then(({ data }) => {
        label.id = data;
        this.labels = [...this.labels, label];
      })
      .catch(e => toastError(e));
  }

  onUpdateLabel(label) {
    this.insurancePipelineService
      .saveInsurancePipelineLabel(label)
      .then(() => {
        const labelIndex = findIndex(
          this.labels,
          labelInList => labelInList.id === label.id
        );
        if (labelIndex === -1) return;
        this.labels = [
          ...this.labels.slice(0, labelIndex),
          label,
          ...this.labels.slice(labelIndex + 1),
        ];
      })
      .catch(e => toastError(e));
  }

  onDeleteLabel(label) {
    if (!label) return;
    this.insurancePipelineService
      .deleteInsurancePipelineLabel(label.id)
      .then(() => {
        const labelIndex = findIndex(
          this.labels,
          labelInList => labelInList.id === label.id
        );
        if (labelIndex === -1) return;
        this.labels = [
          ...this.labels.slice(0, labelIndex),
          ...this.labels.slice(labelIndex + 1),
        ];
      })
      .catch(e => toastError(e));
  }

  refreshView(isSilent, quoteId) {
    if (this.filters.viewMode === 'list_view') {
      if (isSilent) this.tableView.silentlyRefresh(quoteId);
      else this.tableView.refresh();
    } else if (isSilent) {
      this.cardsView.silentlyRefresh(quoteId);
    } else {
      this.cardsView.refresh();
    }
  }

  onColumnSort(column, field, direction) {
    const columnsSorting = { ...this.columnsSorting };
    columnsSorting[column.id] = { field, direction };
    const statusSorting = buildStatusSorting(columnsSorting);
    const data = { ...this.filters, statusSorting };
    this.saveFilters(data);
  }

  saveFilters(filters, doRefreshView) {
    this.insurancePipelineService
      .saveInsurancePipelineFilters(filters)
      .then(() => {
        this.filters = { ...filters };
        this.columnsSorting = buildColumnsSorting(filters.statusSorting);
        this.mode = this.filters.viewMode;
        doRefreshView && this.refreshView();
      })
      .catch(e => toastError(e));
  }

  saveTableSorting(sortColumn, sortDirection) {
    this.saveFilters({ ...this.filters, sortColumn, sortDirection }, true);
  }

  saveStatusFilters(filters) {
    const statusFilters = filters && filters.map(filter => filter.id);
    this.saveFilters({ ...this.filters, statusFilters }, true);
  }

  saveAdviserFilter(adviserFilter) {
    this.saveFilters({ ...this.filters, adviserFilter }, true);
  }

  saveProviderFilter(providerFilter) {
    this.saveFilters({ ...this.filters, providerFilter }, true);
  }

  saveLabelFiler(labelFilter) {
    this.saveFilters({ ...this.filters, labelFilter }, true);
  }

  createNewApplication() {
    this.insuranceCreateApp
      .onCreate({
        typeNew: true,
        quoteId: 0,
      })
      .result.then(() => {
        this.refreshView(true);
      });
  }

  changeViewComfortability(view) {
    this.selectedView = view;
    this.filters.isCompact = !!view;
    this.saveFilters(this.filters);
  }

  changeMode(viewMode) {
    this.saveFilters({ ...this.filters, viewMode });
  }

  toggleFilters() {
    this.filtersVisible = !this.filtersVisible;
  }

  viewWorkbench(quoteId, clientId) {
    this.insuranceCreateApp
      .openWorkbenchModal(quoteId, clientId)
      .result.then((result = {}) => {
        const { isStatusChange, coversUpdated } = result;
        if (isStatusChange) {
          this.refreshView(true);
        } else if (coversUpdated) {
          this.refreshView(true, quoteId);
        }
      });
  }
}

import template from './assetFinanceLoanFacility.html';
import controller from './assetFinanceLoanFacilityCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanAppId: '<',
    loanFacility: '<',
    applicantGuarantorList: '<',
    onLoanDetailsGet: '&',
  },
};
